import api from "../utils/api";
import { toast } from "react-toastify";
import {
  LIST_ERROR,
  GET_LISTS,
  CREATE_LIST,
  UPDATE_LIST,
  DELETE_LIST,
  GET_LIST_BY_ID,
} from "./types";

export const createList = (name) => async (dispatch) => {
  try {
    const res = await api.post(`/lists`, name);
    if (res.status === 200) {
      dispatch({
        type: CREATE_LIST,
        payload: res.data,
      });
      toast.success(` ${res.data.message}`);
    }
  } catch (err) {
    toast.error(`${err.response.data.error}`);
    dispatch({
      type: LIST_ERROR,
      payload: err,
    });
  }
};

export const deleteList = (id) => async (dispatch) => {
  try {
    const res = await api.delete(`/lists/${id}`);
    if (res.status === 200) {
      dispatch({
        type: DELETE_LIST,
        payload: { id },
      });
      toast.success(`List Deleted Successfully`);
    }
  } catch (err) {
    console.log(err);
  }
};

export const getList = (id) => async (dispatch) => {
  try {
    const res = await api.get(`/lists/${id}`);
    if (res.status === 200) {
      dispatch({
        type: GET_LIST_BY_ID,
        payload: res.data,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

// Get all
export const getLists = (page, limit, keyword) => async (dispatch) => {
  try {
    // order=-1&fieldname=createdAt&status=${categoryStatus}&page=${page}&limit=${limit}&keyword=${keyword}
    const res = await api.get(
      `/lists?page=${page}&name[regex]=${
        keyword ?? ""
      }&name[options]=i&limit=${limit}`
    );
    dispatch({
      type: GET_LISTS,
      payload: res.data,
    });
  } catch (err) {
    toast.error(`${err.response.data.error}`);
    dispatch({
      type: LIST_ERROR,
      payload: err,
    });
  }
};

export const updateList = (listId, name) => async (dispatch) => {
  try {
    const res = await api.put(`/lists/${listId}`, name);
    dispatch({
      type: UPDATE_LIST,
      payload: res.data.data,
    });
    toast.success(`${res.data.message}`);
  } catch (err) {
    const message = err.response.data.error;
    toast.error(`${message}`);
  }
};
