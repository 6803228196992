import {
  GET_FOLLOWERS,
  GET_FOLLOWING,
  FOLLOW_ERROR,
  FOLLOW_STREAMER,
  UNFOLLOW_STREAMER,
} from "../actions/types";

const initialState = {
  Followers: [],
  Following: [],

  Follower: null,
  loading: true,
  error: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_FOLLOWERS:
      return {
        ...state,
        Followers: payload,
        loading: false,
      };
    case GET_FOLLOWING:
      return {
        ...state,
        Following: payload,
        loading: false,
      };

    case FOLLOW_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
}
