import React from 'react';
import { useParams } from 'react-router-dom';
import { Container, Typography, Box, CircularProgress } from '@mui/material';
import DOMPurify from 'dompurify';
import firebase from './firebase';

const BlogDetails = () => {
  const { id } = useParams();
  const [post, setPost] = React.useState(null);

  React.useEffect(() => {
    const database = firebase.database();
    const postRef = database.ref(`posts/${id}`);

    postRef.on('value', (snapshot) => {
      const postData = snapshot.val();
      setPost(postData);
    });

    return () => {
      // Clean up the listener when the component unmounts
      postRef.off();
    };
  }, [id]);

  if (!post) {
    return (
      <Container maxWidth="lg" sx={{ mt: 6, mb: 4 }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container maxWidth="md" sx={{ mt: 10, mb: 4 }}> {/* Adjusted margin-top to 10 */}
      <Typography variant="h3" component="h1" gutterBottom>
        {post.title}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        Posted on {post.createdAt}
      </Typography>
      <Box mt={4}>
        <Typography
          variant="body1"
          component="div"
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(post.content) }}
        />
      </Box>
    </Container>
  );
};

export default BlogDetails;

