import SvgIcon from "../../Components/SvgIcon";
import SocialButton from "./Social_button.js";
import { useGoogleLogin } from "react-google-login";
import { gapi } from "gapi-script";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";

import { SocialLogin, registerSocial } from "../../actions/authAction.js";

const SocialoginIcon = ({
  title,
  isSignUp,
  SocialLogin,
  registerSocial,
  isAuthenticated,
}) => {
  const clientId =
    "623083657956-1tomvcmd23gi2gqep36m26g8eo42kl6l.apps.googleusercontent.com";

  const history = useHistory();

  const handleSocialLoginFailure = (err) => {
    if (err) return toast.error("Oops! Authentication failed");
  };
  const handelGoogleLogin = (response) => {
    console.log(response);
    const idToken = response.tokenObj.id_token;
    const firstName = response.profileObj.givenName;
    const lastName = response.profileObj.familyName;

    if (idToken && firstName && lastName) {
      SocialLogin("GOOGLE", idToken, firstName, lastName);
    }
    console.log(`Google Id-Token: ${idToken} `);
    console.log(`Google Full Name: ${firstName} ${lastName}`);
  };
  const handelFacebookLogin = (user) => {
    console.log(user._profile.firstName);
    const accessToken = user._token.accessToken;
    const firstName = user._profile.firstName;
    const lastName = user._profile.lastName;
    if (accessToken && firstName && lastName) {
      SocialLogin("FACEBOOK", accessToken, firstName, lastName);
    }
  };
  gapi.load("client:auth2", () => {
    gapi.client.init({
      clientId: clientId,
      plugin_name: "fierce-streaming",
      scope: "profile email",
    });
  });
  const { signIn, loaded } = useGoogleLogin({
    clientId: clientId,
    onSuccess: handelGoogleLogin,
    onFailure: handleSocialLoginFailure,
  });
  if (isAuthenticated) {
    return <Redirect to="/" />;
  }
  return (
    <div className="social-login-icon mb-4">
      <span>{title}</span>
      <ul>
        <li>
          <SocialButton
            provider="facebook"
            appId="608310704351527"
            onLoginSuccess={handelFacebookLogin}
            onLoginFailure={handleSocialLoginFailure}
          >
            <SvgIcon iconName="SocialFacebook" />
          </SocialButton>
        </li>
        <li onClick={() => signIn()}>
          <SvgIcon iconName="SocialGoogle" />
        </li>
      </ul>
    </div>
  );
};
const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, {
  SocialLogin,
  registerSocial,
})(SocialoginIcon);
