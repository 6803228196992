import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ModalHeader, ModalBody } from "reactstrap";

import {
  ModalBtnSubmit,
  ModalRight,
} from "../../Components/modal/ModalComponents";

import {
  login,
  forgotPassword,
  resetPassword,
  verifyCode,
} from "../../actions/authAction";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";
import SvgIcon from "../../Components/SvgIcon";
import LoginLayout, {
  AlreadyHaveAnAccount,
  LoginTitle,
} from "../layout/LoginLayout";
import SocialLoginIcon from "../layout/social_login_icon.js";

const CheckAndLogin = ({
  isAuthenticated,
  isAdmin,
  login,
  forgotPassword,
  resetPassword,
  code,
  verifyCode,
  children,
  noAuth,
  history,
}) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    forgotemail: "",
    resetCode: "",
    newPassword: "",
    confirmPassword: "",
  });
  const {
    email,
    password,
    forgotemail,
    resetCode,
    newPassword,
    confirmPassword,
  } = formData;
  const [loginModel, setLoginModel] = useState(false);

  const toggleLoginModel = () => {
    setLoginModel(!loginModel);
  };

  const onchange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    //console.log(email, password)
    login(email, password, history).then((_) => {
      toggleLoginModel();
    });
  };

  const handleForgot = async (e) => {
    e.preventDefault();
    console.log(forgotemail);
    // alert("called")
    forgotPassword(forgotemail);
  };

  const handleVerfyCode = async (e) => {
    e.preventDefault();
    // console.log(resetCode)
    verifyCode(resetCode);
  };

  const handleReset = async (e) => {
    e.preventDefault();
    console.log(newPassword, confirmPassword, code);
    resetPassword(newPassword, confirmPassword, code, "admin");
  };

  //   if (isAuthenticated) {
  //     // return { children };
  //   } else {
  return (
    <>
      <ModalRight
        isOpen={loginModel}
        toggle={toggleLoginModel}
        style={{
          maxWidth: "800px",
          width: "100%",
        }}

        // className="modal-two-column"
      >
        <ModalHeader toggle={toggleLoginModel}>Login Model</ModalHeader>
        <ModalBody>
          <form onSubmit={(e) => onSubmit(e)}>
            <LoginTitle
              title="Bahstream"
              subtitle="Stream your favorite workouts/meals/help tips and more for free"
            />

            <div className="form-group my-4">
              <input
                type="email"
                className="form-control input-login"
                placeholder="Email Address"
                value={email}
                onInvalid={(e) => {
                  console.log(e);
                  if (e.target.value === "") {
                    e.target.setCustomValidity("Required email address");
                  } else if (e.target.validity.typeMismatch) {
                    e.target.setCustomValidity(
                      "please enter a valid email address"
                    );
                  } else {
                    e.target.setCustomValidity("");
                  }
                }}
                name="email"
                onChange={(e) => onchange(e)}
                required
                autoComplete="off"
              />
            </div>
            <div className="form-group mt-4">
              <input
                type="password"
                className="form-control input-login"
                placeholder="Password"
                value={password}
                onInvalid={(e) => {
                  console.log(e);
                  if (e.target.value === "") {
                    e.target.setCustomValidity("Required Password");
                  } else {
                    e.target.setCustomValidity("");
                  }
                }}
                name="password"
                onChange={(e) => onchange(e)}
                required
                autoComplete="off"
              />
            </div>

            <div className="text-center mb-4">
              <Link to="/forgotpassword" className="btn-forgot-password">
                Forgot Password?
              </Link>
            </div>

            <button type="submit" className="btn-primary mb-4">
              Login Now
            </button>

            <SocialLoginIcon title="Or Sign in with" />

            <AlreadyHaveAnAccount
              title="Don't have an account?"
              linkUrl="/register"
              linkText="Sign up"
            />
          </form>
        </ModalBody>
      </ModalRight>
      {isAuthenticated ? (
        children
      ) : (
        <div onClick={() => toggleLoginModel()}>{noAuth}</div>
        // <button className="btn btn-primary" >
        // </button>
      )}
    </>
  );
};

CheckAndLogin.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, {
  login,
  forgotPassword,
  resetPassword,
  verifyCode,
})(CheckAndLogin);
