import React, { useState, useEffect } from 'react';
import {
  Container,
  TextField,
  Typography,
  Button,
  Box,
  CircularProgress,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ChromePicker } from 'react-color'; 
import useUploadBlogPost from '../hooks/useUploadBlogPost';

// Fixed password encoded in Base64
const FIXED_PASSWORD = btoa('MySecurePassword123'); // 'MySecurePassword123' encoded in Base64

const NutritionBlogUpload = () => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [image, setImage] = useState(null);
  const [blogType, setBlogType] = useState('nutrition'); // Default to 'nutrition'
  const [color, setColor] = useState('#FFFFFF'); 
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [hasAccess, setHasAccess] = useState(false); // Control access to the page

  const { uploadBlogPost, uploading, error } = useUploadBlogPost();

  // Handle password verification
  useEffect(() => {
    const userPassword = prompt("Please enter the password to access this page:");
    if (btoa(userPassword) === FIXED_PASSWORD) {
      setHasAccess(true); // Grant access if password is correct
    } else {
      alert("Incorrect password! Access denied.");
      setHasAccess(false); // Deny access if incorrect
    }
  }, []);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith('image/')) {
      setImage(file);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (title && content) {
      const result = await uploadBlogPost(title, content, image, blogType, color);
      if (result.success) {
        setTitle('');
        setContent('');
        setImage(null);
        setBlogType('nutrition'); 
        alert('Blog post uploaded successfully!');
      } else {
        alert(`Error: ${result.error}`);
      }
    } else {
      alert('Please fill out all fields.');
    }
  };

  const handleColorPickerToggle = () => {
    setShowColorPicker(!showColorPicker);
  };

  const handleColorChange = (newColor) => {
    setColor(newColor.hex);
  };

  if (!hasAccess) {
    return <Typography variant="h4" sx={{ textAlign: 'center', mt: 5 }}>Access Denied</Typography>;
  }

  return (
    <Container 
      maxWidth="lg" 
      sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100vh', 
        overflow: 'auto' // Allow scrolling if content exceeds view
      }}
    >
      <Box 
        sx={{ 
          p: 4, 
          borderRadius: 2, 
          bgcolor: 'rgba(255, 255, 255, 0.8)', 
          boxShadow: 3, 
          backdropFilter: 'blur(5px)', 
          width: '100%', 
          maxWidth: '800px', 
          maxHeight: '90vh', // Limit height to prevent overflow
          overflowY: 'auto'  // Enable scrolling for the form content
        }}
      >
        <Typography variant="h4" component="h1" gutterBottom sx={{ textAlign: 'center' }}>
          Create a New Blog Post
        </Typography>
        <Box 
          component="form" 
          onSubmit={handleSubmit} 
          sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            gap: 3,
            maxHeight: '100%', // Ensure form stays within the screen
            overflowY: 'auto'  // Enable scrolling inside the form
          }}
        >
          <TextField
            label="Title"
            variant="outlined"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
            fullWidth
          />
          <Box sx={{ maxHeight: '200px', overflow: 'auto' }}>
            <ReactQuill
              value={content}
              onChange={setContent}
              modules={{
                toolbar: [
                  ['bold', 'italic', 'underline'],
                  [{ list: 'ordered' }, { list: 'bullet' }],
                  ['link', 'image', 'video'],
                  ['clean'],
                ],
              }}
              style={{ backgroundColor: 'white', color: 'black', minHeight: '200px' }}
            />
          </Box>

          {/* Blog Type Selector */}
          <FormControl fullWidth>
            <InputLabel id="blog-type-label">Select Blog Type</InputLabel>
            <Select
              labelId="blog-type-label"
              value={blogType}
              onChange={(e) => setBlogType(e.target.value)}
              required
            >
              <MenuItem value="nutrition">Nutrition Blog</MenuItem>
              <MenuItem value="general">General Blog</MenuItem>
            </Select>
          </FormControl>

          {/* Image Upload */}
          <Button
            variant="contained"
            component="label"
            disabled={uploading}
            sx={{ alignSelf: 'center', width: 'fit-content' }}
          >
            Upload Image
            <input
              type="file"
              hidden
              accept="image/*"
              onChange={handleImageUpload}
            />
          </Button>
          {image && (
            <Typography variant="body2" sx={{ mt: 1, textAlign: 'center' }}>
              {image.name}
            </Typography>
          )}

          {/* Color Picker */}
          <Button variant="outlined" onClick={handleColorPickerToggle} sx={{ alignSelf: 'center', width: 'fit-content' }}>
            Pick Text Color
          </Button>
          {showColorPicker && (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <ChromePicker color={color} onChange={handleColorChange} />
            </Box>
          )}

          {/* Submit Button */}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={uploading}
            sx={{ alignSelf: 'center', width: 'fit-content' }}
          >
            Submit
          </Button>

          {/* Loading and Error Handling */}
          {uploading && <CircularProgress sx={{ mt: 2, alignSelf: 'center' }} />}
          {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}
        </Box>
      </Box>
    </Container>
  );
};

export default NutritionBlogUpload;




// import React, { useState } from 'react';
// import {
//   Container,
//   TextField,
//   Typography,
//   Button,
//   Box,
//   CircularProgress,
//   Alert,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
// } from '@mui/material';
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';
// import { ChromePicker } from 'react-color'; 
// import useUploadBlogPost from '../hooks/useUploadBlogPost';

// const NutritionBlogUpload = () => {
//   const [title, setTitle] = useState('');
//   const [content, setContent] = useState('');
//   const [image, setImage] = useState(null);
//   const [blogType, setBlogType] = useState('nutrition'); // Default to 'nutrition'
//   const [color, setColor] = useState('#FFFFFF'); 
//   const [showColorPicker, setShowColorPicker] = useState(false);

//   const { uploadBlogPost, uploading, error } = useUploadBlogPost();

//   const handleImageUpload = (event) => {
//     const file = event.target.files[0];
//     if (file && file.type.startsWith('image/')) {
//       setImage(file);
//     }
//   };

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     if (title && content) {
//       const result = await uploadBlogPost(title, content, image, blogType, color);
//       if (result.success) {
//         setTitle('');
//         setContent('');
//         setImage(null);
//         setBlogType('nutrition'); 
//         alert('Blog post uploaded successfully!');
//       } else {
//         alert(`Error: ${result.error}`);
//       }
//     } else {
//       alert('Please fill out all fields.');
//     }
//   };

//   const handleColorPickerToggle = () => {
//     setShowColorPicker(!showColorPicker);
//   };

//   const handleColorChange = (newColor) => {
//     setColor(newColor.hex);
//   };

//   return (
//     <Container 
//       maxWidth="lg" 
//       sx={{ 
//         display: 'flex', 
//         justifyContent: 'center', 
//         alignItems: 'center', 
//         height: '100vh', 
//         overflow: 'auto' // Allow scrolling if content exceeds view
//       }}
//     >
//       <Box 
//         sx={{ 
//           p: 4, 
//           borderRadius: 2, 
//           bgcolor: 'rgba(255, 255, 255, 0.8)', 
//           boxShadow: 3, 
//           backdropFilter: 'blur(5px)', 
//           width: '100%', 
//           maxWidth: '800px', 
//           maxHeight: '90vh', // Limit height to prevent overflow
//           overflowY: 'auto'  // Enable scrolling for the form content
//         }}
//       >
//         <Typography variant="h4" component="h1" gutterBottom sx={{ textAlign: 'center' }}>
//           Create a New Blog Post
//         </Typography>
//         <Box 
//           component="form" 
//           onSubmit={handleSubmit} 
//           sx={{ 
//             display: 'flex', 
//             flexDirection: 'column', 
//             gap: 3,
//             maxHeight: '100%', // Ensure form stays within the screen
//             overflowY: 'auto'  // Enable scrolling inside the form
//           }}
//         >
//           <TextField
//             label="Title"
//             variant="outlined"
//             value={title}
//             onChange={(e) => setTitle(e.target.value)}
//             required
//             fullWidth
//           />
//           <Box sx={{ maxHeight: '200px', overflow: 'auto' }}>
//             <ReactQuill
//               value={content}
//               onChange={setContent}
//               modules={{
//                 toolbar: [
//                   ['bold', 'italic', 'underline'],
//                   [{ list: 'ordered' }, { list: 'bullet' }],
//                   ['link', 'image', 'video'],
//                   ['clean'],
//                 ],
//               }}
//               style={{ backgroundColor: 'white', color: 'black', minHeight: '200px' }}
//             />
//           </Box>

//           {/* Blog Type Selector */}
//           <FormControl fullWidth>
//             <InputLabel id="blog-type-label">Select Blog Type</InputLabel>
//             <Select
//               labelId="blog-type-label"
//               value={blogType}
//               onChange={(e) => setBlogType(e.target.value)}
//               required
//             >
//               <MenuItem value="nutrition">Nutrition Blog</MenuItem>
//               <MenuItem value="general">General Blog</MenuItem>
//             </Select>
//           </FormControl>

//           {/* Image Upload */}
//           <Button
//             variant="contained"
//             component="label"
//             disabled={uploading}
//             sx={{ alignSelf: 'center', width: 'fit-content' }}
//           >
//             Upload Image
//             <input
//               type="file"
//               hidden
//               accept="image/*"
//               onChange={handleImageUpload}
//             />
//           </Button>
//           {image && (
//             <Typography variant="body2" sx={{ mt: 1, textAlign: 'center' }}>
//               {image.name}
//             </Typography>
//           )}

//           {/* Color Picker */}
//           <Button variant="outlined" onClick={handleColorPickerToggle} sx={{ alignSelf: 'center', width: 'fit-content' }}>
//             Pick Text Color
//           </Button>
//           {showColorPicker && (
//             <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
//               <ChromePicker color={color} onChange={handleColorChange} />
//             </Box>
//           )}

//           {/* Submit Button */}
//           <Button
//             type="submit"
//             variant="contained"
//             color="primary"
//             disabled={uploading}
//             sx={{ alignSelf: 'center', width: 'fit-content' }}
//           >
//             Submit
//           </Button>

//           {/* Loading and Error Handling */}
//           {uploading && <CircularProgress sx={{ mt: 2, alignSelf: 'center' }} />}
//           {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}
//         </Box>
//       </Box>
//     </Container>
//   );
// };

// export default NutritionBlogUpload;





// import React, { useState } from 'react';
// import {
//   Container,
//   TextField,
//   Typography,
//   Button,
//   Box,
//   CircularProgress,
//   Alert,
// } from '@mui/material';
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css'; // Import styles
// import useUploadBlogPost from '../hooks/useUploadBlogPost';

// const NutritionBlogUpload = () => {
//   const [title, setTitle] = useState('');
//   const [content, setContent] = useState('');
//   const [image, setImage] = useState(null);
//   const { uploadBlogPost, uploading, error } = useUploadBlogPost();

//   const handleImageUpload = (event) => {
//     setImage(event.target.files[0]);
//   };

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     if (title && content && image) {
//       const result = await uploadBlogPost(title, content, image);
//       if (result.success) {
//         setTitle('');
//         setContent('');
//         setImage(null);
//         alert('Blog post uploaded successfully!');
//       } else {
//         alert(`Error: ${result.error}`);
//       }
//     } else {
//       alert('Please fill out all fields and upload an image.');
//     }
//   };

//   return (
//     <Container 
//       maxWidth="lg" 
//       sx={{ 
//         display: 'flex', 
//         justifyContent: 'center', 
//         alignItems: 'center', 
//         height: '100vh' 
//       }}
//     >
//       <Box 
//         sx={{ 
//           p: 4, 
//           borderRadius: 2, 
//           bgcolor: 'rgba(255, 255, 255, 0.8)', 
//           boxShadow: 3, 
//           backdropFilter: 'blur(5px)',
//           width: '100%',
//           maxWidth: '800px'
//         }}
//       >
//         <Typography 
//           variant="h4" 
//           component="h1" 
//           gutterBottom 
//           sx={{ textAlign: 'center' }}
//         >
//           Create a New Blog Post
//         </Typography>
//         <Box
//           component="form"
//           onSubmit={handleSubmit}
//           sx={{
//             display: 'flex',
//             flexDirection: 'column',
//             gap: 3,
//           }}
//         >
//           <TextField
//             label="Title"
//             variant="outlined"
//             value={title}
//             onChange={(e) => setTitle(e.target.value)}
//             required
//             fullWidth
//           />
//           <ReactQuill
//             value={content}
//             onChange={setContent}
//             modules={{
//               toolbar: [
//                 [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
//                 [{size: []}],
//                 ['bold', 'italic', 'underline', 'strike', 'blockquote'],
//                 [{'list': 'ordered'}, {'list': 'bullet'}, 
//                  {'indent': '-1'}, {'indent': '+1'}],
//                 ['link', 'image', 'video'],
//                 ['clean']
//               ],
//             }}
//             formats={[
//               'header', 'font', 'size',
//               'bold', 'italic', 'underline', 'strike', 'blockquote',
//               'list', 'bullet', 'indent',
//               'link', 'image', 'video'
//             ]}
//             style={{ backgroundColor: 'white', color: 'black', minHeight: '200px' }}
//           />
//           <Button
//             variant="contained"
//             component="label"
//             disabled={uploading}
//             sx={{ alignSelf: 'center', width: 'fit-content' }}
//           >
//             Upload Image
//             <input
//               type="file"
//               hidden
//               accept="image/*"
//               onChange={handleImageUpload}
//             />
//           </Button>
//           {image && (
//             <Typography 
//               variant="body2" 
//               sx={{ mt: 1, textAlign: 'center' }}
//             >
//               {image.name}
//             </Typography>
//           )}
//           <Button
//             type="submit"
//             variant="contained"
//             color="primary"
//             disabled={uploading}
//             sx={{ alignSelf: 'center', width: 'fit-content' }}
//           >
//             Submit
//           </Button>
//           {uploading && <CircularProgress sx={{ mt: 2, alignSelf: 'center' }} />}
//           {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}
//         </Box>
//       </Box>
//     </Container>
//   );
// };

// export default NutritionBlogUpload;
