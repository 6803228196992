import {
  CATEGORY_ERROR,
  CREATE_CATEGORY,
  GET_CATEGORYS,
  GET_CATEGORY_BY_ID,
  DELETE_CATEGORY,
} from "../actions/types";

const initialState = {
  Categorys: [],
  Category: null,
  loading: true,
  error: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_CATEGORYS:
      return {
        ...state,
        Categorys: payload,
        loading: false,
      };
    case DELETE_CATEGORY:
      console.log("Delete Category", state.Categorys);
      console.log("Delete Payload", payload);

      return {
        ...state,
        Categorys: {
          ...state.Categorys,
          data: state.Categorys.data.filter(
            (elem, i) => elem._id !== payload.id
          ),
        },
        loading: false,
      };
    case CREATE_CATEGORY:
      console.log("Add Category Payload", payload);
      return {
        ...state,
        Categorys: {
          ...state.Categorys,
          data: [...state.Categorys.data, payload],
        },
        // Categorys: [payload],
        loading: false,
      };
    case GET_CATEGORY_BY_ID: {
      return {
        ...state,
        Category: payload,
        loading: false,
      };
    }

    case CATEGORY_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }

    default:
      return state;
  }
}
