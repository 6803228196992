import React, { Fragment, useEffect, lazy, Suspense } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store/store";
import { LOGOUT } from "./actions/types";
import { loadUser } from "./actions/authAction";
import setAuthToken from "./utils/setAuthToken";
import { ToastContainer } from "react-toastify";
// import { LoginFooter } from "./Components/LoginFooter";
import Routes from './containers/routing/Routes'
import ViewLoader from './Components/ViewLoader'
import "react-toastify/dist/ReactToastify.css"
// import VideoPlayer from "./containers/Stream/VideoPlayer";
const Header = lazy(() => import("./Components/Header"));

const App = () => {
  useEffect(() => {
    localStorage.token && setAuthToken(localStorage.token);
    store.dispatch(loadUser());
    // log user out from all tabs if they log out in one tab
    window.addEventListener("storage", () => {
      if (!localStorage.token) store.dispatch({ type: LOGOUT });
    });
  }, []);

  return (
    <Fragment>
      <Provider store={store}>
        <BrowserRouter basename="/">
          <Suspense
            fallback={<><ViewLoader /></>}>
            <Fragment>
              <Header />
              <Switch>
                <Route component={Routes} />
                {/* <Route exact path="/settings"   component={Setting} /> */}
                {/* <Route exact path="/livestreams"   component={LiveStreams} /> */}
              </Switch>
              {/* <LoginFooter /> */}
            </Fragment>
          </Suspense>
        </BrowserRouter>
      <ToastContainer  autoClose={3000} />
      </Provider>
    </Fragment>
  );
};
export default App;
