import {
  GET_LIVE_STREAMS,
  GET_STREAM_BY_ID,
  SUBMIT_LIVE_INFO,
  GET_ALL_USER_STREAMS,
  UPDATE_STREAM_NULL,
  DELETE_STREAM
} from "../actions/types";

const initialState = {
  Videos: [],
  Streams: [],
  Stream: null,
  loading: true,
  loadingAllVideos: true,
  error: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_LIVE_STREAMS:
      return {
        ...state,
        Streams: payload,
        loading: false,
      };
    case GET_STREAM_BY_ID:
      return {
        ...state,
        Stream: payload,
        loading: false,
      };
    case SUBMIT_LIVE_INFO:
      return {
        ...state,
        Stream: payload,
        loading: false,
      };
    case GET_ALL_USER_STREAMS:
      return {
        ...state,
        Videos: payload,
        loadingAllVideos: false,
        loading: false,
        Stream: null,
      };
    case DELETE_STREAM:
      return {
        ...state,
        Videos: {
          ...state.Videos,
          data: state.Videos.data.filter((elem, i) => elem._id !== payload.id),
        },
      };
    case UPDATE_STREAM_NULL:
      let s = {
        ...state,
        Stream: null,
        loading: false,
      };
      console.log(s.Stream);
      return s;

    default:
      return state;
  }
}
