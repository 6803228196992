import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Button,
  CardActions,
  CircularProgress,
  Pagination, // Import Pagination component
} from '@mui/material';
import { styled } from '@mui/system';
import firebase from './firebase';

const CategoryCard = styled(Card)({
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
  },
  borderRadius: '16px', // Softer card corners
  overflow: 'hidden', // Ensure the image fits within the rounded corners
});

const fallbackImageUrl = '/images/workouts/a.png'; // Set the fallback image URL

const PaginationStyled = styled(Pagination)({
  '& .MuiPaginationItem-root': {
    color: 'white', // Set page number color to white
  },
  '& .Mui-selected': {
    backgroundColor: '#1976d2', // Primary blue for selected page
    color: 'white', // White text on selected page
  },
});

const isValidUrl = (url) => {
  try {
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
};

const Blogs = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 10;

  useEffect(() => {
    const database = firebase.database();
    const postsRef = database.ref('posts');

    const unsubscribe = postsRef.on('value', (snapshot) => {
      const postsData = snapshot.val();
      const postsArray = [];

      for (const key in postsData) {
        if (Object.hasOwnProperty.call(postsData, key)) {
          const post = postsData[key];
          postsArray.push({ id: key, ...post });
        }
      }

      // Sort posts by createdAt date in descending order (newest first)
      postsArray.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

      setPosts(postsArray);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  // Pagination logic
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  if (loading) {
    return (
      <Container maxWidth="lg" sx={{ mt: 6, mb: 4, display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 8, mb: 6 }}>
      <Typography
        variant="h3"
        component="h1"
        gutterBottom
        sx={{ fontWeight: 'bold', textAlign: 'center', mb: 4, color: '#333' }} // Adjust typography for a cleaner look
      >
        Blog Posts
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        {currentPosts.map((post) => (
          <Grid item key={post.id} xs={12} sm={6} md={4} lg={3}>
            <CategoryCard sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <CardMedia
                component="img"
                height="200" // Increased height for better visual impact
                image={isValidUrl(post.coverImageUrl) ? post.coverImageUrl : fallbackImageUrl} // Validate image URL
                alt={post.title}
                sx={{ objectFit: 'cover' }}
              />
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography variant="h6" component="h2" gutterBottom sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                  {post.title}
                </Typography>
                <Typography variant="body2" color="textSecondary" align="center" sx={{ mb: 2 }}>
                  Posted on {new Date(post.createdAt).toLocaleDateString()}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  align="center"
                  sx={{ mb: 2 }}
                  dangerouslySetInnerHTML={{ __html: `${post.content.substring(0, 150)}...` }}
                />
              </CardContent>
              <CardActions sx={{ justifyContent: 'center', mb: 2 }}>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  component={Link}
                  to={`/blog/${post.id}`}
                  sx={{ textTransform: 'none', fontWeight: 'bold' }}
                >
                  Read More
                </Button>
              </CardActions>
            </CategoryCard>
          </Grid>
        ))}
      </Grid>

      {/* Styled Pagination component */}
      <PaginationStyled
        count={Math.ceil(posts.length / postsPerPage)}
        page={currentPage}
        onChange={handlePageChange}
        sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}
      />
    </Container>
  );
};

export default Blogs;
