import React from "react";
import { Link } from "react-router-dom";
import WithSidebar from "../containers/layout/WithSidebar";

const WorkoutsPage = () => {
  const workouts = [
    {
      id: 1,
      title: "3 Day Workout Split",
      description: "Description for 3 Day Workout Split",
      imageSrc: "images/workouts/bench.png",
    },
    {
      id: 2,
      title: "4 Day Workout Split",
      description: "Description for 4 Day Workout Split",
      imageSrc: "/images/workouts/crunch.png",
    },
    {
      id: 3,
      title: "5 Day Workout",
      description: "Description for 5 Day Workout",
      imageSrc: "/images/workouts/pull.png",
    },
    {
      id: 4,
      title: "6 Day Workout",
      description: "Description for 6 Day Workout",
      imageSrc: "/images/workouts/pushup.png",
    },
    {
      id: 5,
      title: "6 Day Workout Split",
      description: "Description for 6 Day Workout Split",
      imageSrc: "/images/workouts/squats.png",
    },
    {
      id: 6,
      title: "Full Body Workout",
      description: "Description for Full Body Workout",
      imageSrc: "/images/workouts/crushers.png",
    },
  ];



  return (
    <WithSidebar>
      <div className="content">
        <h4 style={{ marginBottom: "20px" }}>Workouts</h4>
        <div className="row">
          {workouts.map((workout) => (
            <div key={workout.id} className="col-sm-6 col-md-4 col-lg-3">
              <Link to={`/workout/${workout.id}`}>
                <div className="item">
                  <div className="position-relative">
                    <img
                      src={workout.imageSrc}
                      alt={workout.title}
                      style={{ height: "300px", width: "420px" }}
                      className="img-fluid"
                    />
                  </div>
                  <div className="slider-caption mt-2">
                    <h5 className="title">{workout.title}</h5>
                    <p className="description">{workout.description}</p>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </WithSidebar>
  );
};

export default WorkoutsPage;

