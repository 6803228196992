import { GET_DASHBOARD_DATA, DASHBOARD_ERROR, GET_DASHBOARD_GRAPHS } from '../actions/types'
const initialState = {
    Dashboard: [],
    graphdata: [],
    loading: true
}

export default function (state = initialState, action) {
    const { type, payload } = action
    //console.log("payload,", payload)
    switch (type) {
        case GET_DASHBOARD_DATA:
            return {
                ...state,
                loading: false,
                Dashboard: payload,

            }
        case GET_DASHBOARD_GRAPHS:
            return {
                ...state,
                loading: false,
                graphdata: payload,
            }
        case DASHBOARD_ERROR:
            return {
                ...state,
                loading: false,
                Dashboard: [],
            }
        default:
            return state;
    }
}