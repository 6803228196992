import { useState } from 'react';
import firebase from '../firebase';

const useUploadBlogPost = () => {
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);

  const uploadBlogPost = async (title, content, image, blogType = 'nutrition', color = '#FFFFFF') => {
    setUploading(true);
    setError(null);

    try {
      let imageUrl = '';
      if (image) {
        const storageRef = firebase.storage().ref();
        const imageRef = storageRef.child(`images/${image.name}`);
        await imageRef.put(image);
        imageUrl = await imageRef.getDownloadURL();
      }

      const blogPost = {
        title,
        content,
        imageUrl,
        createdAt: firebase.database.ServerValue.TIMESTAMP,
        color,
      };

      // Save to the correct blog based on blogType
      const databaseRef = blogType === 'nutrition'
        ? firebase.database().ref('blogPosts') // Nutrition Blog
        : firebase.database().ref('posts');    // General Blog

      await databaseRef.push(blogPost);

      setUploading(false);
      return { success: true };
    } catch (error) {
      setUploading(false);
      setError(error.message);
      return { success: false, error: error.message };
    }
  };

  return { uploadBlogPost, uploading, error };
};

export default useUploadBlogPost;




// import { useState } from 'react';
// import firebase from '../firebase';

// const useUploadBlogPost = () => {
//   const [uploading, setUploading] = useState(false);
//   const [error, setError] = useState(null);

//   const uploadBlogPost = async (title, content, image) => {
//     setUploading(true);
//     setError(null);

//     try {
//       // Upload image to Firebase Storage
//       const storageRef = firebase.storage().ref();
//       const imageRef = storageRef.child(`images/${image.name}`);
//       await imageRef.put(image);
//       const imageUrl = await imageRef.getDownloadURL();

//       // Save blog post data to Firebase Realtime Database
//       const blogPost = {
//         title,
//         content,
//         imageUrl,
//         createdAt: firebase.database.ServerValue.TIMESTAMP,
//       };

//       const databaseRef = firebase.database().ref('blogPosts');
//       await databaseRef.push(blogPost);

//       setUploading(false);
//       return { success: true };
//     } catch (error) {
//       setUploading(false);
//       setError(error.message);
//       return { success: false, error: error.message };
//     }
//   };

//   return { uploadBlogPost, uploading, error };
// };

// export default useUploadBlogPost;
