import api from "../utils/api";
import { toast } from "react-toastify";
import {
  GET_LIVE_STREAMS,
  STREAM_ERROR,
  GET_STREAM_BY_ID,
  SUBMIT_LIVE_INFO,
  GET_ALL_USER_STREAMS,
  UPDATE_STREAM,
  UPDATE_STREAM_NULL,
  DELETE_STREAM,
} from "./types";

// Get all profiles
export const getStreams = (page, limit, keyword) => async (dispatch) => {
  try {
    const res = await api.get(
      `/streams/admin-videos?page=${page}&limit=${limit}&title[regex]=${
        keyword ?? ""
      }&title[options]=i`
    );
    dispatch({
      type: GET_LIVE_STREAMS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: STREAM_ERROR,
      payload: err,
    });
  }
};

// Get all profiles
export const getMyStreams = (page, limit, keyword) => async (dispatch) => {
  try {
    const res = await api.get(
      `/streams/myPastStreams?page=${page}&limit=${limit}&title[regex]=${
        keyword ?? ""
      }&title[options]=i`
    );
    dispatch({
      type: GET_LIVE_STREAMS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: STREAM_ERROR,
      payload: err,
    });
  }
};

// Get all profiles
export const commentStreaming = (streamId, commentText) => async (dispatch) => {
  try {
    const res = await api.post(`/streams/${streamId}/comments`, {
      commentText: commentText,
    });
    console.log(res);
    toast.success(` ${res.data.message}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  } catch (err) {
    toast.error(`${err.response.data.error}`);
    dispatch({
      type: STREAM_ERROR,
      payload: err,
    });
  }
};

// get stream details by id
export const getStreamDetailById = (id) => async (dispatch) => {
  try {
    const res = await api.get(`/streams/${id}`);
    dispatch({
      type: GET_STREAM_BY_ID,
      payload: res.data,
    });
  } catch (err) {
    toast.error(`${err.response.data.error}`);

    dispatch({
      type: STREAM_ERROR,
      payload: err,
    });
  }
};

// get stream details by id
export const toggleFeatured = (id) => async (dispatch) => {
  try {
    const res = await api.put(`/streams/${id}/toggleFeatured`);
    dispatch({ type: GET_STREAM_BY_ID, payload: res.data });
  } catch (err) {
    toast.error(`${err.response.data.error}`);

    dispatch({
      type: STREAM_ERROR,
      payload: err,
    });
  }
};

// get stream details by id
export const blockStreamer = (id) => async (dispatch) => {
  try {
    const res = await api.post(`/streams/${id}/block`);
    toast.success(` ${res.data.message}`);
    dispatch({ type: GET_STREAM_BY_ID, payload: res.data });
  } catch (err) {
    toast.error(`${err.response.data.error}`);

    dispatch({
      type: STREAM_ERROR,
      payload: err,
    });
  }
};

// get stream details by id
export const unBlockStreammer = (id) => async (dispatch) => {
  try {
    const res = await api.post(`/streams/${id}/unblock`);
    toast.success(` ${res.data.message}`);
    dispatch({ type: GET_STREAM_BY_ID, payload: res.data });
  } catch (err) {
    toast.error(`${err.response.data.error}`);

    dispatch({
      type: STREAM_ERROR,
      payload: err,
    });
  }
};
// get stream details by id
export const getStreamDetailByIdAdmin = (id) => async (dispatch) => {
  try {
    const res = await api.get(`/streams/admin/${id}`);
    dispatch({
      type: GET_STREAM_BY_ID,
      payload: res.data,
    });
  } catch (err) {
    toast.error(`${err.response.data.error}`);
    dispatch({
      type: STREAM_ERROR,
      payload: err,
    });
  }
};

// Create
export const submitLiveInfo =
  (title, genreId, categoryId, description, history) => async (dispatch) => {
    let data = { title, genreId, categoryId, description };
    console.log(data);
    try {
      const res = await api.post(`/streams`, data);
      console.log(res.data.stream);
      dispatch({
        type: SUBMIT_LIVE_INFO,
        payload: res.data.data,
      });
      toast.success(` ${res.data.message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      if (res.status == 200) {
        //history.push(`/genre`);
      }
    } catch (err) {
      if (!err.response) {
        toast.error(` Network Error !`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(` ${err.response.data.error}`);
      }
      dispatch({
        type: STREAM_ERROR,
        payload: err,
      });
    }
  };

// Get all videos of user
export const getAllVideosOfThisUser =
  (id, page, limit, status) => async (dispatch) => {
    try {
      const res = await api.get(
        `/streams?streamer=${id}&page=${page}&limit=${limit}`
      );
      dispatch({
        type: UPDATE_STREAM_NULL,
        payload: null,
      });
      dispatch({
        type: GET_ALL_USER_STREAMS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: STREAM_ERROR,
        payload: err,
      });
    }
  };

// Delete User Video
export const deleteVideo = (id) => async (dispatch) => {
  try {
    const res = await api.delete(`/streams/${id}`);
    if (res.status == 200) {
      toast.success(` Stream Delete successfully`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch({
        type: DELETE_STREAM,
        payload: { id },
      });
    }
  } catch (err) {
    dispatch({
      type: STREAM_ERROR,
      payload: err,
    });
  }
};

//update video by user
export const updateVideoDetail = (id, formData) => async (dispatch) => {
  console.log(formData);
  let description = formData.description;
  let visiblity = formData.visiblity;
  let categoryId = formData.categoryId;
  let genreId = formData.genreId;
  let title = formData.title;
  const body = {
    visiblity,
    categoryId,
    genreId,
    title,
    description,
  };
  try {
    const res = await api.put(`/streams/${id}`, body);
    dispatch({
      type: UPDATE_STREAM,
      payload: res.data,
    });
    toast.success(`${res.data.message}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  } catch (err) {
    toast.error(`${err.response.data.error}`);
  }
};

//setStreamNull
export const setStreamNull = () => async (dispatch) => {
  console.log("asd");
  try {
    dispatch({
      type: UPDATE_STREAM_NULL,
      payload: null,
    });
  } catch (err) {}
};
