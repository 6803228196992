import React from "react";
import { Modal } from "reactstrap";
import { ModalBody } from "reactstrap";

// export function ModalRight({modalPost, modalId, title, btnText, onBtnClick, children}) {
//     return (
//         <>
//         {modalPost && <div className="modal-right">
//             <ModalWrapper modalId={modalId} title={title} btnText={btnText} children={children}>
//                 {children}
//             </ModalWrapper>
//         </div>}
//         </>
//     )
// }

export function ModalRight(props) {
  const { isOpen, toggle, className, children, ...restProps } = props;
  return (
    <Modal
      // isOpen={props.modalSpeakers}
      // toggle={props.toggleModalSpeakers}
      // className="modal-has-page-tabs"
      isOpen={isOpen}
      toggle={toggle}
      className={className !== undefined ? className : ""}
      wrapClassName="modal-right"
      backdrop="static"
      keyboard={false}
      {...restProps}
    >
      {children}
    </Modal>
  );
}

export function ModalWrapper(props) {
  const { isOpen, toggle, className, title, children, ...restProps } = props;
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className={className !== undefined ? className : ""}
    //   wrapClassName="modal-right"
      backdrop="static"
      keyboard={false}
      {...restProps}
    >
      <ModalHeader toggle={toggle} title={title} />
      <ModalBody>{children}</ModalBody>
      {/* <ModalFooter btnText={btnText} onBtnClick={onBtnClick} /> */}
    </Modal>
  );
}

export function ModalHeader({ title, toggle }) {
  return (
    <>
      <div class="modal-header">
        <h5 class="modal-title">{title}</h5>
        <button type="button" class="close" onClick={toggle}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </>
  );
}

export function ModalFooter({ btnText, onBtnClick }) {
  return (
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" data-dismiss="modal">
        Close
      </button>

      <button
        onClick={onBtnClick}
        data-dismiss="modal"
        type="button"
        class="btn btn-danger"
      >
        {btnText}
      </button>
    </div>
  );
}

export const ModalBtnSubmit = ({ btnText }) => (
  <button type="submit" className="btn btn-danger" data-dismiss="modal">
    {btnText}
  </button>
);
