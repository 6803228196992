// workout3.js

const workout3Data = {
    description: `The below is just one example of what a body weight workout could look like. The 6-day split may be ideal for someone that does not have a gym membership or does not have any weights or equipment to train. It uses the person’s own body weight to do complete workouts. It can be done any day of week as many times as needed. Live stream your body weight workouts at https;//bahstream.com. Please feel free to adjust the below as needed, such as incorporating stretches or cardio into your workouts. Always remember to contact your health care provider before starting any workouts or exercises. `,
  
    day1: {
        title: "Day 1 – Chest",
        rows: 4, // Updated rows count
        columns: 9,
        data: [
          ["Exercise", "Sets", "Reps", "Muscle"],
          ["Barbell Bench Press", 4, "6-8", "Chest"],
          ["Incline Dumbbell Press", 4, "6-8", "Chest"],
          ["Machine Chest Press", 4, "8-10", "Chest"],
          ["Dumbbell Bench Press", 4, "6-8", "Chest"],
          ["Incline Barbell Press", 4, "6-8", "Chest"],
          ["Chest Fly's", 4, "8-10", "Chest"],
          ["Incline Machine Press", 4, "6-8", "Chest"],
          ["Pushups", 3, "6", "Chest"], // Updated to 3 sets of 6 reps
        ],
      },      
    
    day2: {
      title: "Day 2 – Back and Biceps",
      rows: 4, // Updated rows count
      columns: 10,
      data: [
        ["Exercise", "Sets", "Reps", "Muscle"],
        ["Lat Pulldowns", 4, "6-8", "Back"],
        ["Cable Row", 4, "6-8", "Back"],
        ["Pull Ups or Bench Row", 4, "6-8", "Back"],
        ["Back Extensions", 4, "6-8", "Back"],
        ["Hammer Curls", 4, "6-8", "Biceps"],
        ["Barbell Curls", 4, "6-8", "Biceps"],
        ["Preacher Curls", 4, "6-8", "Biceps"],
        ["Dumbbell Curls", 4, "6-8", "Biceps"],
      ],
    },
    
  
    day3: {
        title: "Day 3 – Shoulders",
        rows: 4, // Updated rows count
        columns: 7,
        data: [
          ["Exercise", "Sets", "Reps", "Muscle"],
          ["Dumbbell Front Raises", 4, "6-8", "Shoulders"],
          ["Dumbbell Side Raises", 4, "6-8", "Shoulders"],
          ["Seated Dumbbell Press", 4, "6-8", "Shoulders"],
          ["Dumbbell Shrugs", 4, "8-10", "Shoulders"],
          ["Standing Overhead Press", 4, "6-8", "Shoulders"],
          ["Seated Shoulder Press Machine", 4, "6-8", "Shoulders"],
        ],
      },
      
      day4: {
        title: "Day 4 – Legs",
        rows: 7,
        columns: 4,
        data: [
          ["Exercise", "Sets", "Reps", "Muscle"],
          ["Leg Extensions", 4, "6-8", "Legs"],
          ["Leg Curls", 4, "6-8", "Legs"],
          ["Leg Press", 4, "6-8", "Legs"],
          ["Deadlifts", 4, "6-8", "Legs"],
          ["Squats", 4, "6-8", "Legs"],
          ["Calf Raises", 4, "8-10", "Legs"],
        ],
      },
    
      day5: {
        title: "Day 5 – Arms",
        rows: 9,
        columns: 4,
        data: [
          ["Exercise", "Sets", "Reps", "Muscle"],
          ["Dumbbell Curls", 4, "6-8", "Biceps"],
          ["Preacher Curls", 4, "6-8", "Biceps"],
          ["Concentration Curls", 4, "6-8", "Biceps"],
          ["Hammer Curls", 4, "6-8", "Biceps"],
          ["Barbell Curls", 4, "6-8", "Biceps"],
          ["Skull Crushers", 4, "6-8", "Triceps"],
          ["One Handed Triceps Extension", 4, "6-8", "Triceps"],
          ["Triceps Pushdowns", 4, "6-8", "Triceps"],
        ],
      },
    
  };
  
  export default workout3Data;
  