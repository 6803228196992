import api from "../utils/api";
import { toast } from "react-toastify";
import {
  CATEGORY_ERROR,
  CREATE_CATEGORY,
  GET_CATEGORYS,
  GET_CATEGORY_BY_ID,
  UPDATE_CATEGORY,
  DELETE_CATEGORY,
} from "./types";

// Create new category
export const createCategory = (data) => async (dispatch) => {
  console.log(data);
  try {
    // /category/addCategory
    let status = data.status;
    let name = data.name;
    let file = data.file;
    // const body = { status, name, file };

    var body = new FormData();
    body.append("status", status);
    body.append("name", name);
    body.append("file", file);

    const res = await api.post(`/categories`, body);
    console.log(res.data.data);
    if (res.status === 200) {
      dispatch({
        type: CREATE_CATEGORY,
        payload: res.data.data,
      });
      toast.success(` ${res.data.message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

// Get genre by ID
export const deleteCategory = (id) => async (dispatch) => {
  try {
    const res = await api.delete(`/categories/${id}`);
    if (res.status === 200) {
      dispatch({
        type: DELETE_CATEGORY,
        payload: { id },
      });
      toast.success(` Category Deleted Successfully`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

// Get all
export const getCategories =
  (page, limit, categoryStatus, keyword) => async (dispatch) => {
    try {
      // order=-1&fieldname=createdAt&status=${categoryStatus}&page=${page}&limit=${limit}&keyword=${keyword}
      const res = await api.get(
        `/categories?page=${page}&name[regex]=${
          keyword ?? ""
        }&name[options]=i&limit=${limit}${
          categoryStatus !== undefined && categoryStatus !== "_"
            ? `&status=${categoryStatus}`
            : ""
        }`
      );
      dispatch({
        type: GET_CATEGORYS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: CATEGORY_ERROR,
        payload: err,
      });
    }
  };

// Get   by ID
export const getCategoryDetailByID = (category_id) => async (dispatch) => {
  try {
    const res = await api.get(`/categories/${category_id}`);
    console.log(res.data);
    dispatch({
      type: GET_CATEGORY_BY_ID,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: CATEGORY_ERROR,
      // payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

//update category
export const updateCategory =
  (categoryId, formData, history) => async (dispatch) => {
    let status = formData.status;
    let name = formData.name;
    let file = formData.file;
    // const body = { status, name, file };

    var body = new FormData();
    body.append("status", status);
    body.append("name", name);
    body.append("file", file);

    try {
      const res = await api.put(`/categories/${categoryId}`, body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(res);
      dispatch({
        type: UPDATE_CATEGORY,
        payload: res.data,
      });
      toast.success(`${res.data.message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (err) {
      const message = err.response.data.error;
      toast.error(`${message}`);
    }
  };

//upload image
export const updatePicture =
  (categoryId, picture, history) => async (dispatch) => {
    let image = picture;
    let body = { image };
    try {
      const res = await api.post(
        `/categories/uploadpicture/${categoryId}`,
        body
      );
      dispatch({
        type: UPDATE_CATEGORY,
        payload: res.data,
      });
      toast.success(`${res.data.message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (err) {
      console.log(err);
      const errors = err.response.data.errors;
      console.log(errors);
      if (errors) {
        errors.forEach((error) =>
          toast.error(`${error.msg}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        );
      }
    }
  };
