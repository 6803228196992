// workout2.js

const workout2Data = {
  description: `The below is just one example of what a 4-day split workout could look like. The 4-day split incorporates body parts that use similar motions to ensure an effective workout. Stream your 4-day fitness workouts at bahstream.com. Please feel free to adjust the below as needed, such as incorporating stretches and cardio into your workouts. Always remember to contact your health care provider before starting any workouts or exercises.`,

  day1: {
    title: "Day 1 – Chest and Triceps",
    rows: 4, // Updated rows count
    columns: 10,
    data: [
      ["Exercise", "Sets", "Reps", "Muscle"],
      ["Barbell Bench Press", 4, "6-8", "Chest"],
      ["Incline Dumbbell Press", 4, "6-8", "Chest"],
      ["Machine Chest Press or Chest Fly's", 4, "8-10", "Chest"],
      ["Dumbbell Bench Press", 4, "6-8", "Chest"],
      ["Incline Barbell Press", 4, "6-8", "Chest"],
      ["Triceps Machine Extensions", 4, "6-8", "Triceps"],
      ["Seated Triceps Extension", 4, "6-8", "Triceps"],
      ["Triceps Rope/V-bar Pushdown", 4, "8-10", "Triceps"],
    ],
  },
  
  day2: {
    title: "Day 2 – Back and Biceps",
    rows: 4, // Updated rows count
    columns: 10,
    data: [
      ["Exercise", "Sets", "Reps", "Muscle"],
      ["Lat Pulldowns", 4, "6-8", "Back"],
      ["Cable Row", 4, "6-8", "Back"],
      ["Pull Ups or Bench Row", 4, "6-8", "Back"],
      ["Back Extensions", 4, "6-8", "Back"],
      ["Hammer Curls", 4, "6-8", "Biceps"],
      ["Barbell Curls", 4, "6-8", "Biceps"],
      ["Preacher Curls", 4, "6-8", "Biceps"],
      ["Dumbbell Curls", 4, "6-8", "Biceps"],
    ],
  },
  

  day3: {
    title: "Day 3 – Legs",
    rows: 4, // Updated rows count
    columns: 7, // Updated columns count
    data: [
      ["Exercise", "Sets", "Reps", "Muscle"],
      ["Leg Extensions", 4, "6-8", "Legs"],
      ["Leg Curls", 4, "6-8", "Legs"],
      ["Leg Press", 4, "6-8", "Legs"],
      ["Deadlifts", 4, "6-8", "Legs"],
      ["Squats", 4, "6-8", "Legs"],
      ["Calf Raises", 4, "8-10", "Legs"],
    ],
  }, 
  
  day4: {
    title: "Day 4 – Shoulders and Abs",
    rows: 4,
    columns: 9,
    data: [
      ["Exercise", "Sets", "Reps", "Muscle"],
      ["Dumbbell Front Raises", 4, "6-8", "Shoulders"],
      ["Dumbbell Side Raises", 4, "6-8", "Shoulders"],
      ["Seated Dumbbell Press", 4, "6-8", "Shoulders"],
      ["Dumbbell Shrugs", 4, "8-10", "Shoulders"],
      ["Standing Overhead Press", 4, "6-8", "Shoulders"],
      ["Seated Shoulder Press Machine", 4, "6-8", "Shoulders"],
      ["Crunches", "To Failure", "To Failure", "Abs"],
      ["Toe Taps", "To Failure", "To Failure", "Abs"],
    ],
  },
  
};

export default workout2Data;
