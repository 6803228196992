import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import SvgIcon from "../../Components/SvgIcon";
import { logout } from "../../actions/authAction";
import { connect } from "react-redux";
import { useLocation, withRouter } from "react-router-dom";
import useFetch from "../../hooks/useFetch.js";
import { scrollToTop } from "../../utils/common";

export const Sidebar = ({ auth, logout }) => {
  const currentYear = new Date().getFullYear();
  const { pathname } = useLocation();
  const { data: lists, loading, error } = useFetch("/lists");
  // TODO: need to refetch
  return (
    <div className="sidebar">
      <ul className="sidenav border-bottom">
        {auth?.isAuthenticated && (
          <div>
            <Link to={"/stream"}>
              <button
                data-toggle="collapse"
                data-target="#collapsingNavbar"
                className="live-button"
              >
                <SvgIcon iconName="Live" />
                <b style={{ marginRight: "10px" }}></b>
                GO LIVE
              </button>
            </Link>
          </div>
        )}
        <li>
          <Link
            data-toggle="collapse"
            data-target="#collapsingNavbar"
            className={`${pathname === "/" ? "active" : ""}`}
            to={"/"}
          >
            <SvgIcon iconName="Home" /> Home
          </Link>
        </li>
        <li>
          <Link
            data-toggle="collapse"
            data-target="#collapsingNavbar"
            className={`${pathname === "/live-channel" ? "active" : ""}`}
            to={"/live-channel"}
          >
            <SvgIcon iconName="Play" /> Live Channels
          </Link>
        </li>
        <li>
          <Link
            data-toggle="collapse"
            data-target="#collapsingNavbar"
            className={`${pathname === "/all-stream" ? "active" : ""}`}
            to={"/all-stream"}
          >
            <SvgIcon iconName="Play" /> All Videos
          </Link>
        </li>
        <li>
          <Link
            data-toggle="collapse"
            data-target="#collapsingNavbar"
            className={`${pathname === "/workouts" ? "active" : ""}`}
            to={"/workouts"}
          >
            <SvgIcon iconName="FitnessIcon" /> Workouts
          </Link>
        </li>
        <li>
          <Link
            data-toggle="collapse"
            data-target="#collapsingNavbar"
            className={`${pathname === `/blogs` ? "active" : ""}`}
            to={"/blogs"}
          >
           <SvgIcon iconName="FitnessIcon" /> Articles
          </Link>
        </li>
        <li>
          <Link
            data-toggle="collapse"
            data-target="#collapsingNavbar"
            className={`${pathname === `/nutrition` ? "active" : ""}`}
            to={"/nutrition"}
          >
           <SvgIcon iconName="FitnessIcon" /> Nutrition
          </Link>
        </li>
        <li>
          <Link
            data-toggle="collapse"
            data-target="#collapsingNavbar"
            className={`${pathname === `/exercise` ? "active" : ""}`}
            to={"/exercise"}
          >
           <SvgIcon iconName="FitnessIcon" /> Excercise
          </Link>
        </li>
        {lists &&
          lists.data.map((e) => (
            <li key={e._id}>
              <Link
                data-toggle="collapse"
                data-target="#collapsingNavbar"
                className={`${pathname === `/lists/${e._id}` ? "active" : ""}`}
                to={`/lists/${e._id}`}
              >
                <SvgIcon iconName="Play" />
                {e.name}
              </Link>
            </li>
          ))}
      </ul>
      {auth?.isAuthenticated && (
        <ul className="sidenav border-bottom">
          <li>
            <Link
              data-toggle="collapse"
              data-target="#collapsingNavbar"
              className={`${pathname === `/follower` ? "active" : ""}`}
              to={"/follower"}
            >
              <SvgIcon iconName="Follower" /> Followers / Following
            </Link>
          </li>
        </ul>
      )}

      {auth?.isAuthenticated && (
        <ul className="sidenav border-bottom">
          <li>
            <Link
              data-toggle="collapse"
              data-target="#collapsingNavbar"
              className={`${pathname === `/mystreams` ? "active" : ""}`}
              to={"/mystreams"}
            >
              <SvgIcon iconName="Play" />
              My Past streams
            </Link>
          </li>
        </ul>
      )}

      {auth?.user?.data?.role === "ADMIN" && (
        <ul className="sidenav border-bottom">
          <li>
            <Link
              data-toggle="collapse"
              data-target="#collapsingNavbar"
              className={`${pathname === `/users` ? "active" : ""}`}
              to={"/users"}
            >
              <SvgIcon iconName="Play" /> Users
            </Link>
          </li>
          <li>
            <Link
              data-toggle="collapse"
              data-target="#collapsingNavbar"
              className={`${pathname === `/streams` ? "active" : ""}`}
              to={"/streams"}
            >
              <SvgIcon iconName="Paid" /> Videos
            </Link>
          </li>
          <li>
            <Link
              data-toggle="collapse"
              data-target="#collapsingNavbar"
              className={`${
                pathname === `/admin/settings/categories` ? "active" : ""
              }`}
              to={"/admin/settings/categories"}
            >
              <SvgIcon iconName="Playlist" /> Categories
            </Link>
          </li>
          <li>
            <Link
              data-toggle="collapse"
              data-target="#collapsingNavbar"
              className={`${pathname === `/list-items` ? "active" : ""}`}
              to={"/list-items"}
            >
              <SvgIcon iconName="Playlist" /> Lists/items
            </Link>
          </li>

          <li>
            <Link
              data-toggle="collapse"
              data-target="#collapsingNavbar"
              className={`${
                pathname === `/admin/settings/genres` ? "active" : ""
              }`}
              to={"/admin/settings/genres"}
            >
              <SvgIcon iconName="Followers" /> Genre
            </Link>
          </li>
        </ul>
      )}
      <ul className="sidenav border-bottom">
        <li>
          <Link
            data-toggle="collapse"
            data-target="#collapsingNavbar"
            className={`${pathname === `/help` ? "active" : ""}`}
            to={"/help"}
          >
            <SvgIcon iconName="Help" /> Help
          </Link>
        </li>
        {auth?.isAuthenticated ? (
          <li>
            <Link
              data-toggle="collapse"
              data-target="#collapsingNavbar"
              onClick={() => logout()}
            >
              <SvgIcon iconName="Logout" /> Sign out
            </Link>
          </li>
        ) : (
          <li>
            <Link
              data-toggle="collapse"
              data-target="#collapsingNavbar"
              className={`${pathname === `/login` ? "active" : ""}`}
              to={"/login"}
            >
              <SvgIcon iconName="Logout" /> Sign in
            </Link>
          </li>
        )}
      </ul>

      <ul className="sidenav border-bottom">
        <li>
          <Link
            data-toggle="collapse"
            data-target="#collapsingNavbar"
            className={`${pathname === `/privacy-policy` ? "active" : ""}`}
            to={"/privacy-policy"}
          >
            Privacy Policy
          </Link>
        </li>
        <li>
          <Link
            data-toggle="collapse"
            data-target="#collapsingNavbar"
            className={`${pathname === `/terms-of-service` ? "active" : ""}`}
            to={"/terms-of-service"}
          >
            Terms of Service
          </Link>
        </li>
        <li>
          <Link
            data-toggle="collapse"
            data-target="#collapsingNavbar"
            className={`${pathname === `/about` ? "active" : ""}`}
            to={"/about"}
          >
            About Us
          </Link>
        </li>
        <li>
          <Link
            data-toggle="collapse"
            data-target="#collapsingNavbar"
            className={`${
              pathname === `/community-guidelines` ? "active" : ""
            }`}
            to={"/community-guidelines"}
          >
            Community Guidelines
          </Link>
        </li>
        <li>
          <Link
            data-toggle="collapse"
            data-target="#collapsingNavbar"
            className={`${pathname === `/contact` ? "active" : ""}`}
            to={"/contact"}
          >
            Contact Us
          </Link>
        </li>
      </ul>

      <ul className="social clearfix">
        <a target={"_blank"} href={"https://www.facebook.com/bahstream/"}>
          <SvgIcon iconName="Facebook" />
        </a>
        <a target={"_blank"} href={"https://twitter.com/bah_stream"}>
          <SvgIcon iconName="Twitter" />
        </a>
        <a target={"_blank"} href={"https://www.instagram.com/bahstream/"}>
          <SvgIcon iconName="Instagram" />
        </a>
        <a target={"_blank"} href={"https://www.tiktok.com/@bahstream"}>
          <SvgIcon iconName="Tiktok" />
        </a>
      </ul>
      <ul>
      <li style={{ fontSize: "14px" }}>&copy; {currentYear} Bahstream</li>
    </ul>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { logout })(
  withRouter(function WithSidebar({ children, auth, logout }) {
    // Scroll to top of the page on route change
    useEffect(() => {
      scrollToTop();
    }, []);

    return (
      <div className="with-sidebar">
        <Sidebar auth={auth} logout={logout} />
        <div className="main">
          <div className="main-body">
            <div className="container-fluid">{children}</div>
          </div>
        </div>
      </div>
    );
  })
);
