import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import WithSidebar from "../layout/WithSidebar";
import SvgIcon from "../../Components/SvgIcon";
import { Grid, Card, Typography, CardContent } from "@mui/material"; // Import Material UI components for videos

// Hooks for fetching streams
const useFetch = (url) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(url);
        const result = await response.json();
        setData(result);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [url]);

  return { data, loading, error };
};

const AllStreams = () => {
  const [streams, setStreams] = useState([]);
  const [videos, setVideos] = useState([]); // State to store videos
  const [page, setPage] = useState(1);

  const {
    data,
    loading: liveStreamLoading,
    error,
  } = useFetch(`/streams?limit=10&published=true&page=${page}`);

  // Fetch streams
  useEffect(() => {
    if (data && data.data.length > 0) {
      setStreams((prevStreams) => [...prevStreams, ...data.data]);
    } else {
      fetchVideos(); // Fallback to fetch videos if no streams
    }
  }, [data]);

  // Fetch videos if no streams are available
  const fetchVideos = async () => {
    try {
      const response = await fetch("/videoList.json");
      const data = await response.json();
      setVideos(data);
    } catch (error) {
      console.error("Error fetching video files:", error);
    }
  };

  const getStreamTitle = (stream) => stream.title || "Untitled Stream";

  const getVideoTitle = (filename) =>
    filename.replace(/\.mp4$/, "").replace(/-/g, " ").replace(/^\d+\.\s*/, ""); // Remove leading numbers and dot

  return (
    <WithSidebar>
      <div className="">
        <div className="">
          <Typography variant="h4" gutterBottom style={{ marginBottom: "20px" }}>
            {streams.length > 0 ? "Live Streams" : "All Videos"}
          </Typography>

          {liveStreamLoading && streams.length === 0 && <h4>Loading...</h4>}
          {streams.length > 0 ? (
            <InfiniteScroll
              className="content-slider row"
              dataLength={data && data.pagination.totalPage}
              next={() => setPage(page + 1)}
              hasMore={data && (data.pagination.hasNextPage ?? true)}
              loader={<h4>Loading...</h4>}
            >
              {streams.map((item, index) => (
                <Link
                  key={index}
                  to={`/live/${item?._id}`}
                  className="col-sm-6 col-md-4 col-lg-3"
                >
                  <div className="item">
                    <div className="position-relative">
                      <div className="live-badge">LIVE</div>
                      <img
                        src={item?.thumbnail}
                        alt=""
                        style={{ height: "300px", width: "420px" }}
                        className="img-fluid"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = "/path/to/default/image.jpg"; // Default image if thumbnail fails
                        }}
                      />
                      <div className="viewers">
                        <SvgIcon iconName="Viewers" /> {item?.views}
                      </div>
                    </div>
                    <div className="slider-caption mt-2">
                      <p className="streamer">
                        {item?.streamer?.firstname} {item?.streamer?.lastname}
                      </p>
                      <h5 className="title">{getStreamTitle(item)}</h5>
                      <p className="category">
                        {item?.categoryId === null
                          ? "Uncategorized"
                          : item?.categoryId?.name}
                      </p>
                    </div>
                  </div>
                </Link>
              ))}
            </InfiniteScroll>
          ) : (
            <Grid container spacing={4}>
              {videos.map((video, index) => (
                <Grid item key={index} xs={12} sm={6} md={4}>
                  <Card style={{ boxShadow: "none", backgroundColor: "transparent" }}>
                    <div
                      style={{
                        position: "relative",
                        paddingBottom: "56.25%",
                        height: 0,
                        overflow: "hidden",
                      }}
                    >
                      <video
                        controls
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          backgroundColor: "black",
                        }}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.poster = "/path/to/default/poster.jpg"; // Display a default image if the video fails to load
                          e.target.src = ""; // Remove video source if it fails to load
                        }}
                      >
                        <source src={`/videos/${video}`} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                    <CardContent>
                      <Typography variant="h6" align="center" style={{ color: "white" }}>
                        {getVideoTitle(video)}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          )}
        </div>
      </div>
    </WithSidebar>
  );
};

AllStreams.propTypes = {
  auth: PropTypes.object,
  genre: PropTypes.object,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  genre: state.genre,
});

export default connect(mapStateToProps, {
  // Add your action creators here if needed
})(AllStreams);




// import React, { useEffect, Fragment, useState } from "react";
// import { Link, withRouter, useLocation } from "react-router-dom";
// import PropTypes from "prop-types";
// import { connect } from "react-redux";
// import { getDashboard, getDashboardGraphs } from "../../actions/dashboardRoute";
// import { getAllGenre } from "../../actions/genreAction";
// import { Bar, Doughnut } from "react-chartjs-2";
// import { logout } from "../../actions/authAction";
// import OwlCarousel from "react-owl-carousel";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";
// import WithSidebar from "../layout/WithSidebar";
// import SvgIcon from "../../Components/SvgIcon";
// import "oAll Videost [streams, setstreams] = useState([]);

//   //TODO: use this for top live
//   const {
//     data,
//     loading: liveStreamLoading,
//     error,
//   } = useFetch(`/streams?limit=10?published=true&page=${page}`);

//   console.log(data?.data);

//   useEffect(() => {
//     if (!data) return;
//     setstreams([...streams, ...data.data]);
//   }, [data]);

//   return (
//     <WithSidebar>
//       <div className="">
//         <div className="">
//           <h4 style={{ marginBottom: "20px" }}>All Videos</h4>
//           {/* TODO: implement infinite scrolling */}
//           {liveStreamLoading && streams.length < 0 && <ViewLoader />}
//           {streams.length < 0 ? (
//             <RecordNotFound />
//           ) : (
//             <InfiniteScroll
//               className="content-slider row"
//               dataLength={data && data.pagination.totalPage}
//               next={(_) => {
//                 setPage(page + 1);
//                 console.log("Next page", _);
//               }}
//               hasMore={data && (data.pagination.hasNextPage ?? true)}
//               loader={<h4>Loading...</h4>}
//               // endMessage={
//               //   <p style={{ textAlign: "center" }} className="col-12">
//               //     <b>Yay! You have seen it all</b>
//               //   </p>
//               // }
//             >
//               {streams.map((item, index) => (
//                 <Link
//                   key={index}
//                   to={`/live/${item?._id}`}
//                   className="col-sm-6 col-md-4 col-lg-3"
//                 >
//                   <div className="item">
//                     <div className="">
//                       <div className="position-relative">
//                         <div className="live-badge">LIVE</div>
//                         <img
//                           src={item?.thumbnail}
//                           alt=""
//                           style={{ height: "300px", width: "420px" }}
//                           className="img-fluid"
//                         />
//                         <div className="viewers">
//                           <SvgIcon iconName="Viewers" /> {item?.views}
//                         </div>
//                       </div>
//                       <div className="slider-caption mt-2">
//                         <p className="streamer">
//                           ${item?.streamer?.firstname} $
//                           {item?.streamer?.lastname}
//                         </p>
//                         <h5 className="title">{item?.title}</h5>
//                         <p className="category">
//                           {item?.categoryId === null
//                             ? "Uncategorized"
//                             : item?.categoryId?.name}
//                         </p>
//                       </div>
//                     </div>
//                   </div>
//                 </Link>
//               ))}
//             </InfiniteScroll>
//           )}
//         </div>
//       </div>
//     </WithSidebar>
//   );
// };

// AllStreawm.propTypes = {};

// const mapStateToProps = (state) => ({
//   auth: state.auth,
//   genre: state.genre,
// });

// export default connect(mapStateToProps, {
//   logout,
//   getAllGenre,
// })(withRouter(AllStreawm));
