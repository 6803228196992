import WithSidebar from "../layout/WithSidebar.js";
import { useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch.js";
import CategoryListing, { CategoryBox } from "../../Components/CategoryListing.jsx";

const ItemsPage = () => {
  const { listId } = useParams();
  const { data: items, loading, error } = useFetch(`/lists/${listId}/items`);
  return (
    <WithSidebar>
      <div className="">
        <div className="pb-1">
          <h4 style={{ marginBottom: "20px" }}>Items</h4>
          <CategoryListing>
            {items?.data?.map((e) => (
              <CategoryBox image={e.image} name={e.name} />
            ))}
          </CategoryListing>
        </div>
      </div>
    </WithSidebar>
  );
};

export default ItemsPage;
