import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import SvgIcon from "./SvgIcon";
import { Link } from "react-router-dom";

export default function ContentSlider({ items, isLive = false }) {
  const holderImage = "/images/holder/holder-live.png";
  return (
    <OwlCarousel
      className="owl-carousel owl-theme content-slider"
      items={items.lenght}
      margin={24}
      nav={false}
      responsive={{
        0: {
          items: 1,
        },
        600: {
          items: 2,
        },
        1000: {
          // stagePadding: 150,
          items: 3,
        },
        1200: {
          // stagePadding: 250,
          items: 4,
        },
        //   1500: {
        //     // stagePadding: 350,
        //     items: 1,
        //   },
      }}
    >
      {items?.map((item, index) => (
        // <div className="item">
        //     <img src={item.image} alt="" className="img-fluid" />
        //     <div className="transparent-bg">
        //         <div className="live-badge">
        //             LIVE
        //         </div>
        //         <div className="slider-caption">
        //             <div className="streamer">{item.streamer}</div>
        //             <h4 className="title">{item.title}</h4>
        //             <div className="category">{item.category}</div>
        //         </div>
        //     </div>
        // </div>
        <Link to={`/live/${item.id}`}>
          <div key={index} className="item">
            <div className="">
              <div
                className="position-relative img-cover border-radius-14"
                style={{ backgroundImage: `url('${item.image}')` }}
              >
                {isLive && <div className="live-badge">LIVE</div>}
                <img src={holderImage} alt="" className="img-fluid" />
                <div className="viewers">
                  <SvgIcon iconName="Viewers" /> {item.viewers}
                </div>
              </div>
              <div className="slider-caption mt-2">
                <p className="streamer">
                  {`${item.streamer?.firstname} ${item.streamer?.lastname}`}
                  {item.streamer.streamerVerifiedStatus === "Verified" ? (
                    <SvgIcon iconName="Verified" />
                  ) : (
                    ""
                  )}
                </p>
                <h5 className="title">{item.title}</h5>
                <p className="category">{item.category}</p>
              </div>
            </div>
          </div>
        </Link>
      ))}
    </OwlCarousel>
  );
}
