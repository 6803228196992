// workout4.js

const workout4Data = {
  description: `The below is just one example of what a 6-day workout could look like. The 6-day workout is great if you want to tackle one specific body part a day and have more time to go to the gym during the week. It ensures you are effectively hitting all parts of your body by spreading each body part throughout the week. Stream your 6-day fitness workouts at bahstream.com. Please feel free to adjust the below as needed, such as incorporating stretches or cardio into your workouts. Always remember to contact your health care provider before starting any workouts or exercises. `,
  
  day1: {
      title: "Day 1 – Chest",
      rows: 7,
      columns: 4,
      data: [
          ["Exercise", "Sets", "Reps", "Muscle"],
          ["Barbell Bench Press", 4, "6-8", "Chest"],
          ["Incline Dumbbell Press", 4, "6-8", "Chest"],
          ["Machine Chest Press", 4, "8-10", "Chest"],
          ["Dumbbell Bench Press", 4, "6-8", "Chest"],
          ["Incline Barbell Press", 4, "6-8", "Chest"],
          ["Chest Dips", 4, "8-10", "Chest"],
          ["Incline Machine Press", 4, "6-8", "Chest"],
      ],
  },

  day2: {
      title: "Day 2 – Back",
      rows: 6,
      columns: 4,
      data: [
          ["Exercise", "Sets", "Reps", "Muscle"],
          ["Lat Pulldowns", 4, "6-8", "Back"],
          ["Seated Cable Row", 4, "6-8", "Back"],
          ["Machine Lat Pulldowns", 5, "6-8", "Back"],
          ["Machine Barbell Rows", 4, "6-8", "Back"],
          ["Back Extensions", 3, "8-10", "Back"],
          ["Barbell Row", 4, "6-8", "Back"],
      ],
  },

  day3: {
      title: "Day 3 – Shoulders",
      rows: 7,
      columns: 4,
      data: [
          ["Exercise", "Sets", "Reps", "Muscle"],
          ["Dumbbell Front Raises", 4, "6-8", "Shoulders"],
          ["Dumbbell Side Raises", 4, "6-8", "Shoulders"],
          ["Seated Dumbbell Press", 4, "6-8", "Shoulders"],
          ["Shrugs", 4, "8-10", "Shoulders"],
          ["Seated Barbell Press", 4, "6-8", "Shoulders"],
          ["Seated Shoulder Press Machine", 4, "6-8", "Shoulders"],
      ],
  },

  day4: {
      title: "Day 4 – Legs",
      rows: 8,
      columns: 4,
      data: [
          ["Exercise", "Sets", "Reps", "Muscle"],
          ["Leg Extensions", 4, "6-8", "Legs"],
          ["Leg Curls", 4, "6-8", "Legs"],
          ["Leg Press", 4, "6-8", "Legs"],
          ["Deadlifts", 4, "6-8", "Legs"],
          ["Squats", 4, "6-8", "Legs"],
          ["Calf Raises", 4, "8-10", "Legs"],
          ["Hip Abductions", 8, "8", "Legs"],
          ["Hip Adductions", 8, "8", "Legs"],
      ],
  },
  day5: {
      title: "Day 5 – Arms",
      rows: 10,
      columns: 4,
      data: [
          ["Exercise", "Sets", "Reps", "Muscle"],
          ["Dumbbell Curls", 4, "6-8", "Biceps"],
          ["Preacher Curls", 4, "6-8", "Biceps"],
          ["Concentration Curls", 3, "6-8", "Biceps"],
          ["Hammer Curls", 4, "6-8", "Biceps"],
          ["Barbell Curls", 4, "6-8", "Biceps"],
          ["Skull Crushers", 4, "6-8", "Triceps"],
          ["One Handed Triceps Extension", 4, "6-8", "Triceps"],
          ["Triceps Pushdowns", 4, "6-8", "Triceps"],
      ],
  },

  day6: {
      title: "Day 6 – Abs/Cardio",
      rows: 10,
      columns: 4,
      data: [
          ["Exercise", "Sets", "Reps", "Muscle"],
          ["Leg Raises", 4, "6-8", "Abs"],
          ["Planks", 4, "6-8", "Abs"],
          ["Flutter Kicks", 3, "6-8", "Abs"],
          ["Sit-ups", 4, "6-8", "Abs"],
          ["Elbow to Knee", 4, "6-8", "Abs"],
          ["Seated Shoulder Press Machine", 4, "6-8", "Abs"],
          ["V-ups", 4, "6-8", "Abs"],
          ["Toe Taps", 4, "6-8", "Abs"],
      ],
  },
};
  
  export default workout4Data;
  