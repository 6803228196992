import api from "../utils/api";
import { toast } from "react-toastify";
import {
  SUBSCRIBE_STREAMER,
  CHECK_SUSBRIBED_CHANNEL,
  SUBSCRIBE_ERROR,
} from "./types";

// Create
export const Subscribe = (data, history) => async (dispatch) => {
  const streamerId = data.streamerId;

  try {
    const res = await api.post(`/subscriber/${streamerId}/follow`, data);

    dispatch({
      type: SUBSCRIBE_STREAMER,
      payload: res.data,
    });
    toast.success(` ${res.data.message}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    // dispatch (CheckSubscribedStreamer(data))
  } catch (err) {
    if (!err.response) {
      toast.error(` Network Error !`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      console.log(err.response.data);
      const errors = err.response.data.errors;

      if (errors) {
        errors.forEach((error) =>
          toast.error(` ${error.msg}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        );
      }
    }
    dispatch({
      type: SUBSCRIBE_ERROR,
      payload: err,
    });
  }
};

export const CheckSubscribedStreamer = (data) => async (dispatch) => {
  try {
    const res = await api.post(`/subscribe/check`, data);
    // console.log(res)
    dispatch({
      type: CHECK_SUSBRIBED_CHANNEL,
      payload: res?.data,
    });
  } catch (err) {
    dispatch({
      type: SUBSCRIBE_ERROR,
      // payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
