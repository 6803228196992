import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Subscribe } from "../actions/subscriberActions";
import useFetch from "../hooks/useFetch";
import {
  ModalBtnSubmit,
  ModalRight,
} from "../Components/modal/ModalComponents";
import { ModalHeader, ModalBody, Button } from "reactstrap";

function Follow({ Subscribe, isLarge = true, streamerId }) {
  const [following, setFollowing] = useState(false);

  const [unfollowConformation, setUnfollowConformation] = useState(false);

  const toggleUnfollowConformation = () => {
    setUnfollowConformation(!unfollowConformation);
  };
  const {
    data: item,
    loading,
    error,
  } = useFetch(`/subscriber/${streamerId}/check`);

  console.log("item", item);

  useEffect(() => {
    setFollowing(item?.followed);
  }, [item]);

  const follow = (streamerId) => {
    // setNewCategoryAdded(false);
    Subscribe({ streamerId: streamerId }).then((e) => {
      console.log(e);
      // setNewCategoryAdded(true);
      // setShowAddCategoryForm(false);
      setFollowing(!following);
    });
  };

  return (
    <>
      {following ? (
        <>
          <ModalRight
            isOpen={unfollowConformation}
            toggle={toggleUnfollowConformation}
            // className="modal-two-column"
          >
            <ModalHeader toggle={toggleUnfollowConformation}>
              Heads Up!
            </ModalHeader>

            <ModalBody>
              <p>Are you sure wanna unfollow this streammer?</p>

              <button
                onClick={() => {
                  follow(streamerId);
                  toggleUnfollowConformation();
                }}
                data-dismiss="modal"
                type="button"
                class="btn btn-danger"
              >
                Unfollow
              </button>
            </ModalBody>
          </ModalRight>
          <button
            className={`btn ${
              isLarge ? "btn-lg btn-primary" : "btn-sm btn-danger"
            } `}
            onClick={() => {
              toggleUnfollowConformation();
            }}
          >
            Unfollow
          </button>
        </>
      ) : (
        <button
          className={`btn ${
            isLarge ? "btn-lg btn-primary" : "btn-sm btn-danger"
          } `}
          onClick={() => {
            follow(streamerId);
          }}
        >
          Follow
        </button>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  // category: state.category,
});

export default connect(mapStateToProps, {
  Subscribe,
})(Follow);
