import ViewLoader from "../../Components/ViewLoader";
import useFetch from "../../hooks/useFetch.js";
import WithSidebar from "../layout/WithSidebar";
import { useState } from "react";
import TablePage from "../../Components/table/TablePage";
import { getSerial } from "../../utils/commonFunctions";
import { connect } from "react-redux";
import FollowerFOllowingTable from "./follower_following_table.js";

import {
  getFollowers,
  getFollowing,
  followStreammer,
  unfollowStreammer,
} from "../../actions/followerAction.js";
import moment from "moment";
import { ModalWrapper } from "../../Components/modal/ModalComponents.js";
import { base, baseUrl, liveUrl } from "../../utils/base_url.js";

const Followers = ({
  getFollowers,
  getFollowing,
  followStreammer,
  unfollowStreammer,
  follow: { Followers, Following, loading },
}) => {
  const [page, setPage] = useState(1);
  const [limit, setlimit] = useState(10);
  const [keyword, setKeyword] = useState("");

  const handlePageClick = (data) => {
    let selected = data.selected + 1;
    setPage(selected);
  };
  //TODO: use this for loading following
  const {
    data: following,
    loading: followingLoading,
    error: followingError,
  } = useFetch(
    `/subscriber/getFollowing?q=${keyword}&page=${page}&limit=${limit}`
  );
  //TODO: use this for follower
  const {
    data: followers,
    loading: followerLoading,
    error: followerError,
  } = useFetch(
    `/subscriber/getFollower?q=${keyword}&page=${page}&limit=${limit}`
  );

  console.log("Follower", followers);
  console.log("Following", following);

  return (
    <WithSidebar>
      <>
        <div className="">
          <div className="">
            <div className="app-content content dashboard">
              <div className="content-wrapper">
                <div className="content-body">
                  {/* Basic form layout section start */}
                  <section id="configuration">
                    <div className="row">
                      <div className="col-12">
                        <div className>
                          <div className="card rounded">
                            <div className="card-header- text-white bg-dark">
                              <h5 className="m-0">Followers</h5>
                            </div>

                            <div className="card-content collapse show">
                              <div
                                style={{ padding: "0" }}
                                className="card-body"
                              >
                                <nav>
                                  <div
                                    className="nav nav-tabs text-dark-"
                                    id="nav-tab"
                                    role="tablist"
                                  >
                                    <a
                                      className="nav-item nav-link active"
                                      id="nav-home-tab"
                                      data-toggle="tab"
                                      href="#nav-home"
                                      role="tab"
                                      aria-controls="nav-home"
                                      aria-selected="true"
                                    >
                                      My Follower
                                    </a>

                                    <a
                                      className="nav-item nav-link"
                                      id="nav-4-tab"
                                      data-toggle="tab"
                                      href="#nav-4"
                                      role="tab"
                                      aria-controls="nav-4"
                                      aria-selected="false"
                                    >
                                      My Following
                                    </a>
                                  </div>
                                </nav>

                                <div
                                  className="tab-content"
                                  style={{ padding: "0", margin: "0" }}
                                  id="nav-tabContent"
                                >
                                  {/* nav-home */}
                                  <div
                                    className="tab-pane fade show active p-3"
                                    id="nav-home"
                                    role="tabpanel"
                                    style={{ padding: "0", margin: "0" }}
                                    aria-labelledby="nav-home-tab"
                                  >
                                    <FollowerFOllowingTable
                                      data={followers}
                                      loading={followerLoading}
                                      error={followerError}
                                      handlePageClick={handlePageClick}
                                      setlimit={setlimit}
                                      limit={limit}
                                      keyword={keyword}
                                      _key={"follower"}
                                      setKeyword={setKeyword}
                                    />
                                  </div>

                                  <div
                                    className="tab-pane fade p-3"
                                    id="nav-4"
                                    role="tabpanel"
                                    style={{ padding: "0", margin: "0" }}
                                    aria-labelledby="nav-4-tab"
                                  >
                                    <FollowerFOllowingTable
                                      data={following}
                                      loading={followingLoading}
                                      error={followingError}
                                      handlePageClick={handlePageClick}
                                      setlimit={setlimit}
                                      limit={limit}
                                      keyword={keyword}
                                      _key={"following"}
                                      setKeyword={setKeyword}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </WithSidebar>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  follow: state.follow,
});

export default connect(mapStateToProps, {
  getFollowers,
  getFollowing,
  followStreammer,
  unfollowStreammer,
})(Followers);
