import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";
import SvgIcon from "./SvgIcon.jsx";
import { baseUrl, liveUrl } from "../utils/base_url.js";
import { useState } from "react";
import ReactPlayer from "react-player";

export default function FeaturedSlider({ items, isLive = false }) {
  const holderImage = "/images/holder/holder-featured.png";

  const playerRef = React.useRef(null);

  React.useEffect(() => {
    if (playerRef) {
      playerRef.current?.showPreview();
    }
  }, [items]);

  return (
    <OwlCarousel
      className="owl-carousel owl-theme featured-slider"
      items={2}
      margin={30}
      nav={false}
      responsive={{
        0: {
          items: 1,
        },
        768: {
          items: 2,
        },
        //   1000: {
        //     // stagePadding: 150,
        //     items: 1,
        //   },
        //   1300: {
        //     // stagePadding: 250,
        //     items: 2,
        //   },
        //   1500: {
        //     // stagePadding: 350,
        //     items: 1,
        //   },
      }}
      onContextMenu={(e) => e.preventDefault()}
    >
      {items?.map(
        (item, index) => (
          <div className="item img-cover">
            <ReactPlayer
              ref={playerRef}
              url={`${baseUrl}/videos/${item.streamKey}`}
              controls
              previewTabIndex={index}
              onContextMenu={(e) => e.preventDefault()}
              controlsList="nodownload"
              width={"100%"}
              playing
              light={item.image}
            />
          </div>
        )
        // <Link to={`/live/${item.id}`}>

        // playingVideoId && playingVideoId === item.id ? (
        //   <video
        //     key={index}
        //     className="item img-cover"
        //     id="video-preview"
        //     src={`${baseUrl}/videos/${item.streamKey}`}
        //     style={{
        //       width: "100%",
        //       // height: "390px",
        //     }}
        //     muted
        //     controls
        //     onContextMenu={(e) => e.preventDefault()}
        //     controlsList="nodownload"
        //   ></video>
        // ) : (
        //   <div
        //     onClick={(e) => {
        //       setPalying(item.id);
        //     }}
        //   >
        //     <div
        //       key={index}
        //       style={{ backgroundImage: `url('${item.image}')` }}
        //       className="item img-cover"
        //     >
        //       <img src={holderImage} alt="" className="img-fluid" />
        //       <div className="transparent-bg">
        //         {isLive && <div className="live-badge">LIVE</div>}
        //         {/* <div className="slider-caption">
        //       <div className="streamer">
        //         {`${item.streamer?.firstname} ${item.streamer?.lastname}`}
        //         {item.streamer.streamerVerifiedStatus === "Verified" ? (
        //           <SvgIcon iconName="Verified" />
        //         ) : (
        //           ""
        //         )}
        //       </div> */}
        //         {/* <h4 className="title">{item.title}</h4> */}
        //         {/* <div className="category">{item.category}</div> */}
        //         {/* </div> */}
        //       </div>
        //     </div>
        //     // <img src={holderImage} alt="" className="img-fluid" />
        //   </div>
        //   // </Link>
        // )

        // <div
        //   key={index}
        //   // style={{ backgroundImage: `url('${item.image}')` }}
        //   className="item img-cover"
        // >
        //   <video
        //     // className="img-fluid"
        //     id="video-preview"
        //     src={`${baseUrl}/videos/${item.streamKey}`}
        //     style={{ width: "100%",height:"500px" }}
        //     muted
        //     controls
        //     controlsList="nodownload"
        //   ></video>
        //   {/* <img src={holderImage} alt="" className="img-fluid" /> */}
        //   <div className="transparent-bg">
        //     {isLive && <div className="live-badge">LIVE</div>}
        //     {/* <div className="slider-caption">
        //         <div className="streamer">
        //           {`${item.streamer?.firstname} ${item.streamer?.lastname}`}
        //           {item.streamer.streamerVerifiedStatus === "Verified" ? (
        //             <SvgIcon iconName="Verified" />
        //           ) : (
        //             ""
        //           )}
        //         </div> */}
        //     {/* <h4 className="title">{item.title}</h4> */}
        //     {/* <div className="category">{item.category}</div> */}
        //     {/* </div> */}
        //   </div>
        // </div>
        // </Link>
      )}
    </OwlCarousel>
  );
}
