import firebase from 'firebase/compat/app'; // Import the compat version to avoid conflicts with other packages
import 'firebase/compat/database'; // Import the compat version for the Realtime Database
import 'firebase/compat/auth'; // Import the compat version for authentication
import 'firebase/compat/storage'

const firebaseConfig = {
  apiKey: "AIzaSyAusjRrs6vLaGmRe37t0ioKa4NgPzJljE4",
  authDomain: "bahstream.firebaseapp.com",
  databaseURL: "https://bahstream-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "bahstream",
  storageBucket: "bahstream.appspot.com",
  messagingSenderId: "922479555824",
  appId: "1:922479555824:web:0e6c960abe0c644775be35",
  measurementId: "G-Y7EEDZKKFE"
};


// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// Export the initialized Firebase instance
export default firebase;
