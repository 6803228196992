import React from "react";
import Pagination from "../Pagination";
import SvgIcon from "../SvgIcon";

export default function TablePage({
  title,
  onAddNewBtnClick,
  addNewBtnText,
  filters,
  dropdowns,
  limit,
  setlimit,
  pagination,
  handlePageClick,
  children,
  paddingTop,
}) {
  return (
    <div
      style={paddingTop && { paddingTop: "0", paddingLeft: "0" }}
      className=""
    >
      <div className="">
        <div className="card rounded">
          <TablePageTitle
            title={title}
            onAddNewBtnClick={onAddNewBtnClick}
            addNewBtnText={addNewBtnText}
          />

          <div className="card-body">
            <TableFilter filters={filters} dropdowns={dropdowns} />
            <div className={`table-responsive mb-3`}>
              <table
                className="table table-sm table-striped table-bordered table-hover"
                id="DataTables_Table_0"
                role="grid"
                aria-describedby="DataTables_Table_0_info"
              >
                {children}
              </table>
            </div>

            <TableFooter
              limit={limit}
              setlimit={setlimit}
              pagination={pagination}
              handlePageClick={handlePageClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export function TablePageTitle({ title, onAddNewBtnClick, addNewBtnText }) {
  return (
    <>
      <div className="card-header">
        <div className="row">
          <div className="col-6">
            <h5 className="m-0">{title}</h5>
          </div>
          {addNewBtnText && (
            <div className="col-6 text-right">
              <button
                type="button"
                className="btn btn-dark text-uppercase px-5"
                onClick={onAddNewBtnClick && onAddNewBtnClick}
              >
                {addNewBtnText}
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export function TableFilter({ filters, dropdowns }) {
  return (
    <>
      <div className="row table-filter">
        {dropdowns?.map((dropdown, index) => (
          <div key={index} className="col-sm-12 col-md-3">
            <div className="input-group mb-3">
              <div className="input-group-prepend left-icon">
                <SvgIcon iconName="Calendar" />
              </div>
              <select
                value={dropdown.selectedItem}
                className="custom-select"
                onChange={(e) => dropdown.onItemClick(e.target.value)}
              >
                {/* <option value={0}>All status</option> */}
                {dropdown.items?.map((item, i) => {
                  return (
                    <option key={i} value={item.value}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        ))}
        {/* <div className="col-sm-12 col-md-3">
                  <div className="input-group mb-3">
                  <div className="input-group-prepend left-icon">
                      <SvgIcon iconName="Calendar" />
                  </div>
                  <select
                      value={registrationStatus}
                      className="custom-select"
                      onChange={(e) => setRegistrationStatus(e.target.value)}
                  >
                      <option value={9}>All types</option>
                      <option value={1}>Real</option>
                      <option value={2}>Fake</option>
                  </select>
                  </div>
              </div> */}
        {filters?.map((filter, index) => (
          <div className="col-sm-12 col-md-3">
            <div className="input-group mb-3">
              <div className="input-group-prepend left-icon">
                <SvgIcon iconName="Search" />
              </div>
              <input
                type="search"
                className="form-control form-control-sm"
                placeholder={filter.name}
                value={filter.filterText}
                aria-controls="DataTables_Table_0"
                onChange={(e) => {
                  // console.log('e', e?.target?.value)
                  filter.onChange(e);
                }}
              />
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export function TableFooter({ limit, setlimit, pagination, handlePageClick }) {
  return (
    <>
      <div className="row">
        {/* <div className="col-sm-12 col-md-2">
              </div> */}
        <div className="col-sm-6">
          <div className="rows-per-page input-group mb-3">
            <div className="input-group-prepend">Rows per page:</div>
            <select
              value={limit}
              className="custom-select border-0"
              onChange={(e) => setlimit && setlimit(e.target.value)}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div>
        </div>

        <div className="col-sm-6 clearfix">
          <div className="float-sm-right">
            <Pagination
              perPage={pagination?.itemLimit}
              total={pagination?.totalPage}
              handlePageClick={handlePageClick && handlePageClick}
            />
          </div>
        </div>
      </div>
    </>
  );
}
