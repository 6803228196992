import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";

export default function LoginLayout({ className, children }) {
  return (
    <div className={`login-page ${className ? className : ""}`}>
      <section className="main-bg transparent-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 d-flex align-items-center">
              <h1 className="d-none">Login</h1>
              <p className="login-caption d-none d-lg-block">
                Stream favorite nutrition, workouts, and fitness content and
                gain followers
              </p>
            </div>

            <div className="col-lg-5">
              <div className="card login-card">
                <div className="card-body">{children}</div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <ToastContainer autoClose={3000} /> */}
    </div>
  );
}

export const AlreadyHaveAnAccount = ({ title, linkUrl, linkText }) => {
  return (
    <div className="already-have-an-account">
      {title}{" "}
      <Link to={linkUrl} className="btn-login-link ml-2">
        {linkText}
      </Link>
    </div>
  );
};

export const LoginTitle = ({ title, subtitle }) => {
  return (
    <div className="login-title">
      <h2 className="title text-center">{title}</h2>
      {subtitle && <p className="subtitle">{subtitle}</p>}
    </div>
  );
};
