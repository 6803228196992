export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const SORT_ACTION = "SORT_ACTION";

//profile
export const GET_PROFILE = "GET_PROFILE";
export const GET_USERS_FOLLOWERS = "GET_USERS_FOLLOWERS";
export const CREATE_FAKE_PROFILE = "CREATE_FAKE_PROFILE";
export const GET_CURRENT_PROFILE = "GET_CURRENT_PROFILE";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const CLEAR_PROFILE = "CLEAR_PROFILE";
export const PROFILE_ERROR = "PROFILE_ERROR";
export const DELETE_USER = "DELETE_USER";

// Follow
export const FOLLOW_STREAMER = "FOLLOW_STREAMER";
export const UNFOLLOW_STREAMER = "UNFOLLOW_STREAMER";
export const GET_FOLLOWERS = "GET_FOLLOWERS";
export const GET_FOLLOWING = "GET_FOLLOWING";
export const FOLLOW_ERROR = "FOLLOW_STREAMER";

//feedback
export const FEEDBACK_ERROR = "FEEDBACK_ERROR";
export const POST_FEEDACK = "POST_FEEDACK";
//users
export const GET_USERS = "GET_USERS";
export const USERS_ERROR = "USERS_ERROR";
export const UPDATE_USER_STATUS = "UPDATE_USER_STATUS";
export const UPDATE_USER = "UPDATE_USER";
//dashboard
export const DASHBOARD_ERROR = "DASHBOARD_ERROR";
export const GET_DASHBOARD_DATA = "GET_DASHBOARD_DATA";
export const GET_DASHBOARD_GRAPHS = "GET_DASHBOARD_GRAPHS";
//reports
export const GET_REPORTS = "GET_REPORTS";
export const GET_REPORT = "GET_REPORT";
export const DELETE_REPORT = "DELETE_REPORT";
export const REPORT_ERROR = "REPORT_ERROR";

//forgot password
export const SUCCESS_FORGOTPASSWORD = "SUCCESS_FORGOTPASSWORD";
export const SUCCESS_VERIFY_CODE = "SUCCESS_VERIFY_CODE";
export const SUCCESS_RESET_PASSWORD = "SUCCESS_RESET_PASSWORD";
//export const UPDATE_CATEGORY = 'UPDATE_CATEGORY'

//organizations
export const CREATE_CATEGORY = "CREATE_CATEGORY";
export const GET_CATEGORYS = "GET_CATEGORYS";
export const CATEGORY_ERROR = "CATEGORY_ERROR";
export const UPDATE_CATEGORY_STATUS = "UPDATE_CATEGORY_STATUS";
export const GET_CATEGORY_BY_ID = "GET_CATEGORY_BY_ID";
export const UPDATE_CATEGORY = "UPDATE_ORGANIZATION";
export const DELETE_CATEGORY = "DELETE_CATEGORY";

//genre
export const CREATE_GENRE = "CREATE_GENRES";
export const GET_GENRES = "GET_GENRES";
export const GENRE_ERROR = "GENRE_ERROR";
export const GET_GENRE_BY_ID = "GET_GENRES_BY_ID";
export const UPDATE_GENRE = "UPDATE_GENRE";
export const DELETE_GENRE = "DELETE_GENRE";

export const CREATE_PACKAGES = "CREATE_PACKAGES";
export const GET_PACKAGES = "GET_PACKAGES";
export const PACKAGES_ERROR = "PACKAGES_ERROR";
export const GET_PACKAGES_DETAIL_BY_ID = "GET_PACKAGES_DETAIL_BY_ID";

export const PAYMENT_ERROR = "PAYMENT_ERROR";
export const PAYMENT_SUCCESSFUL = "PAYMENT_SUCCESSFUL";
export const GET_MY_SUBSCRIPTION = "GET_MY_SUBSCRIPTION";

export const SUBSCRIBE_ERROR = "SUBSCRIBE_ERROR";
export const CHECK_SUSBRIBED_CHANNEL = "CHECK_SUSBRIBED_CHANNEL";
export const SUBSCRIBE_STREAMER = "SUBSCRIBE_STREAMER";
export const GET_LIVE_STREAMS = "GET_LIVE_STREAMS";
export const STREAM_ERROR = "STREAM_ERROR";
export const SUBMIT_LIVE_INFO = "SUBMIT_LIVE_INFO";
export const GET_STREAM_BY_ID = "GET_STREAM_BY_ID";
export const UPDATE_STREAM = "UPDATE_STREAM";
export const UPDATE_STREAM_NULL = "UPDATE_STREAM_NULL";
//STREAMS
export const GET_ALL_USER_STREAMS = "GET_ALL_USER_STREAMS";

export const DELETE_STREAM = "DELETE_STREAM";

//list
//genre
export const CREATE_LIST = "CREATE_LIST";
export const GET_LISTS = "GET_LISTS";
export const LIST_ERROR = "List_ERROR";
export const GET_LIST_BY_ID = "GET_LIST_BY_ID";
export const UPDATE_LIST = "UPDATE_LIST";
export const DELETE_LIST = "DELETE_LIST";

//items
export const CREATE_ITEM = "CREATE_ITEM";
export const GET_ITEMS = "GET_ITEMS";
export const ITEMS_ERROR = "ITEMS_ERROR";
export const GET_ITEMS_BY_ID = "GET_ITEMS_BY_ID";
export const UPDATE_ITEMS = "UPDATE_ITEMS";
export const DELETE_ITEMS = "DELETE_ITEMS";
