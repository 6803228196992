import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import VideoPlayer2 from "../Stream/VideoPlayer2";
import stream_page from "../users/stream_page.js";
import LiveCam from "../users/live_cam.js";
import ViewLive from "../users/view_live.js";
import ItemsPage from "../users/Items_page.js";
import Followers from "../users/Follower.js";
import AdminVideos from "../users/Admin_Videos.js";
import PastStreams from "../users/past_streams.js";

import ListsItems from "../users/ListItems.js";
import StreammerProfile from "../users/streammer_profile.js";
import Live_channels from "../users/Live_channels.js";
import ViewLoader from "../../Components/ViewLoader";
import all_stream from "../users/all_stream.js";
import WorkoutsPage from "../../Components/WorkoutsPage.jsx";
import WorkoutDetailsPage from "../../Components/charts/WorkoutDetailsPage.jsx";
import blogs from "../blogs/blogs.js";
import Nutrition from "../../Components/Nutition.jsx";
import Exercise from "../../Components/Excersize.jsx";
import BlogDetails from "../blogs/BlogDetails";
import WorkoutCategory from "../../Components/WorkoutCategory.jsx";
import workouts from "../../utils/workoutsData.js";
import NutritionBlogUpload from "../../Components/NutritionBlogUpload.jsx";

const Dashboard = lazy(() => import("../dashboard/Dashboard"));
const Users = lazy(() => import("../users/Users"));
const VideoPlayer = lazy(() => import("../Stream/VideoPlayer"));
const EditUser = lazy(() => import("../users/EditUser"));

const UserVideos = lazy(() => import("../users/UserVideos"));
const EditVideoByUser = lazy(() => import("../Stream/EditVideoByUser"));
const Settings = lazy(() => import("../users/Settings"));
const Genres = lazy(() => import("../users/Genres"));
const Categories = lazy(() => import("../users/Categories"));

const NotFound = lazy(() => import("../../Components/NotFound"));
const PrivateRoute = lazy(() => import("../routing/PrivateRoute"));
const Viewprofile = lazy(() => import("../users/Viewprofile"));
const UserDetails = lazy(() => import("../users/UserDetails"));
const Browse = lazy(() => import("../browse/Browse"));
const SubscriptionLogs = lazy(() => import("../subscriptions/SubscriptionLog"));
const About = lazy(() => import("../about/about"));
const Help = lazy(() => import("../about/help"));
const CommunityGuidelines = lazy(() =>
  import("../about/community_guidelines.js")
);

const Privacy = lazy(() => import("../about/privacy-policy"));
const Terms = lazy(() => import("../about/terms-of-service"));
const Contact = lazy(() => import("../contact/contact"));

const RecentlyWatched = lazy(() =>
  import("../recentlyWatched/RecentlyWatched")
);
const Register = lazy(() => import("../auth/Register"));
const ForgotPassword = lazy(() =>
  import("../../containers/auth/ForgotPassword")
);
const forgotCode = lazy(() => import("../../containers/auth/ForgotCode"));
const ResetPassword = lazy(() => import("../../containers/auth/ResetPassword"));
const Login = lazy(() => import("../../containers/auth/Login"));

const Loading = () => (
  // <div className="row">
  //   <div className="col-12 p-0 text-center">
  //     <img
  //       src={require(`../../menu/loading.gif`)}
  //       className="img-fluid mt-3"
  //       alt="its loading"
  //       style={{width: 50}}
  //     ></img>
  //   </div>
  // </div>
  <ViewLoader />
);

const Routes = (props) => {
  return (
    <section>
      <Suspense fallback={Loading()}>
      <Switch>
          <Route exact path="/forgotpassword" component={ForgotPassword} />
          <Route exact path="/forgotcode" component={forgotCode} />
          <Route exact path="/resetpassword" component={ResetPassword} />
          <Route exact path="/browse" component={Browse} />
          <Route exact path="/" component={Dashboard} />
          <Route exact path="/live-channel" component={Live_channels} />
          <Route exact path="/all-stream" component={all_stream} />
          <Route exact path="/workouts" component={WorkoutsPage} />
          <Route exact path="/uploader" component={NutritionBlogUpload} />
          <Route exact path="/blogs" component={blogs} />
          <Route path="/blog/:id" component={BlogDetails} />
          <Route exact path="/about" component={About} />
          <Route exact path="/help" component={Help} />
          <Route exact path="/community-guidelines" component={CommunityGuidelines} />
          <Route exact path="/privacy-policy" component={Privacy} />
          <Route exact path="/terms-of-service" component={Terms} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/recently-watched" component={RecentlyWatched} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/video/:id" component={VideoPlayer} />
          <Route exact path="/live/:streamId" component={ViewLive} />
          <Route exact path="/lists/:listId" component={ItemsPage} />
          <Route exact path="/nutrition" component={Nutrition} />
          <Route exact path="/exercise" component={Exercise} />
          <Route exact path="/workout/:id" component={WorkoutDetailsPage} />
          <Route path="/workouts/:category" render={(props) => {
            const category = props.match.params.category;
            const categoryWorkouts = workouts[category];
            return <WorkoutCategory category={category} workouts={categoryWorkouts} />;
          }} />
          <Route
            exact
            path="/streammer/:streammerId"
            component={StreammerProfile}
          />

          <PrivateRoute
            exact
            path="/stream/:username"
            component={VideoPlayer}
          />
          <PrivateRoute exact path="/follower" component={Followers} />
          <PrivateRoute
            exact
            path="/streamvideo/:id"
            component={VideoPlayer2}
          />
          <PrivateRoute exact path="/userdetail" component={UserDetails} />
          <PrivateRoute
            exact
            path="/mysubscription"
            component={SubscriptionLogs}
          />
          <PrivateRoute exact path="/profile" component={Viewprofile} />
          <PrivateRoute exact path="/stream" component={stream_page} />
          <PrivateRoute
            exact
            path="/current-live/:streamKey/:streamId"
            component={LiveCam}
          />

          <PrivateRoute exact path="/streams" component={AdminVideos} />
          <PrivateRoute exact path="/mystreams" component={PastStreams} />

          <PrivateRoute exact path="/users" component={Users} />

          <PrivateRoute exact path="/user/videos/" component={UserVideos} />
          <PrivateRoute exact path="/list-items" component={ListsItems} />

          <PrivateRoute
            exact
            path="/user/video/edit/:id"
            component={EditVideoByUser}
          />
          <PrivateRoute exact path="/admin/listUsers/" component={Users} />
          <PrivateRoute
            exact
            path="/admin/listUsers/edit/:id"
            component={EditUser}
          />

          <PrivateRoute
            exact
            path="/admin/settings/genres/"
            component={Genres}
          />
          <PrivateRoute
            exact
            path="/admin/settings/genres/:id"
            component={Genres}
          />
          <PrivateRoute
            exact
            path="/admin/settings/categories/"
            component={Categories}
          />

          <PrivateRoute
            exact
            path="/admin/settings/categories/:id"
            component={Categories}
          />

          <PrivateRoute exact path="/admin/settings/" component={Settings} />

          <Route component={NotFound} />
        </Switch>
      </Suspense>
    </section>
  );
};

export default Routes;
