import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import DOMPurify from 'dompurify';

const NutritionBlog = ({ title, content, image }) => {
  const sanitizedContent = DOMPurify.sanitize(content);

  return (
    <Container maxWidth="md" sx={{ mt: 6, mb: 4 }}>
      {image && (
        <Box sx={{ mb: 4 }}>
          <img
            src={image}
            alt={title}
            style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
          />
        </Box>
      )}
      <Typography variant="h3" component="h1" gutterBottom>
        {title}
      </Typography>
      <Typography variant="body1" component="div" dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
    </Container>
  );
};

export default NutritionBlog;

