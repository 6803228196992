import TablePage from "../../Components/table/TablePage";
import { getSerial } from "../../utils/commonFunctions";
import ViewLoader from "../../Components/ViewLoader";
import { base, baseUrl, liveUrl } from "../../utils/base_url.js";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import Follow from "../../Components/Follow";
import Block from "../../Components/Block.js";
import useFetch from "../../hooks/useFetch.js";
import SvgIcon from "../../Components/SvgIcon.jsx";

const FollowerFOllowingTable = ({
  data,
  loading,
  error,
  handlePageClick,
  setlimit,
  limit,
  keyword,
  setKeyword,
  _key,
}) => {
  const history = useHistory();
  return (
    <TablePage
      paddingTop
      style={{ padding: "0", margin: "0" }}
      limit={limit}
      setlimit={setlimit}
      pagination={data?.pagination}
      handlePageClick={handlePageClick}
      filters={[
        {
          name: "Search Categories",
          filterText: keyword,
          onChange: (e) => {
            // setCurrentPage(0)
            setKeyword(e.target.value);
          },
        },
      ]}
    >
      <thead style={{ padding: "0" }}>
        <tr role="row">
          <th className="text-nowrap">SN</th>
          <th className="text-nowrap">Profile</th>
          <th className="text-nowrap">Name</th>
          <th className="text-nowrap">Email</th>
          <th className="text-nowrap">Member Since</th>
          <th className="text-nowrap">Action</th>
        </tr>
      </thead>
      <tbody>
        {loading ? (
          <ViewLoader />
        ) : data && data?.data?.length > 0 ? (
          data?.data.map((item, index) => (
            <tr role="row" className="odd" key={index}>
              {/* <Link style={{ display: "inline-block" }} to={`/streammer/${item._id}`}> */}
              <td className="text-nowrap">
                {getSerial(data?.pagination, index)}
              </td>
              <td
                onClick={(e) => {
                  e.preventDefault();
                  history.push(`/streammer/${item._id}`);
                }}
                className="text-nowrap"
              >
                {item?.image !== "" ? (
                  <img
                    src={
                      item?.image.toLowerCase().includes("no-image")
                        ? "/images/profile_placeholder.webp"
                        : `${base}/${item?.image}`
                    }
                    style={{ maxHeight: "30px", minWidth: "30px" }}
                    alt="Images"
                  />
                ) : (
                  ""
                )}
              </td>
              <td
                onClick={(e) => {
                  e.preventDefault();
                  history.push(`/streammer/${item._id}`);
                }}
                className="text-nowrap customLink"
              >
                <span style={{ float: "left", marginRight: "5px" }}>
                  {item?.firstname} {item?.lastname}
                  <BlockedText streamerId={item._id} />
                </span>
                {item?.streamerVerifiedStatus === "Verified" ? (
                  <SvgIcon iconName="Verified" />
                ) : (
                  ""
                )}
              </td>
              <td
                onClick={(e) => {
                  e.preventDefault();
                  history.push(`/streammer/${item._id}`);
                }}
                className="text-nowrap customLink"
              >
                {item?.email}
              </td>
              <td className="text-nowrap">
                {moment(item?.createdAt).format("DD/MM/YYYY")}
              </td>
              <td className="text-nowrap">
                <Follow isLarge={false} streamerId={item?._id} />
                <div style={{ width: "10px", display: "inline-block" }}></div>
                {item.role !== "ADMIN" ? (
                  <Block isLarge={false} streamerId={item?._id} />
                ) : undefined}
              </td>
              {/* </Link> */}
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="8">No {_key} found.</td>
          </tr>
        )}
      </tbody>
    </TablePage>
  );
};

export default FollowerFOllowingTable;

const BlockedText = ({ streamerId }) => {
  const {
    data: item,
    loading,
    error,
  } = useFetch(`/streams/${streamerId}/checkBlocked`);

  return item?.data ? <p>(Blocked )</p> : <p></p>;
};
