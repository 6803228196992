import useFetch from "../../hooks/useFetch.js";
import { Scrollbar } from "smooth-scrollbar-react";
import dayjs from "dayjs";
import { Link, useParams } from "react-router-dom";
import { ReactFlvPlayer } from "react-flv-player";
import { useEffect, useState, useRef } from "react";
import { commentStreaming } from "../../actions/streamsActions.js";
import io from "socket.io-client";
import socketString from "../../utils/socketString";
import { baseUrl, liveUrl } from "../../utils/base_url.js";
import { connect } from "react-redux";
import api from "../../utils/api.js";
import { toast } from "react-toastify";
import Follow from "../../Components/Follow.jsx";
import CheckAndLogin from "../auth/login_model.js";
import SvgIcon from "../../Components/SvgIcon.jsx";
import ReactPlayer from "react-player/lazy";
import { base } from "../../utils/base_url.js";
import Block from "../../Components/Block.js";
// import { isMobile } from 'react-device-detect';


const socketOptions = {
  secure: true,
  // secure: false,
  reconnection: true,
  reconnectionDelay: 1000,
  timeout: 15000,
  pingTimeout: 15000,
  pingInterval: 45000,
};

function ViewLive({ auth, commentStreaming }) {
  const { streamId } = useParams();

  const { data: stream, loading, error } = useFetch(`/streams/${streamId}`);
  //TODO: use this to displya comments
  const [messages, setMessages] = useState([]);
  const [refreshChat, setRefreshChat] = useState(null);
  // const videoUrl = 'https://localhost:4001';
  // const videoUrl = 'http://stream.bahstream.com';  // old
  const videoUrl = 'https://media.bahstream.com';


  const socketRef = useRef();

  const streamerId = stream?.data?.streamer?._id;
  const {
    data: streamComments,
    loading: streamCommmentLoading,
    error: streamError,
  } = useFetch(`/streams/${streamId}/comments?limit=30&sort=createdAt`, [
    refreshChat,
  ]);

  const {
    data: item,
    _,
    __,
  } = useFetch(`/streams/${streamerId}/checkIamBlocked`);

  useEffect(() => {
    if (streamComments) {
      setMessages(streamComments?.data);
    }
  }, [streamComments]);

  const [message, setMessage] = useState("");

  useEffect(() => {
    if (!stream) return;
    console.log(stream);
    socketRef.current = io.connect(socketString, socketOptions);
    socketRef.current.emit("joinRoom", stream.data.stream_key);

    socketRef.current.on("chat", (payload) => {
      console.log("messages", messages);
      console.log("payload", payload);

      setMessages([...messages, payload.chat]);
      setRefreshChat(payload.chat._id);
    });

    return () => socketRef.current.disconnect();
  }, [stream]);

  const sendMessage = async (e) => {
    e.preventDefault();
    try {
      const res = await api.post(`/streams/${streamId}/comments`, {
        commentText: message,
      });
      console.log(res.data.data);
      socketRef.current.emit("chat", {
        stream_key: stream.data.stream_key,
        chat: res.data.data,
      });
      setMessages([...messages, res.data.data]);

      setMessage(""); // clear input
    } catch (e) {
      toast.error(e.response.data.error);
    }
  };

  const isMobileDevice = () => {
    return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
};

  if (loading) return <div>Loading...</div>;

  return (
    <div style={{ paddingTop: 70, overflowX: "hidden", background: "#18181B" }}>
      {stream && (
        <div className="row">
          <div className="col-md-8">
            <div className="mb-4 viewplayer-frame">
              {stream.data.published ? (
                <video
                  onContextMenu={(e) => e.preventDefault()}
                  id="video-preview"
                  src={`${baseUrl}/videos/${stream.data?.stream_key}`}
                  style={{ width: "100%", height: "100%" }}
                  playsInline
                  controls
                  controlsList="nodownload"
                  autoPlay
                ></video>
              ) : (
                // <ReactFlvPlayer
                //   isLive={true}
                //   style={{ width: "100%", height: "100%" }}
                //   // url={`${videoUrl}/live/${stream.data?.stream_key}.mp4`}
                //   url={`${videoUrl}/live/${stream.data?.stream_key}.flv`}
                // />
                // <ReactPlayer
                //   url={`${videoUrl}/live/${stream.data?.stream_key}/index.m3u8`}
                //   controls={true}
                //   playing={true}
                //   width="100%"
                //   height="100%"
                // />
                <ReactPlayer
                url={isMobileDevice() ? `${videoUrl}/live/${stream.data?.stream_key}/index.m3u8` : `${videoUrl}/live/${stream.data?.stream_key}.flv`}
                controls={true}
                playing={true}
                width="100%"
                height="100%"
            />
              )}
            </div>

            <div className="px-5 pb-5">
              <div
                className="clearfix position-relative"
                style={{ paddingRight: 100 }}
              >
                <div className="title mb-3 float-left">
                  <div className="mb-2">
                    <span className="text-muted mr-4">
                      {stream?.data.categoryId?.name}
                    </span>
                    <span className="text-muted mr-4">
                      {stream?.data.genreId?.name}
                    </span>
                  </div>

                  <h3>{stream?.data.title}</h3>
                </div>
                <div className="position-absolute" style={{ right: 0 }}>
                  <CheckAndLogin
                    noAuth={<button className="btn btn-primary">Follow</button>}
                  >
                    <Follow streamerId={streamerId} />
                  </CheckAndLogin>
                </div>
              </div>

              <div>
                <div className="streamer-bio">
                  <Link to={`/streammer/${streamerId}`}>
                    <img
                      src={
                        stream?.data?.streamer?.image
                          .toLowerCase()
                          .includes("no-image") ||
                        stream?.data?.streamer?.image
                          .toLowerCase()
                          .includes("no-photo")
                          ? "/images/profile_placeholder.webp"
                          : `${base}/${stream?.data?.streamer?.image}`
                      }
                      alt=""
                    />
                    <span style={{ fontSize: "22px" }}>
                      {stream?.data?.streamer?.firstname +
                        " " +
                        stream?.data?.streamer?.lastname}
                    </span>

                    {stream?.data?.streamer?.streamerVerifiedStatus ===
                    "Verified" ? (
                      <SvgIcon iconName="Verified" />
                    ) : (
                      ""
                    )}
                  </Link>
                </div>

                <div className="text-muted">
                  <p>{stream?.data.description}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <CheckAndLogin
              noAuth={
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <h3 style={{ marginTop: "60px", textAlign: "center" }}>
                    You haven't login yet please login
                  </h3>
                  <button
                    style={{ marginTop: "30px" }}
                    className="btn btn-primary"
                  >
                    Login First
                  </button>
                </div>
              }
            >
              <div className="stream-chat" style={{ height: 660 }}>
                <div className="title">STREAM CHAT</div>
                {/* <Scrollbar damping={0.1}> */}
                <div style={{ height: 560, overflowY: "scroll" }}>
                  {messages &&
                    messages.map((e) => (
                      <div
                        className={`message-box ${
                          e.userId._id === auth?.user?.data?._id
                            ? "current-user"
                            : ""
                        }`}
                      >
                        <img
                          src={
                            e.userId.image.toLowerCase().includes("no-image") ||
                            e.userId.image.toLowerCase().includes("no-photo")
                              ? "/images/profile_placeholder.webp"
                              : `${base}/${e.userId?.image}`
                          }
                          alt=""
                        />
                        <div className="message-desc">
                          <div className="message-user">
                            {e.userId.firstname} {e.userId?.lastname}
                            <span className="created-at">
                              {dayjs(e.createdAt).format("ddd h:mm A")}
                            </span>
                          </div>
                          <div>{e.commentText}</div>
                        </div>
                      </div>
                    ))}
                </div>
                {/* </Scrollbar> */}

                <div className="stream-input">
                  <form
                    onSubmit={(e) => {
                      sendMessage(e);
                    }}
                  >
                    <input
                      type="text"
                      disabled={item?.data ? true : false}
                      onChange={(e) => setMessage(e.target.value)}
                      value={message}
                      placeholder="Send a message"
                    />
                    {item?.data ? (
                      <p style={{ marginTop: "10px" }}>
                        You cannot comment on this video because you are blocked
                        by the streamer.
                      </p>
                    ) : undefined}
                    {/* <button
                    className="btn btn-primary"
                    onClick={(e) => sendMessage(e)}
                  >
                    Send
                  </button> */}
                  </form>
                </div>
              </div>
            </CheckAndLogin>
          </div>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  auth: state.auth,
});

export default connect(mapStateToProps, { commentStreaming })(ViewLive);

// export default ViewLive;
