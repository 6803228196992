import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import SvgIcon from "../../Components/SvgIcon.jsx";

import { connect } from "react-redux";
import api from "../../utils/api.js";

import { Link, useParams, useHistory } from "react-router-dom";
import moment from "moment";
import { getSerial } from "../../utils/commonFunctions";
import {
  submitLiveInfo,
  getAllVideosOfThisUser,
  setStreamNull,
  deleteVideo,
} from "../../actions/streamsActions.js";

import { getCategories } from "../../actions/categoryAction.js";
import "react-toastify/dist/ReactToastify.css";
import ViewLoader from "../../Components/ViewLoader";
import TablePage from "../../Components/table/TablePage";
import WithSidebar from "../layout/WithSidebar";
import { ModalRight } from "../../Components/modal/ModalComponents";
import AsyncSelect from "react-select/async";
import { toast } from "react-toastify";
import { baseUrl } from "../../utils/base_url.js";

const StreamPage = ({
  submitLiveInfo,
  getAllVideosOfThisUser,
  deleteVideo,
  setStreamNull,
  cateogry,
  stream,
}) => {
  const [categoryInputValue, setCategoryInputValue] = useState("");
  const [genreInputValue, setGenreInputValue] = useState("");
  const [streamTitle, setStreamTitle] = useState("");
  const [streamDescription, setStreamDescription] = useState("");
  const [streamKey, setStreamKey] = useState(null);

  const [categorySelectedValue, setCategorySelectedValue] = useState(null);
  const [genreSelectedValue, setGenreSelectedValue] = useState(null);

  const handleCategoryInputChange = (value) => {
    setCategoryInputValue(value);
  };

  const handelGenreInputChange = (value) => {
    setGenreInputValue(value);
  };
  const handleCategoryChange = (value) => {
    setCategorySelectedValue(value);
  };

  const handleGenreChange = (value) => {
    setGenreSelectedValue(value);
  };

  const history = useHistory();

  const baseUrl = "https://api.bahstream.com/api";
  // const baseUrl = "https://localhost:5000/api";

  const loadCategoryOption = async (inputValue) => {
    const response = await fetch(
      `${baseUrl}/categories?name[regex]=${inputValue}&name[options]=i&limit=10&status=true`
    );
    const data = await response.json();
    return data.data;
  };

  const loadGenreOption = async (inputValue) => {
    const response = await fetch(
      `${baseUrl}/genres?name[regex]=${inputValue}&name[options]=i&limit=10&status=true`
    );
    const data = await response.json();
    return data.data;
  };

  useEffect(() => {
    console.log(stream);
    if (stream !== null) {
      setStreamKey(stream.stream_key);
    }
  }, [stream]);

  const startLiveView = async (e) => {
    e.preventDefault();
    if (
      streamTitle &&
      streamDescription &&
      genreSelectedValue?._id &&
      categorySelectedValue?._id
    ) {
      let data = {
        title: streamTitle,
        genreId: genreSelectedValue?._id,
        categoryId: categorySelectedValue?._id,
        description: streamDescription,
      };

      const res = await api.post(`/streams`, data);
      console.log(res.data);
      if (res.status === 200 && res.data.data && res.data.data.stream_key) {
        toast.success(` ${res.data.message}`);
        const streamKey = res.data.data.stream_key;
        const streamId = res.data.data._id;

        history.push(`/current-live/${streamKey}/${streamId}`);
      }
    } else {
      toast.error("Please Fill the information");
    }
  };

  const onGenerateStreamKey = (e) => {
    e.preventDefault();
    if (
      streamTitle &&
      streamDescription &&
      genreSelectedValue?._id &&
      categorySelectedValue?._id
    ) {
      return submitLiveInfo(
        streamTitle,
        genreSelectedValue?._id,
        categorySelectedValue?._id,
        streamDescription
      );
    } else {
      toast.error("Please Fill the information");
    }
  };

  return (
    <WithSidebar>
      <div className="">
        <div className="">
          <div className="card rounded">
            <div className="card-header">
              <div className="row">
                <div className="col-6">
                  <h5 className="m-0">Stream Key</h5>
                </div>
              </div>
            </div>
            {/* Form */}
            {true && (
              <div className="row">
                <div className="col-md-12">
                  <div className="card  mb-3">
                    <div className="card-body">
                      <form onSubmit={(e) => {}}>
                        <div
                          // style={{ maxWidth: "400px" }}
                          className="form-row align-items-center"
                        >
                          <div className="col-lg-4">
                            <label
                              className="sr-only"
                              htmlFor="inlineFormInput"
                            >
                              Title
                            </label>
                            <input
                              type="text"
                              onChange={(e) => setStreamTitle(e.target.value)}
                              className="custom-select  mb-2"
                              id="liveVideoTitle"
                              style={{ background: "none" }}
                              placeholder="Video title"
                              required
                            />
                          </div>
                          <div className="col-lg-4">
                            <AsyncSelect
                              cacheOptions
                              defaultOptions
                              placeholder="Select Category"
                              className="custom-select mb-2 pt-0 pl-0"
                              value={categorySelectedValue}
                              getOptionLabel={(e) => e.name}
                              getOptionValue={(e) => e._id}
                              loadOptions={loadCategoryOption}
                              onInputChange={handleCategoryInputChange}
                              onChange={handleCategoryChange}
                              required
                            />
                          </div>
                          <div className="col-lg-4">
                            <AsyncSelect
                              cacheOptions
                              defaultOptions
                              className="custom-select mb-2 pt-0 pl-0"
                              value={genreSelectedValue}
                              getOptionLabel={(e) => e.name}
                              getOptionValue={(e) => e._id}
                              loadOptions={loadGenreOption}
                              placeholder="Select Genre"
                              onInputChange={handelGenreInputChange}
                              onChange={handleGenreChange}
                              required
                            />
                          </div>
                        </div>

                        <div className="form-row">
                          <div className="col-md-8">
                            <label
                              className="sr-only"
                              htmlFor="inlineFormInput"
                            >
                              Description
                            </label>
                            {/* <input
                              type="message"
                            /> */}
                            <textarea
                              onChange={(e) =>
                                setStreamDescription(e.target.value)
                              }
                              className="custom-select no-icon mb-2"
                              id="videoDescription"
                              style={{ background: "none" }}
                              placeholder="Video Description"
                              required
                            ></textarea>
                          </div>
                        </div>

                        <div className="form-row form align-items-center">
                          <div className="col-auto">
                            <button
                              onClick={onGenerateStreamKey}
                              type="submit"
                              className="btn btn-secondary mb-2"
                              style={{
                                height: "40px",
                                marginTop: "30px",
                                marginBottom: "50px",
                              }}
                            >
                              Generate a stream key
                            </button>
                          </div>

                          <button
                            className="btn btn-secondary mb-2"
                            style={{
                              height: "40px",
                              marginTop: "30px",
                              marginBottom: "50px",
                              backgroundColor: "#EB5757",
                            }}
                            onClick={startLiveView}
                          >
                            Start Live Video
                          </button>
                          {/* {streamKey && (
                            <div className="col-auto">
                              <Link
                                to={`/current-live/${streamKey}/${stream._id}`}
                              >
                              </Link>
                            </div>
                          )} */}
                          {/* {true && (
                            <div
                              className="col-auto"
                              style={{ marginTop: "30px" }}
                            >
                                <button className="live-button">
                                  <SvgIcon iconName="Live" />
                                  <b style={{ marginRight: "10px" }}></b>
                                  Start Live Video
                                </button>
                            </div>
                          )} */}
                        </div>
                      </form>

                      <div style={{ height: 90 }}></div>

                      <h3>How to strem</h3>
                      <p>{streamKey}</p>
                      <p>
                        You can use OBS orXSplit to Live stream. If you're using
                        OBS, go to Settings Stream and select Custom from
                        service dropdown. Enter rtmp://139.144.188.229:1935/live
                        in server input field. Also, add your stream key. Click
                        apply to save.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </WithSidebar>
  );
};

const mapStateToProps = (state) => ({
  stream: state.streams.Stream,
  category: state.category,
});
export default connect(mapStateToProps, {
  submitLiveInfo,
  getAllVideosOfThisUser,
  deleteVideo,
  setStreamNull,
})(StreamPage);
