import api from "../utils/api";
import { toast } from "react-toastify";
import {
  GET_FOLLOWERS,
  GET_FOLLOWING,
  FOLLOW_ERROR,
  FOLLOW_STREAMER,
  UNFOLLOW_STREAMER,
} from "./types";

export const followStreammer = (id) => async (dispatch) => {
  const res = await api.post(`/${id}/follow`);
  if (res.status === 200) {
    dispatch({
      type: FOLLOW_STREAMER,
      payload: res.data,
    });
  }
  try {
  } catch (err) {
    const message = err.response.data.error;
    toast.error(`${message}`);
  }
};

export const unfollowStreammer = (id) => async (dispatch) => {
  const res = await api.post(`/${id}/follow`);
  if (res.status === 200) {
    dispatch({
      type: UNFOLLOW_STREAMER,
      payload: res.data,
    });
  }
  try {
  } catch (err) {
    const message = err.response.data.error;
    toast.error(`${message}`);
  }
};

// Get all
export const getFollowers = (page, limit, keyword) => async (dispatch) => {
  try {
    const res = await api.get(`/getFollower?page=${page}&limit=${limit}`);
    if (res.status === 200) {
      dispatch({
        type: GET_FOLLOWERS,
        payload: res.data,
      });
    }
  } catch (err) {
    const message = err.response.data.error;
    toast.error(`${message}`);
  }
};

// Get all
export const getFollowing = (page, limit, keyword) => async (dispatch) => {
  try {
    const res = await api.get(`/getFollowing?page=${page}&limit=${limit}`);
    if (res.status === 200) {
      dispatch({
        type: GET_FOLLOWING,
        payload: res.data,
      });
    }
  } catch (err) {
    const message = err.response.data.error;
    toast.error(`${message}`);
  }
};

// Get all
export const checkFollow = (id) => async (dispatch) => {
  try {
    const res = await api.post(`/${id}/check`);
  } catch (err) {}
};
