import React from 'react'

export default function CategoryListing({children}) {
  return (
    <div className="category-listing">
        {children}
    </div>
  )
}

export function CategoryBox({image, name}) {
    return (
        <div className="category-box">
            <div className="category-box-wrap">
                <img src={image} alt="" />
                <p>{name}</p>
            </div>
        </div>
    )
}