import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import rootReducer from '../reducers';
import setAuthToken from '../utils/setAuthToken';

const initialState = {};

const middleware = [thunk];
const store = createStore(
    rootReducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
);

// set up a store subscription listener
// to store the users token in localStorage

// initialize current state from redux store for subscription comparison
// preventing undefined error
let currentState = store.getState();

if(localStorage.getItem('token')){
    setAuthToken(localStorage.getItem('token'));
}

store.subscribe(() => {
    // keep track of the previous and current state to compare changes
    let previousState = currentState;
    currentState = store.getState();
    // if the token changes set the value in localStorage and axios headers

    if (previousState.auth.token !== currentState.auth.token) {
        const token = currentState.auth.token;
        setAuthToken(token);
    }
    // setAuthToken("eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MzE1NmRiNTQwMzU0NzAwMjBmZTVhY2YiLCJyb2xlIjoiQURNSU4iLCJpYXQiOjE2NjI0NTU4MzcsImV4cCI6MTY2MjU0MjIzN30.4hXp2xXhZHHBd9euyClMV73_aBt8Gg40GQYsoJKhpMs")
});

export default store;
