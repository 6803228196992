import React from "react";
import { Link } from "react-router-dom";

export default function RecordNotFound(props) {
  const title = props.title ? props.title : "No current Live Channels";
  const showTakeMeHome = !(props.isHomePage ?? false);
  const description = props.description
    ? props.description
    : "There are currently no live videos. Please check again later. In the meantime check out our home page for more fitness content!";
  return (
    <div className="record-not-found py-5">
      <div className="title">{title}</div>
      <p>{description}</p>
      {showTakeMeHome && (
        <Link to={"/"} className="btn btn-primary mt-4">
          Take me home
        </Link>
      )}
    </div>
  );
}
