import WithSidebar from "../layout/WithSidebar";
import useFetch from "../../hooks/useFetch.js";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ContentSlider from "../../Components/ContentSlider";
import RecordNotFound from "../../Components/RecordNotFound";
// import { streamerTopLive } from "../../data/streamerTopLive";
import CategoryListing, { CategoryBox } from "../../Components/CategoryListing";
import { streamerPlayedGames } from "../../data/streamerPlayedGames";
import Follow from "../../Components/Follow";
import CheckAndLogin from "../auth/login_model.js";
import Block from "../../Components/Block.js";
import SvgIcon from "../../Components/SvgIcon.jsx";

const StreammerProfile = ({ auth }) => {
  const { streammerId } = useParams();

  const {
    data: streamerProfile,
    loading: streamerLoading,
    error: streammerError,
  } = useFetch(`/users/streammers/${streammerId}`);

  const {
    data: streammerVideos,
    loading: streammerLoading,
    error: streammerVideoError,
  } = useFetch(`/streams?streamer=${streammerId}&published=true`);

  const cover = "/images/streamer/streamer-cover.jpg";
  const profile = "/images/profile_placeholder.webp";

  const popularStreamLoading = false;
  const popularStream = streammerVideos; // dummy data
  return (
    <WithSidebar>
      <div className="mb-5 negative-margin-top">
        <div
          className="streamer-cover"
          style={{ backgroundImage: `url(${cover})` }}
        >
          {/* <img src={cover} alt="" /> */}
        </div>

        <div className="streamer-profile-wrap clearfix">
          <div className="streamer-profile">
            <img
              src={
                streamerProfile?.data?.image.toLowerCase().includes("no-image")
                  ? profile
                  : streamerProfile?.data?.image
              }
              alt=""
            />
            <div className="streamer-name">
              {streamerProfile?.data?.firstname}{" "}
              {streamerProfile?.data?.lastname}
              {streamerProfile?.data?.streamerVerifiedStatus === "Verified" ? (
                <SvgIcon iconName="Verified" />
              ) : (
                ""
              )}
            </div>
            <div className="streamer-followers">
              {streamerProfile?.data?.followersCount ?? 0}{" "}
              {streamerProfile?.data?.followersCount > 1
                ? "Followers"
                : "Follower"}
            </div>
          </div>

          <div className="streamer-btns">
            <CheckAndLogin
              noAuth={<button className="btn btn-primary">Follow</button>}
            >
              <Follow streamerId={streammerId} />
              <div style={{ width: "10px", display: "inline-block" }}></div>
              {streamerProfile?.data?.role !== "ADMIN" && (
                <Block streamerId={streammerId} />
              )}
            </CheckAndLogin>
          </div>
        </div>
      </div>

      <div className="pb-4">
        <h4 style={{ marginBottom: "20px" }}>Videos</h4>
        {!popularStreamLoading && popularStream?.data?.length > 0 ? (
          <ContentSlider
            items={popularStream.data.map((e) => {
              return {
                image: e.thumbnail,
                viewers: e.views,
                id: e._id,
                streamer: `${e.streamer?.firstname} ${e.streamer?.lastname}`,
                title: e.title,
                category: "Data 2",
              };
            })}
          />
        ) : (
          <RecordNotFound />
        )}
      </div>

      {/* <div className="pb-4">
        <h4 style={{ marginBottom: "20px" }}>Played Games</h4>

        <CategoryListing>
          {streamerPlayedGames?.data?.map((e) => (
            <CategoryBox image={e.image} name={e.name} />
          ))}
        </CategoryListing>
      </div> */}
    </WithSidebar>
  );
};

StreammerProfile.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(StreammerProfile);
