import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { blockStreamer, unBlockStreammer } from "../actions/streamsActions.js";
import useFetch from "../hooks/useFetch";
import {
  ModalBtnSubmit,
  ModalRight,
} from "../Components/modal/ModalComponents";
import { ModalHeader, ModalBody, Button } from "reactstrap";

function Block({
  blockStreamer,
  isLarge = true,
  unBlockStreammer,
  streamerId,
}) {
  const [block, setBlock] = useState(false);
  const [modalConformationBlock, setModalConformationBlock] = useState(false);
  const [modalConformationUnblock, setModalConformationUnblock] =
    useState(false);

  const toggleModelConformationBlock = () => {
    setModalConformationBlock(!modalConformationBlock);
  };

  const toggleModelConformationUnblock = () => {
    setModalConformationUnblock(!modalConformationUnblock);
  };

  const {
    data: item,
    loading,
    error,
  } = useFetch(`/streams/${streamerId}/checkBlocked`);

  console.log("item", item);

  useEffect(() => {
    setBlock(item?.data);
  }, [item]);

  const blockAction = (streamerId) => {
    blockStreamer(streamerId).then((e) => {
      console.log(e);
      // setNewCategoryAdded(true);
      // setShowAddCategoryForm(false);
      setBlock(!block);
    });
  };

  const unblockAction = (streamerId) => {
    unBlockStreammer(streamerId).then((e) => {
      console.log(e);
      // setNewCategoryAdded(true);
      // setShowAddCategoryForm(false);
      setBlock(!block);
    });
  };

  return (
    <>
      <ModalRight
        isOpen={modalConformationBlock}
        toggle={toggleModelConformationBlock}
        // className="modal-two-column"
      >
        <ModalHeader toggle={toggleModelConformationBlock}>
          Heads Up!
        </ModalHeader>

        <ModalBody>
          <p>Are you sure wanna block this streammer?</p>

          <button
            onClick={() => {
              blockAction(streamerId);
              toggleModelConformationBlock();
            }}
            data-dismiss="modal"
            type="button"
            class="btn btn-danger"
          >
            Block
          </button>
        </ModalBody>
      </ModalRight>
      <ModalRight
        isOpen={modalConformationUnblock}
        toggle={toggleModelConformationUnblock}
        // className="modal-two-column"
      >
        <ModalHeader toggle={toggleModelConformationUnblock}>
          Heads Up!
        </ModalHeader>

        <ModalBody>
          <p>Are you sure wanna unblock this streammer?</p>

          <button
            onClick={() => {
              unblockAction(streamerId);
              toggleModelConformationUnblock();
            }}
            data-dismiss="modal"
            type="button"
            class="btn btn-danger"
          >
            Unblock
          </button>
        </ModalBody>
      </ModalRight>
      {block ? (
        <button
          className={`btn ${
            isLarge ? "btn-lg" : "btn-sm"
          } btn-outline-secondary`}
          onClick={() => {
            toggleModelConformationUnblock();
          }}
        >
          Unblock
        </button>
      ) : (
        <button
          className={`btn ${
            isLarge ? "btn-lg" : "btn-sm"
          } btn-outline-secondary`}
          onClick={() => {
            toggleModelConformationBlock();
          }}
        >
          Block
        </button>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  // category: state.category,
});

export default connect(mapStateToProps, {
  blockStreamer,
  unBlockStreammer,
})(Block);
