import { combineReducers } from "redux";
import auth from "./authReducer";
import profile from "./profileReducer";
import dashboard from "./dashboardReducer";
import feedback from "./feedbackReducer";
import category from "./categoryReducer";
import genre from "./genreReducers";
import loader from "./loaderReducer";
import subscribe from "./subscribeReducer";
import streams from "./streamReducer";
import lists from "./lists_Reducers.js";
import items from "./item_reducers.js";
import follow from "./followReducer.js";

export default combineReducers({
  auth,
  profile,
  dashboard,
  feedback,
  category,
  genre,
  loader,
  subscribe,
  streams,
  lists,
  items,
  follow,
});
