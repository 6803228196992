import api from "../utils/api";
import { toast } from "react-toastify";
import {
  ITEMS_ERROR,
  CREATE_ITEM,
  GET_ITEMS,
  GET_ITEMS_BY_ID,
  UPDATE_ITEMS,
  DELETE_ITEMS,
} from "./types";

// Create new category
export const createItem = (id, data) => async (dispatch) => {
  console.log(data);
  try {
    let name = data.name;
    let file = data.file;
    var body = new FormData();
    body.append("name", name);
    body.append("file", file);

    const res = await api.post(`/lists/${id}/items`, body);
    console.log(res.data.data);
    if (res.status === 200) {
      dispatch({
        type: CREATE_ITEM,
        payload: res.data.data,
      });
      toast.success(` ${res.data.message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  } catch (err) {
    toast.error(` ${err.response.data.error}`);
    console.log(err);
  }
};

// Get genre by ID
export const deleteItem = (id) => async (dispatch) => {
  try {
    const res = await api.delete(`/items/${id}`);
    if (res.status === 200) {
      dispatch({
        type: DELETE_ITEMS,
        payload: { id },
      });
      toast.success(`Item Deleted Successfully`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  } catch (err) {
    toast.error(` ${err.response.data.error}`);

    console.log(err);
  }
};

// Get all
export const getItems = (id, page, limit, keyword) => async (dispatch) => {
  try {
    const res = await api.get(
      `/lists/${id}/items?page=${page}&name[regex]=${
        keyword ?? ""
      }&name[options]=i&limit=${limit}`
    );
    dispatch({
      type: GET_ITEMS,
      payload: res.data,
    });
  } catch (err) {
    toast.error(` ${err.response.data.error}`);

    dispatch({
      type: ITEMS_ERROR,
      payload: err,
    });
  }
};

// Get   by ID
export const getItem = (id) => async (dispatch) => {
  try {
    const res = await api.get(`/items/${id}`);
    console.log(res.data);
    dispatch({
      type: GET_ITEMS_BY_ID,
      payload: res.data,
    });
  } catch (err) {
    toast.error(` ${err.response.data.error}`);

    dispatch({
      type: ITEMS_ERROR,
    });
  }
};

//update category
export const updateItem = (id, formData) => async (dispatch) => {
  let name = formData.name;
  let file = formData.file;

  var body = new FormData();
  body.append("name", name);
  body.append("file", file);

  try {
    const res = await api.put(`/items/${id}`, body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    dispatch({
      type: UPDATE_ITEMS,
      payload: res.data,
    });
    toast.success(`${res.data.message}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  } catch (err) {
    toast.error(` ${err.response.data.error}`);

    const message = err.response.data.error;
    toast.error(`${message}`);
  }
};
