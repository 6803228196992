// workout6.js

const workout6Data = {
    description: `The below is just one example of what a body weight workout could look like. The 6-day split may be ideal for someone that does not have a gym membership or does not have any weights or equipment to train. It uses the person’s own body weight to do complete workouts. It can be done any day of week as many times as needed. Live stream your body weight workouts at https;//bahstream.com. Please feel free to adjust the below as needed, such as incorporating stretches or cardio into your workouts. Always remember to contact your health care provider before starting any workouts or exercises.  `,
  
    day1: {
        title: "Full Body Workout - No Equipment",
        rows: 9,
        columns: 4,
        data: [
          ["Exercise", "Sets", "Reps", "Muscle"],
          ["Pushups", 4, "8-10", "Chest"],
          ["Superhero Iso Hold", 4, "8-10", "Back"],
          ["Wrist Pushups", 4, "8-10", "Biceps"],
          ["Body Weight Triceps Dips", 4, "8-10", "Triceps"],
          ["Cherry Pickers", 4, "8-10", "Shoulders"],
          ["Body Squats", 4, "8-10", "Legs"],
          ["Lunges", 4, "8-10", "Legs"],
          ["Planks", 3, "To Failure", "Abs"],
        ],
    },
    
    day2: {
        title: "Core and Cardio Bodyweight Circuit",
        rows: 9,
        columns: 4,
        data: [
          ["Exercise", "Sets", "Reps", "Muscle"],
          ["Mountain Climbers", 4, "20 seconds", "Core"],
          ["Burpees", 4, "10 reps", "Cardio"],
          ["Russian Twists", 4, "10 reps (each side)", "Core"],
          ["High Knees", 4, "20 seconds", "Cardio"],
          ["Plank Jacks", 4, "20 seconds", "Cardio"],
          ["Jumping Lunges", 4, "12 reps (each leg)", "Legs"],
          ["Bicycle Crunches", 4, "15 reps (each side)", "Core"],
          ["Jump Squats", 4, "15 reps", "Legs"],
        ],
    },
    
    day3: {
        title: "Upper Body Bodyweight Workout",
        rows: 8,
        columns: 4,
        data: [
          ["Exercise", "Sets", "Reps", "Muscle"],
          ["Diamond Pushups", 4, "8-10", "Chest"],
          ["Wide Grip Pushups", 4, "8-10", "Chest"],
          ["Pike Pushups", 4, "8-10", "Shoulders"],
          ["Triceps Pushups", 4, "8-10", "Triceps"],
          ["Close Grip Pushups", 4, "8-10", "Triceps"],
          ["Chair Dips", 4, "8-10", "Triceps"],
          ["Planks", 4, "To Failure", "Abs"],
          ["Superman Hold", 3, "To Failure", "Back"],
        ],
    },
    
    day4: {
        title: "Lower Body Bodyweight Workout",
        rows: 8,
        columns: 4,
        data: [
          ["Exercise", "Sets", "Reps", "Muscle"],
          ["Jump Squats", 4, "12-15", "Legs"],
          ["Lunges", 4, "10 reps (each leg)", "Legs"],
          ["Single-Leg Calf Raises", 4, "10 reps (each leg)", "Legs"],
          ["Glute Bridges", 4, "12-15", "Legs"],
          ["Wall Sits", 4, "30 seconds", "Legs"],
          ["Pistol Squats", 4, "8-10", "Legs"],
          ["Planks", 4, "To Failure", "Abs"],
          ["Leg Raises", 3, "To Failure", "Abs"],
        ],
    },
    
};
  
  export default workout6Data;
  