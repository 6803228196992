import api from "../utils/api";
import { toast } from "react-toastify";
import { Redirect } from "react-router-dom";

import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  SUCCESS_VERIFY_CODE,
  SUCCESS_FORGOTPASSWORD,
  LOGOUT,
  CHANGE_PASSWORD,
} from "./types";

// Load User
export const loadUser = () => async (dispatch) => {
  try {
    const res = await api.get("/auth/current");
    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// Register User
export const register = (formData, history) => async (dispatch) => {
  try {
    const res = await api.post("/users/register", formData);
    dispatch({
      type: REGISTER_SUCCESS,
      payload: res.data,
    });

    toast.success(`Registration success!`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    history.push("/login");
  } catch (err) {
    if (!err.response) {
      toast.error(`Network Error!`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      // console.log(errors)
      const errors = err.response.data;
      if (errors.error === "Provided email Dublicate") {
        toast.error(`User with given email already exist please login`);
        history.push("/login");
      } else {
        toast.error(`${errors.error}`);
      }
    }

    dispatch({
      type: REGISTER_FAIL,
    });
  }
};

// Login User
export const SocialLogin =
  (provider, idToken, firstName, lastName) => async (dispatch) => {
    const body = {
      provider,
      idToken,
      firstName,
      lastName,
    };
    try {
      const res = await api.post("/auth/social-login", body);
      console.log(res.data);
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
      toast.success(`${res.data.message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch(loadUser());
    } catch (err) {
      console.log(err);
      if (!err.response) {
        toast.error(`Network Error!`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(err.response.data.error);
      }
      dispatch({ type: LOGIN_FAIL });
    }
  };

// Register User
export const registerSocial =
  (provider, idToken, firstName, lastName, history) => async (dispatch) => {
    const body = {
      provider,
      idToken,
      firstName,
      lastName,
    };
    try {
      const res = await api.post("/users/register-streamer-social", body);
      dispatch({
        type: REGISTER_SUCCESS,
        payload: res.data,
      });

      toast.success(`Registration success!`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      history.push("/login");
    } catch (err) {
      const errors = err.response.data;
      toast.error(`${errors.error}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      dispatch({
        type: REGISTER_FAIL,
      });
    }
  };

// Login User
export const login = (email, password, history) => async (dispatch) => {
  const body = { email, password };
  try {
    const res = await api.post("/auth/login", body);
    console.log(res.data);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });
    toast.success(`${res.data.message}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    dispatch(loadUser());
  } catch (err) {
    console.log(err);

    if (err.response.data.error === "Email not found") {
      toast.error("Email not registered yet. Please register to login.");

      // history.push("/register");
    } else {
      toast.error(err.response.data.error, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        toastId: "login error",
        draggable: true,
        progress: undefined,
      });
    }
    dispatch({ type: LOGIN_FAIL });
  }
};

//forgot password
export const forgotPassword = (email, history, actor) => async (dispatch) => {
  const body = JSON.stringify({ email });

  try {
    const res = await api.post("/auth/forgot", body);
    dispatch({
      type: SUCCESS_FORGOTPASSWORD,
      payload: email,
    });
    toast.success(` Reset Link has been send to your Mail Please check `, {
      position: "bottom-center",
      autoClose: false,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  } catch (err) {
    if (!err.response) {
      toast.error(`Some thing went wrong`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error(err.response.data.message);
      dispatch({
        type: LOGIN_FAIL,
      });
    }
  }
};
export const verifyCode = (resetCode, history, actor) => async (dispatch) => {
  const body = JSON.stringify({ resetCode });

  try {
    const res = await api.post("/auth/verifycode", body);
    // console.log(res)
    dispatch({
      type: SUCCESS_VERIFY_CODE,
      payload: resetCode,
    });

    toast.success(` ${res.data.message} `, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    if (res.status === 200) {
      // history.push('/resetpassword')
      window.jQuery(".modal-2").modal("hide");

      window.jQuery("#password-modal").modal("show");
    }
  } catch (err) {
    // toast.error(err.response.data.message)

    const errors = err.response.data.errors;
    //  console.log(errors)

    if (errors) {
      errors.forEach((error) =>
        toast.error(`${error.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      );
    }
    dispatch({
      type: LOGIN_FAIL,
    });
  }
};
export const resetPassword =
  (password, confirmpassword, resetCode, history) => async (dispatch) => {
    // console.log(resetCode)
    const body = JSON.stringify({ password });

    try {
      const res = await api.post(`/auth/reset-password/${resetCode}`, body);
      // console.log(res)

      if (res.status == 200) {
        dispatch({
          type: SUCCESS_VERIFY_CODE,
          payload: res.data,
        });
        history.push("/login");
        toast.success(` ${res.data.message} `, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (err) {
      const errors = err.response.data;
      //  console.log(errors)
      toast.error(`${errors.error}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    dispatch({ type: LOGIN_FAIL });
  };
// Logout
export const logout = () => async (dispatch) => {
  const res = await api.delete("/auth/logout");
  // console.log(res)
  // window.jQuery('#logout').modal('hide');

  dispatch({ type: LOGOUT });
  toast.success(`${res.data.message}`, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};
export const updatePassword =
  (currentpassword, newpassword, confirmpassword, history) =>
  async (dispatch) => {
    const body = {
      currentpassword: currentpassword,
      newpassword: newpassword,
      confirmpassword: confirmpassword,
    };

    try {
      const res = await api.post("/auth/change-password", body);
      toast.success(`${res.data.message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      dispatch(logout());

      // console.log(res)
      dispatch({
        type: CHANGE_PASSWORD,
      });

      // dispatch(loadUser());
    } catch (err) {
      toast.error(err.response.data.error);
    }
  };
