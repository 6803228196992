import ViewLoader from "../../Components/ViewLoader";
import useFetch from "../../hooks/useFetch.js";
import WithSidebar from "../layout/WithSidebar";
import React, { useCallback, useEffect, useState } from "react";

import TablePage from "../../Components/table/TablePage";
import { getSerial } from "../../utils/commonFunctions";
import moment from "moment";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  ModalBtnSubmit,
  ModalRight,
} from "../../Components/modal/ModalComponents";
import { ModalHeader, ModalBody, Button } from "reactstrap";

import {
  getLists,
  deleteList,
  updateList,
  createList,
  getList,
} from "../../actions/listAction.js";

import {
  getItem,
  deleteItem,
  updateItem,
  createItem,
  getItems,
} from "../../actions/itemsAction.js";

const ListsItems = ({
  createList,
  updateList,
  deleteList,
  getList,
  getLists,
  list: { Lists, List, loading },
  getItem,
  deleteItem,
  updateItem,
  createItem,
  getItems,
  itemData,
}) => {
  const [page, setPage] = useState(1);
  const [limit, setlimit] = useState(5);
  const [keyword, setKeyword] = useState("");
  const [genreStatus, setGenreStatus] = useState(9);
  const [newGenreAdded, setNewGenreAdded] = useState(false);
  const [genreName, setGenreName] = useState("");
  const [showAddGenreForm, setShowAddGenreForm] = useState(false);

  const [modalPost, setModalPost] = useState(false);

  const toggleModalPost = () => {
    setModalPost(!modalPost);
  };

  const handlePageClick = (data) => {
    let selected = data.selected + 1;
    setPage(selected);
  };

  const refetchList = useCallback(() => {
    getLists(page, limit);
  }, [page, limit, getLists]);

  useEffect(() => {
    getLists(page, limit, keyword);
  }, [getLists, page, limit, keyword]);

  useEffect(() => {}, [newGenreAdded]);
  console.log(Lists);

  return (
    <WithSidebar>
      <ModalRight
        isOpen={modalPost}
        toggle={toggleModalPost}
        // className="modal-two-column"
      >
        <ModalHeader toggle={toggleModalPost}>List</ModalHeader>

        <ModalBody>
          <EditCateogry
            // updatePicture={updatePicture}
            // id={id}
            list={List}
            updateList={updateList}
            getListByID={getList}
            loading={loading}
            deleteList={deleteList}
            modalPost={modalPost}
            toggleModalPost={toggleModalPost}
            refetchList={refetchList}
            // update
            createlist={createList}
          />
        </ModalBody>
      </ModalRight>

      <TablePage
        title="Manage List/Items"
        onAddNewBtnClick={toggleModalPost}
        addNewBtnText={"Add Lists"}
        filters={[
          {
            name: "Search lists",
            filterText: keyword,
            onChange: (e) => {
              setKeyword(e.target.value);
            },
          },
        ]}
        limit={limit}
        setlimit={setlimit}
        pagination={Lists?.data?.pagination}
        handlePageClick={handlePageClick}
      >
        <thead>
          <tr role="row">
            <th className="text-nowrap">SN</th>
            <th className="text-nowrap">Name</th>
            <th className="text-nowrap">Created At</th>
            <th className="text-nowrap">Last Modified</th>

            <th className="text-nowrap">Action</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <ViewLoader />
          ) : Lists && Lists?.data?.length > 0 ? (
            Lists?.data.map((item, index) => (
              <tr role="row" className="odd" key={index}>
                <td className="text-nowrap">
                  {getSerial(Lists?.pagination, index)}
                </td>
                <td className="text-nowrap">{item.name}</td>
                <td className="text-nowrap">
                  {moment(item && item.createdAt).format("DD/MM/YYYY")}
                </td>
                <td className="text-nowrap">
                  {moment(item && item.updatedAt).format("DD/MM/YYYY")}
                </td>
                <td className="text-nowrap">
                  <ColumnAction
                    List={List}
                    itemsObject={{
                      getItem,
                      deleteItem,
                      updateItem,
                      createItem,
                      getItems,
                      itemData,
                    }}
                    deleteList={deleteList}
                    getList={getList}
                    id={item._id}
                    loading={loading}
                    refetchList={refetchList}
                    updateList={updateList}
                  />
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="7">No lits found.</td>
            </tr>
          )}
        </tbody>
      </TablePage>
    </WithSidebar>
  );
};
ListsItems.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  list: state.lists,
  itemData: state.items,
});

export default connect(mapStateToProps, {
  getLists,
  createList,
  updateList,
  deleteList,
  getList,
  // items
  getItem,
  getItems,
  createItem,
  updateItem,
  deleteItem,
})(ListsItems);

function ColumnAction({
  id,
  List,
  updateList,
  getList,
  loading,
  deleteList,
  refetchList,
  itemsObject,
}) {
  const [modalPost, setModalPost] = useState(false);
  const [sowUpdateModel, setUpdateModel] = useState(false);
  const [showItemModel, setItemModel] = useState(false);
  const [modalDeletePost, setModalDeletePost] = useState(false);

  const toggleModalPost = () => {
    setModalPost(!modalPost);
  };

  const toggleItemModel = () => {
    setItemModel(!showItemModel);
  };

  const toggleModalDeletePost = () => {
    setModalDeletePost(!modalDeletePost);
  };
  return (
    <>
      {/* <ModalRight
        modalId={`editModal-${id}`}
        title="Category"
        btnText="Save"
        onBtnClick={() => {
          toggleModalPost()
        }}
        modalPost={modalPost}
        toggleModalPost={toggleModalPost}
      > */}

      <ModalRight
        isOpen={modalPost}
        toggle={toggleModalPost}
        // className="modal-two-column"
      >
        <ModalHeader toggle={toggleModalPost}>Category</ModalHeader>

        <ModalBody>
          <EditCateogry
            // updatePicture={updatePicture}
            // id={id}
            list={List}
            updateList={updateList}
            getListByID={getList}
            loading={loading}
            deleteList={deleteList}
            modalPost={modalPost}
            toggleModalPost={toggleModalPost}
            refetchList={refetchList}
            // update
            createlist={createList}
          />
        </ModalBody>
      </ModalRight>

      <ModalRight
        isOpen={showItemModel}
        toggle={toggleItemModel}
        style={{
          maxWidth: "1200px",
          width: "100%",
          backgroundColor: "#6c757d",
        }}
        // className="modal-two-column"
      >
        <ModalHeader toggle={toggleItemModel}>Items</ModalHeader>

        <ModalBody style={{ padding: "0" }}>
          <ItemsPage id={id} itemsObject={itemsObject} />
        </ModalBody>
      </ModalRight>

      <ModalRight
        isOpen={sowUpdateModel}
        toggle={() => setUpdateModel(!sowUpdateModel)}
        // className="modal-two-column"
      >
        <ModalHeader toggle={() => setUpdateModel(!sowUpdateModel)}>
          Category
        </ModalHeader>

        <ModalBody>
          <EditCateogry
            // updatePicture={updatePicture}
            id={id}
            list={List}
            updateList={updateList}
            getListByID={getList}
            loading={loading}
            deleteList={deleteList}
            modalPost={sowUpdateModel}
            toggleModalPost={() => setUpdateModel(!sowUpdateModel)}
            refetchList={refetchList}
            update
            createlist={createList}
          />
        </ModalBody>
      </ModalRight>

      <Button
        onClick={() => setUpdateModel(!sowUpdateModel)}
        style={{ marginRight: "10px" }}
        className="btn btn-secondary btn-sm"
      >
        Edit
      </Button>

      <Button
        onClick={() => toggleItemModel()}
        style={{ marginRight: "10px" }}
        className="btn btn-info btn-sm"
      >
        Items
      </Button>

      <Button
        onClick={() => {
          toggleModalDeletePost();
        }}
        className="btn  btn-danger btn-sm"
      >
        Delete
      </Button>

      <ModalRight
        isOpen={modalDeletePost}
        toggle={toggleModalDeletePost}
        // className="modal-two-column"
      >
        <ModalHeader toggle={toggleModalDeletePost}>Heads Up!</ModalHeader>

        <ModalBody>
          <p>Are you sure wanna delete this Item?</p>

          <button
            onClick={() => {
              deleteList(id).then((e) => {
                console.log(e);
                // setNewCategoryAdded(true);
                // setShowAddCategoryForm(false);
                toggleModalDeletePost();
                refetchList();
              });
            }}
            data-dismiss="modal"
            type="button"
            class="btn btn-danger"
          >
            Delete
          </button>
        </ModalBody>
      </ModalRight>
    </>
  );
}

const ItemsPage = ({ id, itemsObject }) => {
  const [keyword, setKeyword] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setlimit] = useState(5);
  const [showAddItem, setAddItem] = useState(false);
  const [showUpdateItem, setUpdateItem] = useState(false);
  const [showDeleteItem, setDeleteItem] = useState(false);

  const [updateId, setUpdateId] = useState(null);

  const { Items, Item, loading } = itemsObject.itemData;

  // const { data: Items, loading, error } = useFetch(`/lists/${id}/items`);

  const handlePageClick = (data) => {
    let selected = data.selected + 1;
    setPage(selected);
  };

  useEffect(() => {
    itemsObject.getItems(id, page, limit, keyword);
  }, [itemsObject.getItems, page, limit, keyword]);

  const refetchList = useCallback(() => {
    itemsObject.getItems(id, page, limit);
  }, [page, Items, limit, itemsObject.getItems]);

  const toggleAddItem = () => {
    setAddItem(!showAddItem);
  };
  const toggleUpdateItem = () => {
    setUpdateItem(!showUpdateItem);
  };

  const toggleDeleteItem = () => {
    setDeleteItem(!showDeleteItem);
  };

  return (
    <>
      <ModalRight
        isOpen={showAddItem}
        toggle={toggleAddItem}
        // className="modal-two-column"
      >
        <ModalHeader toggle={toggleAddItem}>Items</ModalHeader>

        <ModalBody>
          <EditItems
            Item={Item}
            createItem={itemsObject.createItem}
            updateItem={itemsObject.updateItem}
            getItem={itemsObject.getItem}
            loading={loading}
            modalPost={showAddItem}
            id={id}
            toggleModalPost={toggleAddItem}
            refetchList={refetchList}
          />
        </ModalBody>
      </ModalRight>
      <ModalRight
        isOpen={showUpdateItem}
        toggle={toggleUpdateItem}
        // className="modal-two-column"
      >
        <ModalHeader toggle={toggleUpdateItem}>Items</ModalHeader>

        <ModalBody>
          <EditItems
            update
            Item={Item}
            createItem={itemsObject.createItem}
            updateItem={itemsObject.updateItem}
            getItem={itemsObject.getItem}
            id={updateId}
            loading={loading}
            modalPost={showUpdateItem}
            toggleModalPost={toggleUpdateItem}
            refetchList={refetchList}
          />
        </ModalBody>
      </ModalRight>

      <ModalRight
        isOpen={showDeleteItem}
        toggle={toggleDeleteItem}
        // className="modal-two-column"
      >
        <ModalHeader toggle={toggleDeleteItem}>Heads Up!</ModalHeader>

        <ModalBody>
          <p>Are you sure wanna delete this Item?</p>

          <button
            onClick={() => {
              itemsObject.deleteItem(updateId).then((e) => {
                console.log(e);
                // setNewCategoryAdded(true);
                // setShowAddCategoryForm(false);
                toggleDeleteItem();
                refetchList();
              });
            }}
            data-dismiss="modal"
            type="button"
            class="btn btn-danger"
          >
            Delete
          </button>
        </ModalBody>
      </ModalRight>

      <TablePage
        paddingTop
        // title="Items"
        onAddNewBtnClick={toggleAddItem}
        addNewBtnText={"Add Items"}
        filters={[
          {
            name: "Search Items",
            filterText: keyword,
            onChange: (e) => {
              setKeyword(e.target.value);
            },
          },
        ]}
        limit={limit}
        setlimit={setlimit}
        pagination={Items?.data?.pagination}
        handlePageClick={handlePageClick}
      >
        <thead>
          <tr role="row">
            <th className="text-nowrap">SN</th>
            <th className="text-nowrap">Image</th>
            <th className="text-nowrap">Name</th>
            <th className="text-nowrap">Created At</th>
            <th className="text-nowrap">Last Modified</th>

            <th className="text-nowrap">Action</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <ViewLoader />
          ) : Items && Items?.data?.length > 0 ? (
            Items?.data.map((item, index) => (
              <tr role="row" className="odd" key={index}>
                <td className="text-nowrap">
                  {getSerial(Items?.pagination, index)}
                </td>
                <td className="text-nowrap">
                  {item.image !== "" ? (
                    <img
                      src={item.image}
                      alt=""
                      style={{
                        maxHeight: "30px",
                        minWidth: "30px",
                      }}
                    />
                  ) : (
                    ""
                  )}
                </td>
                <td className="text-nowrap">{item.name}</td>
                <td className="text-nowrap">
                  {moment(item && item.createdAt).format("DD/MM/YYYY")}
                </td>
                <td className="text-nowrap">
                  {moment(item && item.updatedAt).format("DD/MM/YYYY")}
                </td>
                <td className="text-nowrap">
                  <button
                    onClick={() => {
                      setUpdateId(item?._id);
                      toggleUpdateItem();
                    }}
                    style={{ marginRight: "10px" }}
                    className="btn btn-secondary btn-sm"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => {
                      setUpdateId(item?._id);
                      toggleDeleteItem();
                    }}
                    className="btn btn-danger btn-sm"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="7">No lits found.</td>
            </tr>
          )}
        </tbody>
      </TablePage>
    </>
  );
};

const EditItems = ({
  createItem,
  updateItem,
  toggleModalPost,
  refetchList,
  modalPost,
  id,
  getItem,
  Item,
  update,
  loading,
}) => {
  const [formData, setFormData] = useState({
    name: "",
    image: "", // for preview
    file: "", // for uploading
    updated: false,
  });
  const [imageUrl, setImageUrl] = useState("");

  const UpdateThisForm = (e) => {
    e.preventDefault();
    // console.log('formData', formData)
    updateItem(id, formData).then((_) => {
      refetchList();
      toggleModalPost();
    }); // close modal
    //setFormData({ ...formData, updated: true })
  };

  const handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      setFormData({ ...formData, image: reader.result });
      setImageUrl(() => reader.result);

      setFormData({ ...formData, file: file });
    };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    if (update && Item !== null) {
      // console.log(Item);
      setFormData({
        status: loading || Item?.data?.status,
        image: loading || !Item?.data?.image ? "" : Item?.data?.image,
        name: loading || !Item?.data?.name ? "" : Item?.data?.name,
      });
    } else {
      setFormData({
        status: false,
        image: "",
        name: "",
      });
    }
  }, [loading, Item, update]);

  useEffect(() => {
    update && getItem(id);
  }, [update, getItem, id, modalPost]);

  const SubmitAddItemForm = (e) => {
    e.preventDefault();
    // setNewCategoryAdded(false);
    console.log("formData", formData);
    createItem(id, formData).then((e) => {
      console.log(e);
      // setNewCategoryAdded(true);
      // setShowAddCategoryForm(false);
      refetchList();
      toggleModalPost();
    });
  };

  return (
    <>
      <form
        onSubmit={(e) => {
          if (update) UpdateThisForm(e);
          else SubmitAddItemForm(e);
        }}
      >
        <div className="form-row">
          <div className="form-group col-md-12">
            <label htmlFor="inputState">Item label</label>
            <input
              type="text"
              value={formData.name}
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
              className="form-control"
              placeholder="Item label"
            />
          </div>

          <div className="form-group col-md-12">
            <img
              className="img-thumbnail"
              src={imageUrl !== "" ? imageUrl : formData.image}
              alt=""
            />
            <div className="input-group mt-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="inputGroupFileAddon01">
                  Upload
                </span>
              </div>
              <div className="custom-file">
                <input
                  type="file"
                  multiple
                  accept="image/*"
                  onChange={(e) => handleImageChange(e)}
                  className="custom-file-input"
                />
                <label className="custom-file-label">Choose Image</label>
              </div>
            </div>
          </div>
        </div>
        <button
          type="submit"
          className="btn btn-danger"
          // data-dismiss="modal"
        >
          Save
        </button>
      </form>
    </>
  );
};

const EditCateogry = ({
  id, // for modal
  modalPost,
  toggleModalPost,
  getListByID,
  loading,
  list,
  updateList,
  createlist,
  refetchList,
  update,
}) => {
  console.log("id", id);

  const [formData, setFormData] = useState({
    name: "",
  });

  const UpdateThisForm = (e) => {
    e.preventDefault();
    updateList(id, formData).then((_) => {
      toggleModalPost(); // close modal
      refetchList();
    });
  };

  useEffect(() => {
    if (update && list !== null) {
      setFormData({
        name: loading || !list?.data?.name ? "" : list?.data?.name,
      });
    } else {
      setFormData({ name: "" });
    }
  }, [loading, list, update]);

  useEffect(() => {
    console.log("id", id, modalPost);
    update && getListByID(id);
  }, [update, getListByID, id, modalPost]);

  const SubmitAddListForm = (e) => {
    e.preventDefault();
    // setNewListAdded(false);
    // console.log("formData", formData);
    createlist(formData).then((e) => {
      console.log(e);
      // setNewListAdded(true);
      // setShowAddListForm(false);
      toggleModalPost();
      refetchList();
    });
  };

  return (
    <>
      <form
        onSubmit={(e) => {
          if (update) UpdateThisForm(e);
          else SubmitAddListForm(e);
        }}
      >
        <div className="form-row">
          <div className="form-group col-md-12">
            <label htmlFor="inputState">List label</label>
            <input
              type="text"
              value={formData.name}
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
              className="form-control"
              placeholder="List label"
            />
          </div>
        </div>
        <button
          type="submit"
          className="btn btn-danger"
          // data-dismiss="modal"
        >
          Save
        </button>
      </form>
    </>
  );
};
