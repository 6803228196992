import React from 'react';
import { Link } from 'react-router-dom';
import './WorkoutCategory.css';

const WorkoutCategory = ({ category, workouts }) => (
  <div className="workout-category-page">
    <div className="category-header">
      <Link to="/" className="back-button">← Back</Link>
      <h2>{category.charAt(0).toUpperCase() + category.slice(1)} Workouts</h2>
    </div>
    <div className="workouts-list">
      {workouts.map((workout, index) => (
        <div key={index} className="workout-item">
          <img src={workout.image} alt={workout.name} />
          <div className="workout-info">
            <h3>{workout.name}</h3>
            <p>{workout.description}</p>
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default WorkoutCategory;
