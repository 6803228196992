import {
  LIST_ERROR,
  CREATE_LIST,
  GET_LISTS,
  GET_LIST_BY_ID,
  DELETE_LIST,
} from "../actions/types";

const initialState = {
  Lists: [],
  List: null,
  loading: true,
  error: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_LISTS:
      return {
        ...state,
        Lists: payload,
        loading: false,
      };
    case DELETE_LIST:
      return {
        ...state,
        Lists: {
          ...state.Lists,
          data: state.Lists.data.filter((elem, i) => elem._id !== payload.id),
        },
        loading: false,
      };
    case CREATE_LIST:
      return {
        ...state,
        Lists: {
          ...state.Lists,
          data: [...state.Lists.data, payload],
        },
        loading: false,
      };
    case GET_LIST_BY_ID: {
      return {
        ...state,
        List: payload,
        loading: false,
      };
    }

    case LIST_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }

    default:
      return state;
  }
}
