import React from "react";

function Verified() {
  return (
    <svg
      width="30"
      height="30"
      version="1.1"
      viewBox="0 0 700 700"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="#EB5757"
        d="m350 70c-115.98 0-210 94.02-210 210s94.02 210 210 210 210-94.02 210-210-94.02-210-210-210zm94.457 174.27-96.234 96.215c-3.418 3.418-7.8945 5.1289-12.375 5.1289-4.4766 0-8.957-1.7109-12.375-5.1289l-43.766-43.766c-6.8359-6.8359-6.8359-17.91 0-24.746 6.8359-6.8359 17.91-6.8359 24.746 0l31.395 31.395 83.859-83.844c6.8359-6.8359 17.91-6.8359 24.746 0 6.8398 6.8359 6.8398 17.91 0.003906 24.746z"
      />
    </svg>
  );
}

function Live() {
  return (
    <svg
      width="17"
      height="12"
      viewBox="0 0 17 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.0472 0.785L11.6668 4.25V2C11.6668 1.60218 11.5 1.22064 11.2031 0.93934C10.9061 0.658035 10.5034 0.5 10.0835 0.5H2.16683C1.7469 0.5 1.34418 0.658035 1.04724 0.93934C0.750311 1.22064 0.583496 1.60218 0.583496 2V9.5C0.583496 9.89782 0.750311 10.2794 1.04724 10.5607C1.34418 10.842 1.7469 11 2.16683 11H10.0835C10.5034 11 10.9061 10.842 11.2031 10.5607C11.5 10.2794 11.6668 9.89782 11.6668 9.5V7.625L15.0631 10.8425C15.1733 10.9478 15.3141 11.0198 15.4675 11.0493C15.621 11.0789 15.7802 11.0646 15.9251 11.0085C16.07 10.9523 16.194 10.8566 16.2816 10.7336C16.3691 10.6106 16.4162 10.4658 16.4168 10.3175V1.3175C16.4212 1.16536 16.3765 1.01557 16.2889 0.888032C16.2012 0.760493 16.0746 0.661239 15.926 0.603454C15.7774 0.545669 15.6137 0.532088 15.4568 0.564515C15.2998 0.596942 15.157 0.673842 15.0472 0.785Z"
        fill="white"
      />
    </svg>
  );
}

function Facebook() {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 0.00976562C4.48163 0.00976562 0.00695801 4.48444 0.00695801 10.0028C0.00695801 15.5212 4.48163 19.9959 10 19.9959C15.5184 19.9959 19.9931 15.5212 19.9931 10.0028C19.9931 4.48444 15.5184 0.00976562 10 0.00976562ZM13.3768 5.92094C13.3768 6.06112 13.263 6.17632 13.1228 6.17632H12.0319C11.2797 6.17632 11.1423 6.46917 11.1423 7.04655V8.24849H13.0465C13.1145 8.24849 13.1798 8.27486 13.2269 8.32344C13.2741 8.37201 13.3019 8.43586 13.3019 8.50387L13.3005 10.558C13.3005 10.6982 13.1867 10.8134 13.0451 10.8134H11.1409V15.9959C11.1409 16.1361 11.0271 16.2513 10.8855 16.2513H8.76199C8.62181 16.2513 8.50661 16.1375 8.50661 15.9959V10.812H6.87857C6.73839 10.812 6.6232 10.6982 6.6232 10.5566V8.50248C6.6232 8.3623 6.73701 8.2471 6.87857 8.2471H8.50661V6.83142C8.50661 4.96327 9.68218 3.75716 11.5031 3.75716L13.1228 3.75994C13.263 3.75994 13.3768 3.87375 13.3768 4.01532V5.92094Z"
        fill="#7C7D7D"
      />
    </svg>
  );
}
function Follower() {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_42_2015)">
        <path
          d="M7.67233 12.7468C9.75144 12.7468 11.4364 10.6372 11.4364 8.55946C11.4364 6.48174 9.75144 4.79541 7.67233 4.79541C5.59322 4.79541 3.90967 6.48035 3.90967 8.55946C3.90967 10.6386 5.59461 12.7468 7.67233 12.7468Z"
          fill="#7C7D7D"
        />
        <path
          d="M11.2186 13.3423C11.0035 13.2201 10.7342 13.2326 10.5329 13.377C9.69742 13.9752 8.71893 14.3388 7.67382 14.3388C6.61206 14.3388 5.62108 13.9654 4.77861 13.3506C4.58014 13.2063 4.31782 13.1799 4.10269 13.2979C1.94308 14.4873 0.388604 16.6941 0.0541143 19.0966C-0.0166699 19.6074 0.378889 20.0626 0.895197 20.0626H14.3456C14.8605 20.0626 15.2574 19.6074 15.1867 19.098C14.8563 16.7246 13.3352 14.5428 11.2186 13.3423Z"
          fill="#7C7D7D"
        />
        <path
          d="M18.9827 2.85227H17.1784V1.04796C17.1784 0.510837 16.744 0.076416 16.2069 0.076416C15.6697 0.076416 15.2353 0.510837 15.2353 1.04796V2.85227H13.431C12.8939 2.85227 12.4595 3.28669 12.4595 3.82381C12.4595 4.36094 12.8939 4.79536 13.431 4.79536H15.2353V6.59966C15.2353 7.13679 15.6697 7.57121 16.2069 7.57121C16.744 7.57121 17.1784 7.13679 17.1784 6.59966V4.79536H18.9827C19.5199 4.79536 19.9543 4.36094 19.9543 3.82381C19.9543 3.28669 19.5199 2.85227 18.9827 2.85227Z"
          fill="#7C7D7D"
        />
      </g>
      <defs>
        <clipPath id="clip0_42_2015">
          <rect
            width="20"
            height="20.0056"
            fill="white"
            transform="translate(0 0.0666504)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
function Help() {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_42_5483)">
        <path
          d="M10 0.0152588C4.48163 0.0152588 0.00695801 4.48993 0.00695801 10.0083C0.00695801 15.5267 4.48163 20.0014 10 20.0014C15.5184 20.0014 19.9931 15.5267 19.9931 10.0083C19.9931 4.48993 15.5184 0.0152588 10 0.0152588ZM9.85984 15.5864C9.61695 15.8293 9.30328 15.95 8.91743 15.95C8.53159 15.95 8.21515 15.8293 7.96671 15.5864C7.71827 15.3435 7.59474 15.052 7.59474 14.7106C7.59474 14.3581 7.71827 14.0611 7.96671 13.8182C8.21515 13.5753 8.53159 13.4545 8.91743 13.4545C9.30328 13.4545 9.61695 13.5753 9.85984 13.8182C10.1027 14.0611 10.2235 14.3581 10.2235 14.7106C10.2235 15.052 10.1013 15.3435 9.85984 15.5864ZM13.2727 9.42123C12.9369 9.91672 12.4802 10.2859 11.9015 10.5288C11.3227 10.7717 10.6537 10.898 9.89315 10.9091L9.84318 12.2318H7.97642L7.9098 9.5045H8.67039C9.23249 9.5045 9.74464 9.45454 10.2068 9.35599C10.669 9.25745 11.0382 9.06453 11.3144 8.77723C11.5892 8.49132 11.728 8.07216 11.728 7.52116C11.728 6.99236 11.5684 6.57598 11.2491 6.27341C10.9299 5.97084 10.5052 5.81956 9.97642 5.81956C9.42542 5.81956 8.99516 5.97084 8.68704 6.27341C8.37892 6.57598 8.22486 6.98681 8.22486 7.5045H6.22347C6.21237 6.84385 6.35533 6.25398 6.65373 5.73629C6.95075 5.21859 7.38378 4.81054 7.95144 4.51352C8.5191 4.21651 9.19918 4.068 9.99308 4.068C10.7315 4.068 11.3838 4.2054 11.9514 4.4816C12.5191 4.7578 12.9646 5.15058 13.2894 5.66273C13.6142 6.17487 13.7766 6.80082 13.7766 7.53781C13.7766 8.2984 13.6086 8.92574 13.2727 9.42123Z"
          fill="#7C7D7D"
        />
      </g>
      <defs>
        <clipPath id="clip0_42_5483">
          <rect
            width="20"
            height="20.0056"
            fill="white"
            transform="translate(0 0.0055542)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
function Home() {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.00695801 7.78208C0.00695801 8.31643 0.444154 8.75363 0.978505 8.75363H10.2776C10.812 8.75363 11.2492 8.31643 11.2492 7.78208V0.981252C11.2492 0.446901 10.812 0.00970459 10.2776 0.00970459H0.978505C0.444154 0.00970459 0.00695801 0.446901 0.00695801 0.981252V7.78208Z"
        fill="#7C7D7D"
      />
      <path
        d="M14.719 0.00970459C14.1846 0.00970459 13.7474 0.446901 13.7474 0.981252V7.78208C13.7474 8.31643 14.1846 8.75363 14.719 8.75363H19.0215C19.5559 8.75363 19.9931 8.31643 19.9931 7.78208V0.981252C19.9931 0.446901 19.5559 0.00970459 19.0215 0.00970459H14.719Z"
        fill="#7C7D7D"
      />
      <path
        d="M0.00695801 19.0243C0.00695801 19.5586 0.444154 19.9958 0.978505 19.9958H5.28107C5.81542 19.9958 6.25262 19.5586 6.25262 19.0243V12.2234C6.25262 11.6891 5.81542 11.2519 5.28107 11.2519H0.978505C0.444154 11.2519 0.00695801 11.6891 0.00695801 12.2234V19.0243Z"
        fill="#7C7D7D"
      />
      <path
        d="M8.75085 19.0243C8.75085 19.5586 9.18805 19.9958 9.7224 19.9958H19.0215C19.5558 19.9958 19.993 19.5586 19.993 19.0243V12.2234C19.993 11.6891 19.5558 11.2519 19.0215 11.2519H9.7224C9.18805 11.2519 8.75085 11.6891 8.75085 12.2234V19.0243Z"
        fill="#7C7D7D"
      />
    </svg>
  );
}
function Instagram() {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.8785 6.322C14.7785 6.05135 14.6189 5.80708 14.4121 5.60305C14.2123 5.39487 13.9652 5.23664 13.6946 5.13671C13.4753 5.05066 13.145 4.94795 12.5343 4.92158C11.8764 4.89244 11.6793 4.8855 10.0083 4.8855C8.33997 4.8855 8.14149 4.89244 7.48223 4.92158C6.87432 4.94795 6.54122 5.05066 6.32192 5.13671C6.05128 5.23664 5.807 5.39625 5.60437 5.60305C5.39479 5.80292 5.23518 6.04997 5.13525 6.322C5.0492 6.54129 4.94649 6.87162 4.92012 7.48231C4.89097 8.13879 4.88403 8.34004 4.88403 10.0083C4.88403 11.6766 4.89097 11.8751 4.92012 12.5344C4.94649 13.1423 5.0492 13.4754 5.13525 13.6947C5.23518 13.9653 5.39479 14.2096 5.60159 14.4122C5.80145 14.6204 6.0485 14.7786 6.31915 14.8786C6.53844 14.9646 6.86877 15.0673 7.47945 15.0937C8.13733 15.1228 8.33719 15.1298 10.0055 15.1298C11.6738 15.1298 11.8722 15.1228 12.5315 15.0937C13.1394 15.0673 13.4725 14.9646 13.6918 14.8786C14.2373 14.669 14.6689 14.2359 14.8785 13.6919C14.9645 13.4726 15.0672 13.1423 15.0936 12.5316C15.1228 11.8723 15.1297 11.6766 15.1297 10.0083C15.1297 8.34004 15.1228 8.14157 15.0936 7.48231C15.0658 6.87439 14.9645 6.54268 14.8785 6.322ZM10.0027 13.2158C8.22893 13.2158 6.78965 11.7765 6.78965 10.0028C6.78965 8.22901 8.22893 6.78973 10.0027 6.78973C11.7765 6.78973 13.2157 8.22901 13.2157 10.0028C13.2157 11.7765 11.7765 13.2158 10.0027 13.2158ZM13.342 7.4143C12.9284 7.4143 12.5926 7.07842 12.5926 6.66482C12.5926 6.25122 12.9284 5.91534 13.342 5.91534C13.7556 5.91534 14.0915 6.25122 14.0915 6.66482C14.0915 7.07842 13.7556 7.4143 13.342 7.4143ZM11.9278 10.0028C11.9278 11.0673 11.0645 11.9306 9.99992 11.9306C8.93539 11.9306 8.0721 11.0673 8.0721 10.0028C8.0721 8.93824 8.93539 8.07495 9.99992 8.07495C11.0645 8.07495 11.9278 8.93824 11.9278 10.0028Z"
        fill="#7C7D7D"
      />
      <path
        d="M10 0.00976562C4.48163 0.00976562 0.00695801 4.48444 0.00695801 10.0028C0.00695801 15.5212 4.48163 19.9959 10 19.9959C15.5184 19.9959 19.9931 15.5212 19.9931 10.0028C19.9931 4.48444 15.5184 0.00976562 10 0.00976562ZM16.2151 12.5816C16.186 13.2464 16.0777 13.7003 15.9251 14.0986C15.6003 14.9383 14.9355 15.6031 14.0958 15.9279C13.7002 16.0819 13.2436 16.1888 12.5788 16.218C11.9112 16.2471 11.6988 16.254 10.0014 16.254C8.30397 16.254 8.09162 16.2471 7.42403 16.218C6.75921 16.1888 6.30536 16.0805 5.90703 15.9279C5.49204 15.771 5.11313 15.5254 4.80085 15.2075C4.48302 14.8953 4.23596 14.5191 4.07774 14.0986C3.92368 13.703 3.81681 13.2464 3.78767 12.5816C3.75852 11.914 3.75158 11.7016 3.75158 10.0042C3.75158 8.30678 3.75852 8.09443 3.79044 7.42406C3.81959 6.75925 3.92785 6.30539 4.08052 5.90706C4.23735 5.49207 4.48302 5.11317 4.80085 4.80088C5.11313 4.48027 5.48926 4.236 5.9098 4.07777C6.30536 3.92371 6.76199 3.81684 7.4268 3.7877C8.0944 3.75855 8.30675 3.75161 10.0042 3.75161C11.7016 3.75161 11.914 3.75855 12.5843 3.79047C13.2491 3.81962 13.703 3.92788 14.1013 4.08055C14.5163 4.23739 14.8952 4.48305 15.2075 4.80088C15.5281 5.11317 15.7724 5.48929 15.9306 5.90983C16.0847 6.30539 16.1915 6.76202 16.2207 7.42684C16.2498 8.09443 16.2526 8.30678 16.2526 10.0042C16.2526 11.7016 16.2443 11.914 16.2151 12.5816Z"
        fill="#7C7D7D"
      />
    </svg>
  );
}
function Logout() {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_42_5486)">
        <path
          d="M12.2221 12.4137V14.6482C12.2221 15.0577 11.9847 15.1562 11.696 14.8661L7.39209 10.5566C7.1034 10.2666 7.1034 9.79327 7.39209 9.5032L11.696 5.19508C11.9847 4.905 12.2221 5.00354 12.2221 5.41298V7.64754C12.2221 8.05698 12.5566 8.39286 12.966 8.39286H19.9251C19.1437 3.64754 15.0298 0.027832 10.068 0.027832C4.54962 0.027832 0.0749512 4.50667 0.0749512 10.0306C0.0749512 15.5545 4.54962 20.0334 10.068 20.0334C15.0298 20.0334 19.1437 16.4137 19.9251 11.6684H12.966C12.5566 11.6684 12.2221 12.0042 12.2221 12.4137Z"
          fill="#7C7D7D"
        />
      </g>
      <defs>
        <clipPath id="clip0_42_5486">
          <rect
            width="20"
            height="20.0056"
            fill="white"
            transform="translate(0 0.027832)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
function Paid() {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_42_5506)">
        <path
          d="M10.0042 0.0222168C4.48302 0.0222168 0.00695801 4.50105 0.00695801 10.025C0.00695801 15.5489 4.48302 20.0278 10.0042 20.0278C15.5253 20.0278 20.0014 15.5489 20.0014 10.025C20 4.50105 15.524 0.0222168 10.0042 0.0222168ZM13.2131 13.6336C12.9466 14.0819 12.5496 14.4428 12.025 14.7148C11.6281 14.9202 11.1645 15.0479 10.6315 15.0979V16.4705H9.24221V15.059C8.84804 14.9979 8.48302 14.8938 8.14853 14.7439C7.58503 14.4913 7.14228 14.1277 6.81751 13.6558C6.49273 13.1839 6.32618 12.6093 6.31646 11.9306H8.24846C8.26789 12.3983 8.43721 12.7925 8.75644 13.1117C9.07566 13.431 9.51702 13.592 10.0805 13.592C10.5663 13.592 10.9535 13.4754 11.2394 13.2408C11.5253 13.0076 11.6683 12.694 11.6683 12.3026C11.6683 11.8918 11.542 11.5725 11.2894 11.3435C11.0368 11.1145 10.7009 10.9285 10.2804 10.7856C9.86123 10.6426 9.41292 10.4899 8.93548 10.3276C8.1624 10.0611 7.57393 9.71687 7.16865 9.29633C6.76338 8.87579 6.56074 8.31784 6.56074 7.62111C6.55102 7.02985 6.69121 6.52187 6.98267 6.09716C7.27275 5.67246 7.67108 5.34352 8.17767 5.10896C8.50106 4.95907 8.85637 4.85775 9.24221 4.80362V3.44068H10.6315V4.80639C11.0146 4.86191 11.3671 4.96462 11.6891 5.11451C12.1999 5.35324 12.5996 5.68773 12.8911 6.1166C13.1825 6.54546 13.3366 7.05622 13.356 7.64886H11.3963C11.3866 7.29633 11.2505 6.98266 10.9882 6.71063C10.7259 6.43859 10.3609 6.30258 9.89315 6.30258C9.49204 6.29286 9.15616 6.3914 8.88413 6.59543C8.61209 6.80084 8.47608 7.09925 8.47608 7.49064C8.47608 7.82513 8.58156 8.09022 8.79114 8.28453C9.00071 8.48023 9.28662 8.6454 9.64887 8.77864C10.0111 8.91188 10.4261 9.05483 10.8938 9.2075C11.3893 9.37961 11.8432 9.57947 12.2526 9.80848C12.6621 10.0375 12.991 10.3401 13.2394 10.7176C13.4879 11.0951 13.6114 11.5795 13.6114 12.1707C13.6128 12.6981 13.4795 13.1853 13.2131 13.6336Z"
          fill="#7C7D7D"
        />
      </g>
      <defs>
        <clipPath id="clip0_42_5506">
          <rect
            width="20"
            height="20.0056"
            fill="white"
            transform="translate(0 0.0222168)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
function Play() {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_42_5503)">
        <path
          d="M10 0.020813C4.48163 0.020813 0.00695801 4.49548 0.00695801 10.0139C0.00695801 15.5323 4.48163 20.0069 10 20.0069C15.5184 20.0069 19.9931 15.5323 19.9931 10.0139C19.9931 4.49548 15.5184 0.020813 10 0.020813ZM13.2325 10.8563L8.34422 13.6502C7.49898 14.1332 6.80779 13.7321 6.80779 12.7592V7.19778C6.80779 6.22484 7.49898 5.82373 8.34422 6.30673L13.2339 9.10062C14.0777 9.58362 14.0777 10.3733 13.2325 10.8563Z"
          fill="#7C7D7D"
        />
      </g>
      <defs>
        <clipPath id="clip0_42_5503">
          <rect
            width="20"
            height="20.0056"
            fill="white"
            transform="translate(0 0.0111084)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
function Playlist() {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_42_5509)">
        <path
          d="M19.0146 0.0541382C18.4774 0.0541382 18.043 0.488559 18.043 1.02569V11.6114C17.281 11.0645 16.347 10.7412 15.3366 10.7412C12.7689 10.7412 10.687 12.823 10.687 15.3907C10.687 17.9584 12.7689 20.0403 15.3366 20.0403C17.8584 20.0403 19.9084 18.0333 19.9819 15.5295C19.9833 15.5059 19.9861 15.4837 19.9861 15.4601V1.02569C19.9861 0.488559 19.5517 0.0541382 19.0146 0.0541382Z"
          fill="#7C7D7D"
        />
        <path
          d="M0.971547 1.99723H14.018C14.5552 1.99723 14.9896 1.56281 14.9896 1.02569C14.9896 0.488559 14.5552 0.0541382 14.018 0.0541382H0.971547C0.434421 0.0541382 0 0.488559 0 1.02569C0 1.56281 0.434421 1.99723 0.971547 1.99723Z"
          fill="#7C7D7D"
        />
        <path
          d="M7.77238 9.07568H0.971547C0.43442 9.07568 0 9.5101 0 10.0472C0 10.5844 0.43442 11.0188 0.971547 11.0188H7.77238C8.30951 11.0188 8.74393 10.5844 8.74393 10.0472C8.74393 9.5101 8.30951 9.07568 7.77238 9.07568Z"
          fill="#7C7D7D"
        />
        <path
          d="M5.27412 18.0972H0.971547C0.434421 18.0972 0 18.5316 0 19.0687C0 19.6058 0.434421 20.0403 0.971547 20.0403H5.27412C5.81124 20.0403 6.24566 19.6058 6.24566 19.0687C6.24566 18.5316 5.81124 18.0972 5.27412 18.0972Z"
          fill="#7C7D7D"
        />
      </g>
      <defs>
        <clipPath id="clip0_42_5509">
          <rect
            width="20"
            height="20.0056"
            fill="white"
            transform="translate(0 0.0444336)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
function SocialFacebook() {
  return (
    <>
      <svg
        width="36"
        height="37"
        viewBox="0 0 36 37"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="18" cy="18.026" r="18" fill="white" fill-opacity="0.1" />
        <g clip-path="url(#clip0_42_3071)">
          <path
            d="M19.51 27.776V18.8818H22.4943L22.942 15.4145H19.51V13.2012C19.51 12.1976 19.7876 11.5137 21.2283 11.5137L23.0628 11.513V8.41175C22.7455 8.37052 21.6565 8.276 20.389 8.276C17.7423 8.276 15.9303 9.89154 15.9303 12.8578V15.4145H12.937V18.8818H15.9303V27.776H19.51Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_42_3071">
            <rect
              width="19.5"
              height="19.5"
              fill="white"
              transform="translate(8.25 8.276)"
            />
          </clipPath>
        </defs>
      </svg>
    </>
  );
}
function SocialGoogle() {
  return (
    <>
      <svg
        width="36"
        height="37"
        viewBox="0 0 36 37"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="18" cy="18.026" r="18" fill="white" fill-opacity="0.1" />
        <g clip-path="url(#clip0_42_3062)">
          <path
            d="M27.0421 16.021L19.3942 16.0206C19.0565 16.0206 18.7827 16.2943 18.7827 16.6321V19.0752C18.7827 19.4129 19.0565 19.6867 19.3941 19.6867H23.701C23.2294 20.9106 22.3492 21.9356 21.2261 22.5868L23.0626 25.7659C26.0085 24.0621 27.7501 21.0728 27.7501 17.7264C27.7501 17.25 27.715 16.9094 27.6447 16.5258C27.5913 16.2344 27.3384 16.021 27.0421 16.021Z"
            fill="white"
          />
          <path
            d="M18.3751 23.3575C16.2674 23.3575 14.4274 22.2059 13.4392 20.5018L10.2603 22.3341C11.878 25.1379 14.9085 27.026 18.3751 27.026C20.0757 27.026 21.6803 26.5681 23.0626 25.7702V25.7658L21.2261 22.5867C20.3861 23.074 19.4141 23.3575 18.3751 23.3575Z"
            fill="white"
          />
          <path
            d="M23.0625 25.7702V25.7659L21.2261 22.5868C20.386 23.074 19.4141 23.3576 18.375 23.3576V27.026C20.0756 27.026 21.6803 26.5682 23.0625 25.7702Z"
            fill="white"
          />
          <path
            d="M12.6685 17.651C12.6685 16.6121 12.952 15.6402 13.4391 14.8002L10.2602 12.9679C9.45784 14.3458 9 15.9461 9 17.651C9 19.356 9.45784 20.9562 10.2602 22.3342L13.4391 20.5019C12.952 19.6619 12.6685 18.6899 12.6685 17.651Z"
            fill="white"
          />
          <path
            d="M18.3751 11.9445C19.7495 11.9445 21.012 12.4329 21.9981 13.2452C22.2414 13.4456 22.5949 13.4312 22.8178 13.2083L24.5489 11.4772C24.8017 11.2244 24.7837 10.8105 24.5136 10.5762C22.8614 9.14293 20.7118 8.276 18.3751 8.276C14.9085 8.276 11.878 10.1641 10.2603 12.9679L13.4392 14.8002C14.4274 13.0961 16.2674 11.9445 18.3751 11.9445Z"
            fill="white"
          />
          <path
            d="M21.998 13.2452C22.2413 13.4456 22.5949 13.4312 22.8177 13.2083L24.5488 11.4772C24.8016 11.2244 24.7836 10.8105 24.5135 10.5762C22.8613 9.1429 20.7117 8.276 18.375 8.276V11.9445C19.7494 11.9445 21.0119 12.4329 21.998 13.2452Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_42_3062">
            <rect
              width="18.75"
              height="18.75"
              fill="white"
              transform="translate(9 8.276)"
            />
          </clipPath>
        </defs>
      </svg>
    </>
  );
}
function Tiktok() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="matrix(0.5625 0 0 0.5625 0 0)">
        <path
          d="M16.708 0.027C 18.453001 0 20.188 0.016 21.921 0C 22.026 2.041 22.76 4.12 24.254 5.563C 25.744999 7.0420003 27.854 7.719 29.906 7.948L29.906 7.948L29.906 13.316999C 27.983 13.254 26.051 12.853999 24.306 12.025999C 23.546 11.681999 22.838 11.238999 22.145 10.785999C 22.136 14.681999 22.161001 18.573 22.12 22.453C 22.016 24.317 21.401001 26.171999 20.317001 27.708C 18.573002 30.265 15.546001 31.932 12.437001 31.984001C 10.530002 32.093002 8.625001 31.573002 7.0000014 30.615002C 4.307001 29.027002 2.4120016 26.120003 2.1360016 23.000002C 2.1040015 22.333002 2.0930016 21.667002 2.1200016 21.016003C 2.3600016 18.479002 3.6150017 16.052002 5.5630016 14.401003C 7.771002 12.478003 10.864002 11.562003 13.760001 12.104003C 13.787002 14.079003 13.708001 16.052002 13.708001 18.027002C 12.385001 17.599003 10.839001 17.719002 9.683001 18.522003C 8.839001 19.069004 8.198001 19.907003 7.8640003 20.855003C 7.5880003 21.531004 7.6670003 22.282003 7.6830006 23.000004C 8.000001 25.188004 10.104 27.027004 12.35 26.828003C 13.839001 26.812002 15.266001 25.948004 16.042 24.683002C 16.293 24.240002 16.574 23.787003 16.589 23.266003C 16.720001 20.881002 16.668001 18.506002 16.684 16.121002C 16.695 10.746002 16.668 5.3860025 16.709 0.028001785L16.709 0.028001785L16.708 0.027z"
          stroke="none"
          fill-rule="nonzero"
          fill="#7C7D7D"
        />
      </g>
    </svg>
  );
}
function Twitter() {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 0.00976562C4.48163 0.00976562 0.00695801 4.48444 0.00695801 10.0028C0.00695801 15.5212 4.48163 19.9959 10 19.9959C15.5184 19.9959 19.9931 15.5212 19.9931 10.0028C19.9931 4.48444 15.5184 0.00976562 10 0.00976562ZM16.2193 5.97507C16.1527 6.27625 16.0153 6.8217 15.8515 7.07292C15.4435 7.70026 15.202 8.05834 15.0757 8.24155C14.9771 11.6531 12.3123 15.1118 7.90841 15.1118C6.53714 15.1118 5.20196 14.7259 4.04721 13.9973C3.80571 13.8446 3.70023 13.5448 3.79599 13.2742C3.89037 13.0049 4.15824 12.8356 4.44415 12.8675C5.17698 12.948 5.94728 12.837 6.61626 12.533C5.63777 11.8349 4.21237 10.5066 4.21237 8.69956C4.21237 6.61351 4.55519 5.76965 4.59405 5.67944C4.6801 5.48374 4.86053 5.34773 5.0715 5.31719C5.28385 5.28943 5.49481 5.36993 5.63083 5.5351C6.54408 6.63711 7.83208 7.37132 9.23943 7.60727C9.32965 6.0972 10.6024 4.89665 12.1541 4.89665C12.8425 4.89665 13.5101 5.13954 14.0333 5.57396C14.1069 5.56285 14.1832 5.55314 14.2609 5.54342C14.6343 5.49484 15.1006 5.43378 15.3102 5.31025C15.5212 5.18673 15.7849 5.19922 15.982 5.34356C16.1791 5.48929 16.272 5.73634 16.2193 5.97507Z"
        fill="#7C7D7D"
      />
    </svg>
  );
}
function Viewers() {
  return (
    <svg
      width="15"
      height="12"
      viewBox="0 0 15 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4 6.00375C4 7.65579 5.34418 9 7.00375 9C8.65578 9 10 7.65579 10 6.00375C10 4.34418 8.65578 3 7.00375 3C5.34418 3 4 4.34418 4 6.00375ZM11.8024 1.53454C13.0829 2.52364 14.1732 3.97091 14.9561 5.78183C15.0146 5.91998 15.0146 6.08002 14.9561 6.2109C13.3903 9.83272 10.6024 12 7.5 12H7.49265C4.39756 12 1.60975 9.83272 0.0439031 6.2109C-0.0146344 6.08002 -0.0146344 5.91998 0.0439031 5.78183C1.60975 2.16 4.39756 0 7.49265 0H7.5C9.05122 0 10.522 0.538185 11.8024 1.53454ZM7.00405 8C8.10226 8 9 7.10225 9 6.00404C9 4.89781 8.10226 4 7.00405 4C6.90783 4 6.81162 4.00802 6.72345 4.02406C6.69141 4.90583 5.96992 5.61122 5.08016 5.61122H5.04008C5.01603 5.73951 5 5.86773 5 6.00404C5 7.10225 5.89778 8 7.00405 8Z"
        fill="white"
      />
    </svg>
  );
}

const ArrowDown = () => (
  <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.00002 0.00683594C3.13715 0.00683594 0.00488281 3.13911 0.00488281 7.00198C0.00488281 10.8649 3.13715 13.9971 7.00002 13.9971C10.8629 13.9971 13.9952 10.8649 13.9952 7.00198C13.9952 3.13911 10.8629 0.00683594 7.00002 0.00683594ZM10.1041 6.6085L7.48094 9.23168C7.34784 9.36478 7.17393 9.43085 7.00002 9.43085C6.82612 9.43085 6.65221 9.36478 6.51911 9.23168L3.89593 6.6085C3.6307 6.34327 3.6307 5.9119 3.89593 5.64667C4.16116 5.38144 4.59253 5.38144 4.85776 5.64667L7.00002 7.78893L9.14229 5.64667C9.40752 5.38144 9.83889 5.38144 10.1041 5.64667C10.3694 5.9119 10.3694 6.34327 10.1041 6.6085Z"
      fill="#7C7D7D"
    />
  </svg>
);
const ArrowLeft = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.9861 9.99306C19.9861 4.47467 15.5114 0 9.99306 0C4.47467 0 0 4.47467 0 9.99306C0 15.5114 4.47467 19.9861 9.99306 19.9861C15.5114 19.9861 19.9861 15.5114 19.9861 9.99306ZM10.5552 14.4275L6.80777 10.6801C6.61763 10.4899 6.52325 10.2415 6.52325 9.99306C6.52325 9.74462 6.61763 9.49618 6.80777 9.30604L10.5552 5.55864C10.9341 5.17974 11.5503 5.17974 11.9292 5.55864C12.3081 5.93754 12.3081 6.55378 11.9292 6.93269L8.86884 9.99306L11.9292 13.0534C12.3081 13.4323 12.3081 14.0486 11.9292 14.4275C11.5503 14.8064 10.9341 14.8064 10.5552 14.4275Z"
      fill="#D9E6EC"
    />
  </svg>
);
const ArrowRight = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 9.99306C0 15.5114 4.47467 19.9861 9.99306 19.9861C15.5114 19.9861 19.9861 15.5114 19.9861 9.99306C19.9861 4.47467 15.5114 0 9.99306 0C4.47467 0 0 4.47467 0 9.99306ZM9.43095 5.55864L13.1783 9.30604C13.3685 9.49618 13.4629 9.74462 13.4629 9.99306C13.4629 10.2415 13.3685 10.4899 13.1783 10.6801L9.43095 14.4275C9.05205 14.8064 8.43581 14.8064 8.0569 14.4275C7.678 14.0486 7.678 13.4323 8.0569 13.0534L11.1173 9.99306L8.0569 6.93269C7.678 6.55378 7.678 5.93754 8.0569 5.55864C8.43581 5.17974 9.05205 5.17974 9.43095 5.55864Z"
      fill="#D9E6EC"
    />
  </svg>
);
const Calendar = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.1602 0.00976562H0.839591C0.381576 0.00976562 0.00683594 0.384505 0.00683594 0.842521V4.17354C0.00683594 4.63156 0.381576 5.0063 0.839591 5.0063H19.1602C19.6182 5.0063 19.993 4.63156 19.993 4.17354V0.842521C19.993 0.384505 19.6182 0.00976562 19.1602 0.00976562Z"
      fill="#7C7D7D"
    />
    <path
      d="M19.1602 7.50439H0.839591C0.381576 7.50439 0.00683594 7.87913 0.00683594 8.33715V19.163C0.00683594 19.621 0.381576 19.9957 0.839591 19.9957H19.1602C19.6182 19.9957 19.993 19.621 19.993 19.163V8.33715C19.993 7.87913 19.6182 7.50439 19.1602 7.50439ZM16.2456 16.2483H11.249V11.2518H16.2456V16.2483Z"
      fill="#7C7D7D"
    />
  </svg>
);
const OptionDots = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.9999 0.00976562C4.48151 0.00976562 0.00683594 4.48444 0.00683594 10.0028C0.00683594 15.5212 4.48151 19.9959 9.9999 19.9959C15.5183 19.9959 19.993 15.5212 19.993 10.0028C19.993 4.48444 15.5183 0.00976562 9.9999 0.00976562ZM5.00337 11.252C4.31357 11.252 3.75423 10.6926 3.75423 10.0028C3.75423 9.31303 4.31357 8.75369 5.00337 8.75369C5.69316 8.75369 6.2525 9.31303 6.2525 10.0028C6.2525 10.6926 5.69316 11.252 5.00337 11.252ZM9.9999 11.252C9.3101 11.252 8.75076 10.6926 8.75076 10.0028C8.75076 9.31303 9.3101 8.75369 9.9999 8.75369C10.6897 8.75369 11.249 9.31303 11.249 10.0028C11.249 10.6926 10.6897 11.252 9.9999 11.252ZM14.9964 11.252C14.3066 11.252 13.7473 10.6926 13.7473 10.0028C13.7473 9.31303 14.3066 8.75369 14.9964 8.75369C15.6862 8.75369 16.2456 9.31303 16.2456 10.0028C16.2456 10.6926 15.6862 11.252 14.9964 11.252Z"
      fill="#E0E0E0"
    />
  </svg>
);
const Search = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.7082 18.3374L16.4314 15.0605C16.2856 14.9148 16.2676 14.6857 16.3897 14.5206C17.6097 12.8842 18.294 10.8259 18.176 8.60802C17.9331 4.03758 14.2634 0.323496 9.69576 0.0292559C4.21206 -0.323277 -0.326451 4.21524 0.026082 9.69754C0.320322 14.2652 4.03441 17.9349 8.60485 18.1778C10.8241 18.2957 12.881 17.6115 14.5174 16.3915C14.6826 16.2694 14.9116 16.2874 15.0573 16.4331L18.3342 19.71C18.5243 19.9002 18.7728 19.9946 19.0212 19.9946C19.2697 19.9946 19.5181 19.9002 19.7082 19.71C20.0872 19.3325 20.0872 18.7163 19.7082 18.3374ZM1.96501 9.56846C1.68882 5.25896 5.25578 1.69199 9.56529 1.96819C13.1267 2.19581 16.0025 5.07297 16.2301 8.63439C16.5049 12.9425 12.9393 16.5095 8.62983 16.2347C5.0698 16.0057 2.19263 13.1299 1.96501 9.56846Z"
      fill="#F2F2F2"
    />
  </svg>
);
const Followers = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_46_6214)">
      <path
        d="M7.67233 12.7483C9.75144 12.7483 11.4364 10.6387 11.4364 8.56093C11.4364 6.4832 9.75144 4.79688 7.67233 4.79688C5.59322 4.79688 3.90967 6.48182 3.90967 8.56093C3.90967 10.64 5.59461 12.7483 7.67233 12.7483Z"
        fill="#828282"
      />
      <path
        d="M11.2186 13.3432C11.0035 13.2211 10.7342 13.2336 10.5329 13.3779C9.69742 13.9761 8.71893 14.3398 7.67382 14.3398C6.61206 14.3398 5.62108 13.9664 4.77861 13.3516C4.58014 13.2072 4.31782 13.1809 4.10269 13.2988C1.94308 14.4883 0.388604 16.6951 0.0541143 19.0976C-0.0166699 19.6083 0.378889 20.0636 0.895197 20.0636H14.3456C14.8605 20.0636 15.2574 19.6083 15.1867 19.099C14.8563 16.7256 13.3352 14.5438 11.2186 13.3432Z"
        fill="#828282"
      />
      <path
        d="M18.9827 2.85398H17.1784V1.04967C17.1784 0.512546 16.744 0.078125 16.2069 0.078125C15.6697 0.078125 15.2353 0.512546 15.2353 1.04967V2.85398H13.431C12.8939 2.85398 12.4595 3.2884 12.4595 3.82552C12.4595 4.36265 12.8939 4.79707 13.431 4.79707H15.2353V6.60137C15.2353 7.1385 15.6697 7.57292 16.2069 7.57292C16.744 7.57292 17.1784 7.1385 17.1784 6.60137V4.79707H18.9827C19.5199 4.79707 19.9543 4.36265 19.9543 3.82552C19.9543 3.2884 19.5199 2.85398 18.9827 2.85398Z"
        fill="#828282"
      />
    </g>
    <defs>
      <clipPath id="clip0_46_6214">
        <rect
          width="20"
          height="20.0056"
          fill="white"
          transform="translate(0 0.0683594)"
        />
      </clipPath>
    </defs>
  </svg>
);
const Settings = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_46_6182)">
      <path
        d="M17.761 10.4251C17.768 10.2877 17.7721 10.1475 17.7721 10.0087C17.7721 9.8699 17.768 9.72972 17.761 9.59231L19.4432 7.69502C19.6958 7.4091 19.7472 6.90529 19.557 6.57496L17.7527 3.44935C17.5612 3.11903 17.1004 2.91084 16.7256 2.98717L14.2413 3.49515C14.0095 3.34387 13.7694 3.20508 13.5209 3.07878L12.7173 0.673502C12.5966 0.311253 12.1857 0.015625 11.8041 0.015625H8.19546C7.81378 0.015625 7.40295 0.311253 7.2822 0.673502L6.47998 3.07878C6.23154 3.20508 5.99143 3.34387 5.75965 3.49515L3.27526 2.98717C2.90191 2.91084 2.43973 3.11903 2.2482 3.44935L0.443894 6.57496C0.252361 6.90529 0.303714 7.4091 0.557704 7.69502L2.23987 9.59231C2.23293 9.72972 2.22877 9.8699 2.22877 10.0087C2.22877 10.1475 2.23293 10.2877 2.23987 10.4251L0.556316 12.3224C0.303714 12.6083 0.252361 13.1121 0.442506 13.4424L2.24681 16.568C2.43834 16.8983 2.89913 17.1065 3.27387 17.0302L5.75826 16.5222C5.99004 16.6735 6.23015 16.8123 6.47859 16.9386L7.28081 19.3439C7.40156 19.7061 7.81239 20.0018 8.19407 20.0018H11.8027C12.1844 20.0018 12.5952 19.7061 12.7159 19.3439L13.5181 16.9386C13.7666 16.8123 14.0067 16.6735 14.2385 16.5222L16.7229 17.0302C17.0962 17.1065 17.5584 16.8983 17.7499 16.568L19.5542 13.4424C19.7458 13.1121 19.6944 12.6083 19.4404 12.3224L17.761 10.4251ZM9.99976 12.507C8.62016 12.507 7.50149 11.3883 7.50149 10.0087C7.50149 8.62909 8.62016 7.51042 9.99976 7.51042C11.3794 7.51042 12.498 8.62909 12.498 10.0087C12.498 11.3883 11.3794 12.507 9.99976 12.507Z"
        fill="#7C7D7D"
      />
    </g>
    <defs>
      <clipPath id="clip0_46_6182">
        <rect
          width="20"
          height="20.0056"
          fill="white"
          transform="translate(0 0.00585938)"
        />
      </clipPath>
    </defs>
  </svg>
);
function FitnessIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="matrix(0.5625 0 0 0.5625 0 0)">
        <path
          d="M16.708 0.027C 18.453001 0 20.188 0.016 21.921 0C 22.026 2.041 22.76 4.12 24.254 5.563C 25.744999 7.0420003 27.854 7.719 29.906 7.948L29.906 7.948L29.906 13.316999C 27.983 13.254 26.051 12.853999 24.306 12.025999C 23.546 11.681999 22.838 11.238999 22.145 10.785999C 22.136 14.681999 22.161001 18.573 22.12 22.453C 22.016 24.317 21.401001 26.171999 20.317001 27.708C 18.573002 30.265 15.546001 31.932 12.437001 31.984001C 10.530002 32.093002 8.625001 31.573002 7.0000014 30.615002C 4.307001 29.027002 2.4120016 26.120003 2.1360016 23.000002C 2.1040015 22.333002 2.0930016 21.667002 2.1200016 21.016003C 2.3600016 18.479002 3.6150017 16.052002 5.5630016 14.401003C 7.771002 12.478003 10.864002 11.562003 13.760001 12.104003C 13.787002 14.079003 13.708001 16.052002 13.708001 18.027002C 12.385001 17.599003 10.839001 17.719002 9.683001 18.522003C 8.839001 19.069004 8.198001 19.907003 7.8640003 20.855003C 7.5880003 21.531004 7.6670003 22.282003 7.6830006 23.000004C 8.000001 25.188004 10.104 27.027004 12.35 26.828003C 13.839001 26.812002 15.266001 25.948004 16.042 24.683002C 16.293 24.240002 16.574 23.787003 16.589 23.266003C 16.720001 20.881002 16.668001 18.506002 16.684 16.121002C 16.695 10.746002 16.668 5.3860025 16.709 0.028001785L16.709 0.028001785L16.708 0.027z"
          stroke="none"
          fill-rule="nonzero"
          fill="#7C7D7D"
        />
      </g>
    </svg>
  );
}


const iconNames = {
  Followers: <Followers />,
  Settings: <Settings />,
  ArrowDown: <ArrowDown />,
  ArrowLeft: <ArrowLeft />,
  ArrowRight: <ArrowRight />,
  Calendar: <Calendar />,
  OptionDots: <OptionDots />,
  Search: <Search />,
  Facebook: <Facebook />,
  Help: <Help />,
  Home: <Home />,
  Instagram: <Instagram />,
  Logout: <Logout />,
  Paid: <Paid />,
  Play: <Play />,
  Playlist: <Playlist />,
  SocialFacebook: <SocialFacebook />,
  SocialGoogle: <SocialGoogle />,
  Tiktok: <Tiktok />,
  Twitter: <Twitter />,
  Viewers: <Viewers />,
  Live: <Live />,
  Verified: <Verified />,
  Follower: <Follower />,
  FitnessIcon: <FitnessIcon />,
};

function SvgIcon(props) {
  return iconNames[props.iconName];

  // return false;
}

export default SvgIcon;
