// workout1.js

const workout1Data = {
  description: `The below is just one example of what a 3-day split workout could look like. The 3-day split may be ideal for someone that does not have a lot of time during the week but still wants to get a workout in. Stream your 3-day fitness workouts at bahstream.com. Please feel free to adjust the below as needed, such as incorporating stretches or cardio into your workouts. Also, check out our 6-day split workout for alternative exercises. Always remember to contact your health care provider before starting any workouts or exercises.`,

  day1: {
    title: "Day 1 – Push A – Back Biceps Abs",
    rows: 9,
    columns: 4,
    data: [
      ["Exercise", "Sets", "Reps", "Muscle"],
      ["Lat Pulldown", 4, "6-8", "Back"],
      ["Cable Row", 4, "6-8", "Back"],
      ["Pullups or Bench Row", 3, "6-8", "Back"],
      ["Reverse Curls", 4, "6-8", "Biceps"],
      ["Hammer Curls", 3, "8-10", "Biceps"],
      ["Barbell Curls", 3, "8-10", "Biceps"],
      ["Plank", "3", "To Failure", "Abs"],
      ["Leg Raises", "3", "To Failure", "Abs"],
    ],
  },

  day2: {
    title: "Day 2 – Pull A – Chest Shoulders Triceps",
    rows: 9,
    columns: 4,
    data: [
      ["Exercise", "Sets", "Reps", "Muscle"],
      ["Barbell Bench Press", 3, "6-8", "Chest"],
      ["Incline Dumbbell Press", 4, "6-8", "Chest"],
      ["Machine Chest Press", 4, "6-8", "Chest"],
      ["Dumbbell Front Raises", 4, "8-10", "Shoulders"],
      ["Standing Overhead Press", 3, "6-8", "Shoulders"],
      ["Seated Shoulder Press Machine", 4, "6-8", "Shoulders"],
      ["Seated Triceps Extension", 4, "6-8", "Triceps"],
      ["Triceps Rope Pushdown", 3, "6-8", "Triceps"],
    ],
  },  

  day3: {
    title: "Day 3 – Legs",
    rows: 4, // Adjusted rows count
    columns: 7, // Adjusted columns count
    data: [
      ["Exercise", "Sets", "Reps", "Muscle"],
      ["Leg Extensions", 3, "6-8", "Legs"],
      ["Leg Curls", 4, "6-8", "Legs"],
      ["Leg Press", 4, "6-8", "Legs"],
      ["Forward Lunges", 4, "8-10", "Legs"],
      ["Squats", 3, "6-8", "Legs"],
      ["Standing Calf Raises", 3, "8-10", "Legs"],
    ],
  },
  
};

export default workout1Data;
