import axios from "axios";
import store from "../store/store";
import { LOGOUT } from "../actions/types";
import { baseUrl } from "./base_url.js";

const api = axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-Type": "application/json",

  },
});

// Request Interceptor
api.interceptors.request.use(request => {
    console.log('Starting Request:', request);
    return request;
});

// Response Interceptor
api.interceptors.response.use(
  (res) => {
    console.log("Response received:", res);
    return res;
  },
  (err) => {
    console.log("Error response received:", err);

    if (err?.response?.status === 401) {
      console.log("Token expired. Logging out.");
      store.dispatch({ type: LOGOUT });
    } else {
      console.log("Other error occurred.");
    }

    return Promise.reject(err);
  }
);

export default api;

// import axios from "axios";
// import store from "../store/store";
// import { LOGOUT } from "../actions/types";
// import { baseUrl } from "./base_url.js";

// const api = axios.create({
//   baseURL: baseUrl,
//   headers: {
//     "Content-Type": "application/json",
//     "Access-Control-Allow-Origin": "*",
//     "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
//   },
// });

// // Add a console.log statement to check if the interceptor is triggered
// api.interceptors.response.use(
//   (res) => {
//     console.log("Response received:", res);
//     return res;
//   },
//   (err) => {
//     console.log("Error response received:", err);

//     if (err?.response?.status === 401) {
//       console.log("Token expired. Logging out.");
//       store.dispatch({ type: LOGOUT });
//     } else {
//       console.log("Other error occurred.");
//     }

//     return Promise.reject(err);
//   }
// );

// export default api;

