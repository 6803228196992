import React from "react";

import ReactPaginate from "react-paginate";
import SvgIcon from "./SvgIcon";

const Pagination = ({ total, perPage, handlePageClick }) => {
  return (
    <ul className="pagination">
      <ReactPaginate
        // containerClassName="Pagination"
        pageClassName="paginate_button page-item"
        pageLinkClassName="page-link"
        // activeClassName="active"
        previousClassName="page-item previous"
        previousLinkClassName="page-link"
        nextClassName=" page-item next"
        nextLinkClassName="page-link"
        activeLinkClassName="active"
        previousLabel={<SvgIcon iconName="ArrowLeft" />}
        nextLabel={<SvgIcon iconName="ArrowRight" />}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={total ? total : null}
        marginPagesDisplayed={2}
        pageRangeDisplayed={perPage ? perPage : null}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
      />
    </ul>
  );
}

export default Pagination;