// workout5.js

const workout5Data = {
    description: `The below is just one example of what a 6-day split workout could look like. The 6-day split may be ideal for hitting similar body parts together while also hitting those same body parts twice a week. Live stream your 6-day fitness workouts at bahstream.com. Please feel free to adjust the below as needed, such as incorporating stretches or cardio into your workouts. Also check out our 3 – day split workout page for less exercises. Always remember to contact your health care provider before starting any workouts or exercises. .`,
  
    day1: {
        title: "Day 1 – Push A - Back Biceps Abs",
        rows: 10,
        columns: 4,
        data: [
            ["Exercise", "Sets", "Reps", "Muscle"],
            ["Lat Pulldowns", 4, "6-8", "Back"],
            ["Machine Row", 4, "6-8", "Back"],
            ["Pull Ups", 3, "6-8", "Back"],
            ["Reverse Curls", 4, "6-8", "Biceps"],
            ["Hammer Curls", 3, "8-10", "Biceps"],
            ["Barbell Curls", 3, "8-10", "Biceps"],
            ["Plank", 3, "To Failure", "Abs"],
            ["Leg Raises", 3, "To Failure", "Abs"],
        ],
    },

    day2: {
        title: "Day 2 – Pull A – Chest Shoulders Triceps",
        rows: 9,
        columns: 4,
        data: [
            ["Exercise", "Sets", "Reps", "Muscle"],
            ["Barbell Bench Press", 3, "6-8", "Chest"],
            ["Incline Dumbbell Press", 4, "6-8", "Chest"],
            ["Machine Chest Press", 4, "6-8", "Chest"],
            ["Dumbbell Front Raises", 4, "8-10", "Shoulders"],
            ["Standing Overhead Press", 3, "6-8", "Shoulders"],
            ["Seated Shoulder Press Machine", 4, "6-8", "Shoulders"],
            ["Seated Triceps Extension", 4, "6-8", "Triceps"],
            ["Triceps Rope Pushdown", 3, "6-8", "Triceps"],
        ],
    },

    day3: {
        title: "Day 3 - Legs",
        rows: 7,
        columns: 4,
        data: [
            ["Exercise", "Sets", "Reps", "Muscle"],
            ["Leg Extensions", 4, "6-8", "Legs"],
            ["Leg Curls", 4, "6-8", "Legs"],
            ["Leg Press", 3, "6-8", "Legs"],
            ["Hip Adductions", 4, "8-10", "Legs"],
            ["Squats", 3, "6-8", "Legs"],
            ["Standing Calf Raises", 4, "8-10", "Legs"],
        ],
    },

    day4: {
        title: "Day 4 – Push B - Back Biceps Abs",
        rows: 9,
        columns: 4,
        data: [
            ["Exercise", "Sets", "Reps", "Muscle"],
            ["Machine Pulldowns", 3, "6-8", "Back"],
            ["Cable Row", 3, "6-8", "Back"],
            ["Back Extensions", 3, "6-8", "Back"],
            ["Preacher Curls", 3, "6-8", "Biceps"],
            ["Dumbbell Curls", 3, "8-10", "Biceps"],
            ["Concentration Curls", 3, "8-10", "Biceps"],
            ["Crunches", 3, "To Failure", "Abs"],
            ["Flutter Kicks", 3, "To Failure", "Abs"],
        ],
    },
    day5: {
        title: "Day 5 – Pull B – Chest Shoulders Triceps",
        rows: 8,
        columns: 4,
        data: [
            ["Exercise", "Sets", "Reps", "Muscle"],
            ["Dumbbell Bench Press", 3, "6-8", "Chest"],
            ["Incline Barbell Press", 4, "6-8", "Chest"],
            ["Chest Fly’s", 4, "6-8", "Chest"],
            ["Dumbbell Side Raises", 4, "8-10", "Shoulders"],
            ["Seated Dumbbell Press", 3, "6-8", "Shoulders"],
            ["Shrugs", 4, "6-8", "Shoulders"],
            ["One Handed Triceps Extension", 4, "6-8", "Triceps"],
            ["Triceps V-bar Pushdown", 3, "6-8", "Triceps"],
        ],
    },

    day6: {
        title: "Day 6 – Legs",
        rows: 6,
        columns: 4,
        data: [
            ["Exercise", "Sets", "Reps", "Muscle"],
            ["Leg Extensions", 4, "6-8", "Legs"],
            ["Leg Curls", 4, "6-8", "Legs"],
            ["Deadlifts", 3, "6-8", "Legs"],
            ["Hip Abductions", 4, "8-10", "Legs"],
            ["Front Lunges", 3, "6-8", "Legs"],
            ["Sitting Calf Raises", 4, "8-10", "Legs"],
        ],
    },
};

    
  
  export default workout5Data;
  