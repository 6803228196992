
import {
    SUBSCRIBE_STREAMER,
    SUBSCRIBE_ERROR,
    CHECK_SUSBRIBED_CHANNEL,

   } from "../actions/types";
   
   const initialState = {
     isSubscribed: false,
     loading: true,
     error: {},
   };
   
   export default function (state = initialState, action) {
     const { type, payload } = action;
   
     switch (type) {
       case SUBSCRIBE_STREAMER:
         return {
           ...state,
           loading: false,
         };
       case CHECK_SUSBRIBED_CHANNEL: {
         console.log(payload)
         return {
           ...state,
           isSubscribed: payload.subscribed,
           loading: false,
         }; 
       }
   
       case SUBSCRIBE_ERROR: {
         return {
           ...state,
           Genres: [],
           loading: false,
         };
       }
   
       default:
         return state;
     }
   }
   