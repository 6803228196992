import ViewLoader from "../../Components/ViewLoader";
import useFetch from "../../hooks/useFetch.js";
import WithSidebar from "../layout/WithSidebar";
import React, { useCallback, useEffect, useState } from "react";

import TablePage from "../../Components/table/TablePage";
import { getSerial } from "../../utils/commonFunctions";
import moment from "moment";
import { ModalWrapper } from "../../Components/modal/ModalComponents.js";
import { baseUrl, liveUrl } from "../../utils/base_url.js";
import { ModalRight } from "../../Components/modal/ModalComponents";
import { ModalHeader, ModalBody } from "reactstrap";
import { connect } from "react-redux";

import api from "../../utils/api.js";
import {
  getStreams,
  deleteVideo,
  updateVideoDetail,
  getStreamDetailByIdAdmin,
  getAllVideosOfThisUser,
  toggleFeatured,
} from "../../actions/streamsActions.js";

const AdminStreams = ({
  getStreams,
  deleteVideo,
  updateVideoDetail,
  getStreamDetailByIdAdmin,
  getAllVideosOfThisUser,
  toggleFeatured,
  stream: { Streams, Stream, loading },
}) => {
  const [page, setPage] = useState(1);
  const [limit, setlimit] = useState(10);
  const [keyword, setKeyword] = useState("");
  const [showPalyer, setModelPlayer] = useState(false);
  const [selectedStream, setSlectedStream] = useState(null);
  const [modalDeletePost, setModalDeletePost] = useState(false);
  const [modalEditPost, setModalEditPost] = useState(false);

  const [refreshList, setRefreshList] = useState("");

  const toggleModalDeletePost = () => {
    setModalDeletePost(!modalDeletePost);
  };

  const toggleModalEditPost = () => {
    setModalEditPost(!modalEditPost);
  };

  useEffect(() => {
    getStreams(page, limit, keyword);
  }, [getStreams, refreshList, page, limit, keyword]);

  const refetchList = useCallback(() => {
    getStreams(page, limit);
  }, [page, refreshList, limit, getStreams]);

  const pagination = Streams?.pagination;

  const handlePageClick = (data) => {
    let selected = data.selected + 1;
    setPage(selected);
  };

  // const {
  //   data: streams,
  //   loading: streamLoading,
  //   error: streamError,
  // } = useFetch(
  //   `/streams/admin-videos?page=${page}&title[regex]=${
  //     keyword ?? ""
  //   }&title[options]=i&limit=${limit}`,
  //   [refreshList]
  // );
  return (
    <WithSidebar>
      <ModalWrapper
        isOpen={showPalyer}
        toggle={() => setModelPlayer(!showPalyer)}
        title={selectedStream?.title}
      >
        <video
          id="video-preview"
          src={`${baseUrl}/videos/${selectedStream?.stream_key}`}
          style={{ width: "100%", height: "100%" }}
          playsInline
          muted
          controls
          autoPlay
        ></video>
      </ModalWrapper>

      <ModalRight
        isOpen={modalDeletePost}
        toggle={toggleModalDeletePost}
        // className="modal-two-column"
      >
        <ModalHeader toggle={toggleModalDeletePost}>Heads Up!</ModalHeader>

        <ModalBody>
          <p>Are you sure wanna delete this Stream?</p>

          <button
            onClick={() => {
              deleteVideo(selectedStream?._id).then((val) => {
                toggleModalDeletePost();
                setRefreshList(selectedStream?._id);
              });
            }}
            data-dismiss="modal"
            type="button"
            class="btn btn-danger"
          >
            Delete
          </button>
        </ModalBody>
      </ModalRight>

      <ModalRight
        isOpen={modalEditPost}
        toggle={toggleModalEditPost}
        // className="modal-two-column"
      >
        <ModalHeader toggle={toggleModalEditPost}>Edit Stream</ModalHeader>

        <ModalBody>
          <EditStream
            getStream={getStreamDetailByIdAdmin}
            id={selectedStream?._id}
            loading={loading}
            modalPost={modalEditPost}
            toggleModalPost={toggleModalEditPost}
            refetchList={refetchList}
            stream={Stream}
            update
            toggleFeatured={toggleFeatured}
            updateVideoDetail={updateVideoDetail}
          />
        </ModalBody>
      </ModalRight>
      <TablePage
        title="Manage Streams"
        filters={[
          {
            name: "Search Streams",
            filterText: keyword,
            onChange: (e) => {
              // setCurrentPage(0)
              setKeyword(e.target.value);
            },
          },
        ]}
        limit={limit}
        setlimit={setlimit}
        pagination={Streams?.pagination}
        handlePageClick={handlePageClick}
      >
        <thead>
          <tr role="row">
            <th className="text-nowrap">SN</th>
            <th className="text-nowrap">Thumbnail</th>
            <th className="text-nowrap">Title</th>
            <th className="text-nowrap">Status</th>
            <th className="text-nowrap">Visiblity</th>
            <th className="text-nowrap">Created date</th>
            <th className="text-nowrap">Views</th>
            <th className="text-nowrap">Action</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <ViewLoader />
          ) : Streams && Streams.data?.length > 0 ? (
            Streams.data.map((item, index) => (
              <>
                <tr role="row" className="odd" key={index}>
                  <td className="text-nowrap">
                    {getSerial(Streams?.pagination, index)}
                  </td>
                  <td className="text-nowrap">
                    {item.thumbnail !== "" ? (
                      <img
                        src={item.thumbnail}
                        alt=""
                        style={{
                          maxHeight: "30px",
                          minWidth: "30px",
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </td>
                  <td
                    className="text-nowrap"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setSlectedStream(item);
                      setModelPlayer(!showPalyer);
                    }}
                  >
                    {item.title}
                  </td>
                  <td className="text-nowrap">
                    <span
                      className={
                        item.publishStatus == "Published"
                          ? "text-success"
                          : "text-warning"
                      }
                    >
                      {item.publishStatus}
                    </span>
                  </td>
                  <td className="text-nowrap">
                    <span>{item.visiblity.toLowerCase()}</span>
                  </td>
                  <td className="text-nowrap">
                    {moment(item && item.createdAt).format("DD/MM/YYYY")}
                  </td>
                  <td className="text-nowrap">{item.views}</td>
                  <td className="text-nowrap">
                    <button
                      style={{ marginRight: "20px" }}
                      onClick={() => {
                        setSlectedStream(item);
                        toggleModalDeletePost();
                      }}
                      className="btn btn-danger btn-sm"
                    >
                      delete
                    </button>
                    <button
                      onClick={() => {
                        setSlectedStream(item);
                        toggleModalEditPost();
                      }}
                      className="btn btn-secondary btn-sm"
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              </>
            ))
          ) : (
            <tr>
              <td colSpan="7">No category found.</td>
            </tr>
          )}
        </tbody>
      </TablePage>
    </WithSidebar>
  );
};

const EditStream = ({
  id, // for modal
  modalPost,
  toggleModalPost,
  getStream,
  loading,
  stream,
  updateVideoDetail,
  toggleFeatured,
  refetchList,
  update,
}) => {
  console.log("id", id);
  const [formData, setFormData] = useState({
    description: "",
    title: "",
    visiblity: "",
    updated: false,
    featured: false,
  });

  const UpdateThisForm = (e) => {
    e.preventDefault();
    Promise.all([
      formData.featured !== stream?.data?.featured
        ? toggleFeatured(id)
        : undefined,
      updateVideoDetail(id, formData),
    ]).then((_) => {
      toggleModalPost();
      refetchList();
    });
  };

  useEffect(() => {
    if (update && stream !== null) {
      setFormData({
        description: loading || stream?.data?.description,
        title: loading || !stream?.data?.title ? "" : stream?.data?.title,
        visiblity:
          loading || !stream?.data?.visiblity ? "" : stream?.data?.visiblity,
        featured:
          loading || !stream?.data?.featured ? "" : stream?.data?.featured,
      });
    } else {
      setFormData({ description: "", title: "", visiblity: "" });
    }
  }, [loading, stream, update]);

  useEffect(() => {
    update && getStream(id);
  }, [update, getStream, id, modalPost]);

  const SubmitAddCategoryForm = (e) => {
    e.preventDefault();
    // console.log("formData", formData);
    // createCategory(formData).then((e) => {
    //   console.log(e);
    //   toggleModalPost();
    //   refetchList();
    // });
  };

  return (
    <>
      <form
        onSubmit={(e) => {
          if (update) UpdateThisForm(e);
          else SubmitAddCategoryForm(e);
        }}
      >
        <div className="form-row">
          <div className="form-group col-md-12">
            <label htmlFor="inputState">Visiblity</label>
            <select
              id="inputState"
              className="form-control"
              value={formData.visiblity}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  visiblity: e.target.value,
                })
              }
            >
              <option value="PRIVATE">Private</option>
              <option value="PUBLIC">Public</option>
            </select>
          </div>
          {/* <div class="form-group col-md-12 custom-control custom-switch">
            <input
              type="checkbox"
              class="custom-control-input"
              id="customSwitches"
              checked={formData.featured}
              onChange={(val) => {
                setFormData({
                  ...formData,
                  featured: !formData.featured,
                });
              }}
            />
            <label
              style={{ marginLeft: "30px" }}
              class="custom-control-label"
              for="customSwitches"
            >
              Featured Stream
            </label>
          </div> */}
          <div className="form-group col-md-12">
            <label htmlFor="inputState">Stream Title</label>
            <input
              type="text"
              value={formData.title}
              onChange={(e) =>
                setFormData({ ...formData, title: e.target.value })
              }
              className="form-control"
              placeholder="Stream title"
            />
          </div>
          <div className="form-group col-md-12">
            <label htmlFor="inputState">Description</label>
            <input
              type="text"
              value={formData.description}
              onChange={(e) =>
                setFormData({ ...formData, description: e.target.value })
              }
              className="form-control"
              placeholder="Stream Description"
            />
          </div>
        </div>
        <button
          type="submit"
          className="btn btn-danger"
          // data-dismiss="modal"
        >
          Save
        </button>
      </form>
    </>
  );
};

const mapStateToProps = (state) => ({
  stream: state.streams,
});
export default connect(mapStateToProps, {
  getAllVideosOfThisUser,
  updateVideoDetail,
  deleteVideo,
  toggleFeatured,
  getStreamDetailByIdAdmin,
  getStreams,
})(AdminStreams);
