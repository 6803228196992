import {
  ITEMS_ERROR,
  CREATE_ITEM,
  GET_ITEMS,
  GET_ITEMS_BY_ID,
  UPDATE_ITEMS,
  DELETE_ITEMS,
} from "../actions/types";

const initialState = {
  Items: [],
  Item: null,
  loading: true,
  error: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ITEMS:
      return {
        ...state,
        Items: payload,
        loading: false,
      };
    case DELETE_ITEMS:
      return {
        ...state,
        Items: {
          ...state.Items,
          data: state.Items.data.filter((elem, i) => elem._id !== payload.id),
        },
        loading: false,
      };
    case CREATE_ITEM:
      console.log("Add Category Payload", payload);
      return {
        ...state,
        Items: {
          ...state.Items,
          data: [...state.Items.data, payload],
        },
        // Categorys: [payload],
        loading: false,
      };
    case GET_ITEMS_BY_ID: {
      return {
        ...state,
        Item: payload,
        loading: false,
      };
    }

    case ITEMS_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }

    default:
      return state;
  }
}
