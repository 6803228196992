import { useState, useEffect } from 'react';
import firebase from '../firebase';

const useFetchBlogPosts = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fallbackImageUrl = '/images/nutrition/diet.png'; // Define a fallback image URL

  const isValidUrl = (url) => {
    if (!url) return false; // Check if URL exists
    try {
      new URL(url);
      return true;
    } catch (e) {
      return false;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const databaseRef = firebase.database().ref('blogPosts');
        databaseRef.on('value', (snapshot) => {
          const data = snapshot.val();
          const fetchedBlogPosts = data
            ? Object.entries(data)
                .map(([key, post]) => ({
                  id: key, // Add the post ID for unique keys
                  ...post,
                  // Use coverImageUrl and fallback if the URL is invalid
                  imageUrl: isValidUrl(post.coverImageUrl) ? post.coverImageUrl : fallbackImageUrl,
                }))
                .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) // Sort by newest first
            : [];
          setBlogPosts(fetchedBlogPosts);
          setLoading(false);
        });
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return { blogPosts, loading, error };
};

export default useFetchBlogPosts;

