import api from '../utils/api';
import {  toast } from 'react-toastify';

import {
    DASHBOARD_ERROR,
GET_DASHBOARD_DATA,
GET_DASHBOARD_GRAPHS

} from './types';

// Get all reports
export const getDashboard = () => async dispatch => {
  try {
    const totalcounts  =  await api.get(`/dashboard/stats`);
// console.log()
    dispatch({
      type: GET_DASHBOARD_DATA,
      payload:  totalcounts?.data
    });
  } catch (err) {
    
    dispatch({
      type: DASHBOARD_ERROR,
    //   payload: { msg: err.response.statusText, status: err.response.status }
      
    });
  }
};

export const getDashboardGraphs  = (year,organization) =>async dispatch => {

  try {
    // const totalcounts  =  await api.get(`/dashboard/stats`);
  
    const orgranizationScansPerMonth = await api.get(`/dashboard/getanalytics?year=${year}&organization=${organization?organization:""}`); 

console.log(orgranizationScansPerMonth)

// console.log()
    dispatch({
      type: GET_DASHBOARD_GRAPHS,
      payload:orgranizationScansPerMonth?.data
    });
  } catch (err) {
    
    dispatch({
      type: DASHBOARD_ERROR,
    //   payload: { msg: err.response.statusText, status: err.response.status }
      
    });
  }
};
