import {
  GET_GENRES,
  GENRE_ERROR,
  GET_GENRE_BY_ID,
  UPDATE_GENRE,
  DELETE_GENRE,
  CREATE_GENRE,
} from "../actions/types";

const initialState = {
  Genres: [],
  Genre: null,
  loading: true,
  error: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_GENRES:
      return {
        ...state,
        Genres: payload,
        loading: false,
      };

    case CREATE_GENRE:
      console.log("Genere Create Payload", payload);
      return {
        ...state,
        Genres: { ...state.Genres, data: [...state.Genres.data, payload] },
        loading: false,
      };

    // case UPDATE_GENRE:
    //   return {
    //     ...state,
    //     Genres: payload,
    //     loading: false,
    //   };
    case DELETE_GENRE:
      return {
        ...state,
        // Genres: payload,
        Genres: {
          ...state.Genres,
          data: state.Genres.data.filter((elem, i) => elem._id !== payload.id),
        },
        loading: false,
      };
    case GET_GENRE_BY_ID: {
      return {
        ...state,
        Genre: payload,
        loading: false,
      };
    }

    case GENRE_ERROR: {
      return {
        ...state,
        Genres: [],
        loading: false,
      };
    }

    default:
      return state;
  }
}
