import React, { useEffect, Fragment, useState } from "react";
import { Link, withRouter, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getDashboard, getDashboardGraphs } from "../../actions/dashboardRoute";
import { getAllGenre } from "../../actions/genreAction";
import { Bar, Doughnut } from "react-chartjs-2";
import { logout } from "../../actions/authAction";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import WithSidebar from "../layout/WithSidebar";
import SvgIcon from "../../Components/SvgIcon";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import FeaturedSlider from "../../Components/FeaturedSlider";
import ContentSlider from "../../Components/ContentSlider";
import useFetch from "../../hooks/useFetch.js";
import ViewLoader from "../../Components/ViewLoader.jsx";
import InfiniteScroll from "react-infinite-scroll-component";
import RecordNotFound from "../../Components/RecordNotFound";

const Dashboard = ({
  getDashboard,
  auth: { isAuthenticated },
  logout,
  dashboard: { Dashboard, loading },
}) => {
  let currentyear = new Date().getFullYear();
  const [page, setPage] = useState(1);
  // const [lives, setLives] = useState([]);

  //TODO: use this for top live
  const {
    data,
    loading: liveStreamLoading,
    error,
  } = useFetch(`/streams/all-live?limit=20&page=${page}`);

  const {
    data: featuredStreams,
    loading: featuredStreamsLoading,
    error: featuredStreamError,
  } = useFetch("/streams?published=true&limit=5&sort=-views&featured=true");

  console.log(data?.data);

  // useEffect(() => {
  //   if (!data) return;
  //   setLives([...data.data, ...lives]);
  // }, [data]);

  return (
    <WithSidebar>
      <div className="">
        <div className="">
          <div className="row">
            <div className="col-lg-12 mb-5">
              {featuredStreamsLoading && <ViewLoader />}

              {featuredStreams && (
                <FeaturedSlider
                  items={featuredStreams.data.map((e) => {
                    return {
                      image: e.thumbnail,
                      viewers: e.views,
                      id: e._id,
                      streamer: e.streamer,
                      title: e.title,
                      category:
                        e.categoryId === null
                          ? "uncategorized"
                          : e.categoryId?.name,
                    };
                  })}
                />
              )}
            </div>
          </div>
          {/* TODO: implement infinite scrolling */}
          {data && data.data && (
            // <InfiniteScroll
            //   className="content-slider row"
            //   dataLength={data && data.pagination.totalPage }
            //   next={(_) => {
            //     setPage(page + 1);
            //     console.log("Next page", _);
            //   }}
            //   hasMore={data && (data.pagination.hasNextPage ?? true)}
            //   loader={<h4>Loading...</h4>}
            //   endMessage={
            //     <p style={{ textAlign: "center" }} className="col-12">
            //       <b>Yay! You have seen it all</b>
            //     </p>
            //   }
            // >
            <div className="content-slider row">
              {data.data.length > 0 ? (
                data.data.map((item, index) => (
                  // <div className="item">
                  //     <img src={item.image} alt="" className="img-fluid" />
                  //     <div className="transparent-bg">
                  //         <div className="live-badge">
                  //             LIVE
                  //         </div>
                  //         <div className="slider-caption">
                  //             <div className="streamer">{item.streamer}</div>
                  //             <h4 className="title">{item.title}</h4>
                  //             <div className="category">{item.category}</div>
                  //         </div>
                  //     </div>
                  // </div>
                  <Link
                    key={index}
                    to={`/live/${item?._id}`}
                    className="col-sm-6 col-md-4 col-lg-3"
                  >
                    <div className="item">
                      <div className="">
                        <div className="position-relative">
                          <div className="live-badge">LIVE</div>
                          <img
                            src={item?.thumbnailImage}
                            alt=""
                            style={{ height: "200px", width: "220px" }}
                            className="img-fluid"
                          />
                          <div className="viewers">
                            <SvgIcon iconName="Viewers" /> {item?.liveViewer}
                          </div>
                        </div>
                        <div className="slider-caption mt-2">
                          <p className="streamer">
                            ${item?.streamer?.firstname} $
                            {item?.streamer?.lastname}
                          </p>
                          <h5 className="title">{item?.title}</h5>
                          <p className="category">
                            {item?.categoryId === null
                              ? "Uncategorized"
                              : item?.categoryId?.name}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))
              ) : (
                <RecordNotFound />
              )}
            </div>
          )}
          <div
            style={{
              display: "flex",
              marginBottom: "20px",
              overflow: "hidden",
              gap: "20px",
            }}
            className=""
          >
            <img
              style={{
                width: "50%",
                borderRadius: "20px",
                overflow: "hidden",
              }}
              src="images/Dumbbells.jpg"
              alt=""
            />
            <img
              style={{ width: "50%", borderRadius: "20px", overflow: "hidden" }}
              src="images/Heartbeat.png"
              alt=""
            />
          </div>{" "}
          {/* <div className="pb-4">
            <h4 style={{ marginBottom: "20px" }}>Live Channel</h4>
            {liveStreamLoading && <ViewLoader />}

            {lives && (
              <ContentSlider
                items={lives.data.map((e) => {
                  return {
                    image: e.thumbnailImage,
                    viewers: e.liveViewer,
                    id: e._id,
                    streamer: `${e.streamer.firstname} ${e.streamer.lastname}`,
                    title: e.title,
                    category: "Data 2",
                  };
                })}
              />
            )}
          </div> */}
        </div>
      </div>
    </WithSidebar>
  );
};

Dashboard.propTypes = {};

const mapStateToProps = (state) => ({
  auth: state.auth,
  dashboard: state.dashboard,
  organization: state.organization,
  genre: state.genre,
});

export default connect(mapStateToProps, {
  getDashboard,
  logout,
  // getOrganizations,
  getDashboardGraphs,
  getAllGenre,
})(withRouter(Dashboard));
