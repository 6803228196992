
  const initialState = {
    
    Loader: false,
  
  };
  
  export default function (state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {

      case "START_LOADER":
        return {
          ...state,
          Loader: payload,
        };
      
      default:
        return state;
    }
  }
  