import api from "../utils/api";
import { toast } from "react-toastify";
import {
  GENRE_ERROR,
  CREATE_GENRE,
  GET_GENRES,
  UPDATE_GENRE,
  GET_GENRE_BY_ID,
  DELETE_GENRE,
} from "./types";

// Create
export const createGenre = (data) => async (dispatch) => {
  try {
    // /category/addCategory
    let status = data.status;
    let name = data.name;
    let file = data.file;
    // const body = { status, name, file };

    var body = new FormData();
    body.append("status", status);
    body.append("name", name);
    body.append("file", file);
    const res = await api.post(`/genres`, body);
    dispatch({
      type: CREATE_GENRE,
      payload: res.data.data,
    });
    if (res.status == 200) {
      toast.success(` ${res.data.message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  } catch (err) {
    toast.error(`${err.response.data.error}`);
    console.log(err);
  }
};

// Get all genre
export const getAllGenre =
  (page, limit, genreStatus, keyword) => async (dispatch) => {
    try {
      const res = await api.get(
        `/genres?page=${page}&name[regex]=${
          keyword ?? ""
        }&name[options]=i&limit=${limit}${
          genreStatus !== undefined && genreStatus !== 9
            ? `&status=${genreStatus}`
            : ""
        }`
      );
      dispatch({
        type: GET_GENRES,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: GENRE_ERROR,
        payload: err,
      });
    }
  };

export const deleteGenre = (id) => async (dispatch) => {
  try {
    const res = await api.delete(`/genres/${id}`);
    dispatch({
      type: DELETE_GENRE,
      payload: { id },
    });
    if (res.status == 200) {
      toast.success(` Genre Deleted Successfully`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

// Get genre by ID
export const getGenreDetailByID = (id) => async (dispatch) => {
  try {
    const res = await api.get(`/genres/${id}`);
    dispatch({
      type: GET_GENRE_BY_ID,
      payload: res.data,
    });
  } catch (err) {
    const errors = err.response.data.errors;
    console.log(errors);
    if (errors) {
      errors.forEach((error) =>
        toast.error(`${error.msg}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      );
    }
  }
};
export const getGenreByCategory =
  (category_id, page, limit, keyword) => async (dispatch) => {
    // try {
    //   const res = await api.get(`/genres/category?category=${category_id}&page=${page}&limit=${limit}&keyword=${keyword}`);
    //   dispatch({
    //     type: GET_GENRES,
    //     payload: res.data,
    //   });
    // } catch (err) {
    //   dispatch({
    //     type: GENRE_ERROR,
    //     payload: err,
    //   });
    // }
  };
//update genre
export const updateGenre = (genreId, formData, history) => async (dispatch) => {
  let status = formData.status;
  let name = formData.name;
  let file = formData.file;
  // const body = { status, name, file };

  var body = new FormData();
  body.append("status", status);
  body.append("name", name);
  body.append("file", file);
  try {
    const res = await api.put(`/genres/${genreId}`, body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    console.log("Udate genre response: ", res.data);
    dispatch({
      type: UPDATE_GENRE,
      payload: res.data,
    });
    toast.success(`${res.data.message}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  } catch (err) {
    const message = err.response.data.error;
    toast.error(`${message}`);
  }
};

//upload image
export const updatePicture =
  (genreId, picture, history) => async (dispatch) => {
    let image = picture;
    let body = { image };
    try {
      const res = await api.post(`/genres/uploadpicture/${genreId}`, body);
      dispatch({
        type: UPDATE_GENRE,
        payload: res.data,
      });
      toast.success(`${res.data.message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (err) {
      console.log(err);
      const errors = err.response.data.errors;
      console.log(errors);
      if (errors) {
        errors.forEach((error) =>
          toast.error(`${error.msg}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        );
      }
    }
  };
