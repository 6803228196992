import React from 'react';
import { useHistory } from 'react-router-dom';
import './Excersize.css';
import workouts from '../utils/workoutsData';

const Exercise = () => {
  const history = useHistory();

  const handleCategoryClick = (category) => {
    history.push(`/workouts/${category}`);
  };

  return (
    <div className="exercise-page">
      <div className="banner">
        <img src="/images/workouts/banner.png" alt="Nutrition Banner" />
        <h1>Welcome to our Exercise Page</h1>
      </div>
      <div className="categories">
        {Object.keys(workouts).map((category, index) => (
          <div key={index} className="category" onClick={() => handleCategoryClick(category)}>
            <img src={`/images/workouts/${category}.png`} alt={category.charAt(0).toUpperCase() + category.slice(1)} />
            <h2>{category.charAt(0).toUpperCase() + category.slice(1)}</h2>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Exercise;
