let socketString;

if (window.location.hostname === "localhost") {
  socketString = "https://localhost:5000";
} else {

  // socketString = "https://api.bahstream.com";
  socketString = "https://api.bahstream.com";
}

export default socketString;


// let socketString;

// if (window.location.hostname === "localhost") {
//   socketString = "https://170.187.157.33:5000";
// } else {
//   socketString = "https://170.187.157.33:5000";
// }

// export default socketString;
