import { useEffect, useState, useRef } from "react";
import io from "socket.io-client";
import socketString from "../../utils/socketString";
import { useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch.js";
import { toast } from "react-toastify";
import api from "../../utils/api.js";
import { Scrollbar } from "smooth-scrollbar-react";
import dayjs from "dayjs";
import { base } from "../../utils/base_url.js";
import { commentStreaming } from "../../actions/streamsActions.js";
import { connect } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
// import { useBeforeunload } from "react-beforeunload";
import { Prompt, Redirect } from "react-router";

function stopAndRemoveTrack(mediaStream) {
  return function (track) {
    track.stop();
    mediaStream.removeTrack(track);
  };
}

function stopMediaStream(mediaStream) {
  if (!mediaStream) {
    return;
  }

  mediaStream.getTracks().forEach(stopAndRemoveTrack(mediaStream));
}

function LiveCame({ auth }) {
  const [recordingCircle, setRecordingCircle] = useState("gray");
  const [isServerDisable, setIsServerDisable] = useState(false);
  const [isStopDisable, setIsStopDisable] = useState(false);
  const [isStartDisable, setIsStartDisable] = useState(true);
  const [oo, setOo] = useState();
  const [live_state, setLiveState] = useState(false);
  const [isStartDelayActive, setIsStartDelayActive] = useState(true); // This state controls the delay for the "Go Live" button

  const [outputMessage, setOutputMessage] = useState();
  const [outputVideo, setOutputVideo] = useState();

  const [mediaStream, setMediaStream] = useState(null);

  const { streamKey, streamId } = useParams();

  const { data: stream, loading, error } = useFetch(`/streams/${streamId}`);

  const [url, setUrl] = useState(streamKey);
  var framerate = 15;
  var audiobitrate = 22050;
  var height = 480;
  var width = 480;
  var mediaRecorder;
  const [mediaRecorder2, setMediaRecorder2] = useState(null);
  var state = "stop";

  const [constraints, setConstraints] = useState(undefined);

  // Add this at the beginning of your LiveCame component
const [initialized, setInitialized] = useState(false);
useEffect(() => {
  if (!initialized && !live_state) {
      // Set a timer once upon initial component mount and when streaming hasn't started.
      const timer = setTimeout(() => {
          setIsStartDelayActive(false); // Disable delay, allow button to be enabled
          setInitialized(true); // Prevent this timer from setting up again
      }, 30000); // 30 seconds

      return () => clearTimeout(timer); // Cleanup the timer when the component unmounts
  }
}, [initialized, live_state]); // Depend on 'initialized' and 'live_state'


  

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(function (devices) {
      var cam = devices.find((device) => device.kind === "videoinput");
      var mic = devices.find((device) => device.kind === "audioinput");
      var _constraints = {
        video: cam && {
          width: { min: 100, ideal: width, max: 1920 },
          height: { min: 100, ideal: height, max: 1080 },
          frameRate: { ideal: framerate },
        },
        audio: mic && {
          sampleRate: audiobitrate,
          echoCancellation: true,
        },
      };
      setConstraints(_constraints);
      // }
    });
  }, []);

  const socketOptions = {
    secure: true,
    reconnection: false,
    reconnectionDelay: 1000,
    timeout: 15000,
    pingTimeout: 15000,
    pingInterval: 45000,
    query: { framespersecond: framerate, audioBitrate: audiobitrate },
  };

  const video_show = (stream) => {
    var output_video = document.getElementById("video-preview");
    if (output_video === null) return;
    if ("srcObject" in output_video) {
      output_video.muted = true;
      output_video.srcObject = stream;
    } else {
      output_video.src = window.URL.createObjectURL(stream);
    }
  };

  var socket = io.connect(socketString, socketOptions);
  const connect_server = () => {
    navigator.getUserMedia =
      navigator.mediaDevices.getUserMedia ||
      navigator.mediaDevices.mozGetUserMedia ||
      navigator.mediaDevices.msGetUserMedia ||
      navigator.mediaDevices.webkitGetUserMedia;
    const fail = (str) => {
      alert(
        str +
          "\nUnable to access the camera Please ensure you are using Firefox or Chrome."
      );
      //location.replace('http://mozilla.org/firefox');
    };
    !navigator.getUserMedia && fail("No getUserMedia() available.");
    !MediaRecorder && fail("No MediaRecorder available.");

    socket.on("connect_timeout", (timeout) => {
      console.log("state on connection timeout= " + timeout);
      setOutputMessage("Connection timed out");
      setRecordingCircle("gray");
    });

    socket.on("error", (error) => {
      console.log("state on connection error1= " + error);
      setOutputMessage("Connection error");
      setRecordingCircle("gray");
    });

    socket.on("connect_error", () => {
      console.log("state on connection error2= " + state);
      setOutputMessage("Connection Failed");
      setRecordingCircle("gray");
    });

    socket.on("message", (m) => {
      console.log("state on message= " + state);
      console.log("recv server message", m);
      if (constraints !== undefined) {
        navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
          video_show(stream);
          window.liveStreamMedia = stream;
        });
      }
    });

    socket.on("fatal", (m) => {
      console.log("fatal socket error!!", m);
      console.log("state on fatal error= " + state);
      console.log("media recorder restarted");
      setRecordingCircle("gray");
      //mediaRecorder.start();
      //state="stop";
      //button_start.disabled=true;
      //button_server.disabled=false;
      //document.getElementById('button_start').disabled=true;
      //restart the server
      if (oo.checked) {
        //timedCount();
        setOutputMessage("server is reload!");
        console.log("server is reloading!");
        setRecordingCircle("gray");
      }
    });

    socket.on("ffmpeg_stderr", (m) => {
      //this is the ffmpeg output for each frame
    });

    socket.on("disconnect", (reason) => {
      console.log("state disconec= " + state);
      console.log("ERROR: server disconnected!" + reason);
      setRecordingCircle("gray");
      //reconnect the server
      connect_server();

      //socket.open();
      //mediaRecorder.stop();
      //state="stop";
      //button_start.disabled=true;
      //button_server.disabled=false;
      //document.getElementById('button_start').disabled=true;
      //var oo=document.getElementById("checkbox_Reconection");
      if (oo.checked) {
        //timedCount();
        setOutputMessage("server is reloading!");
        console.log("server is reloading!");
      }
    });
    state = "ready";
    console.log("state = " + state);
    setIsServerDisable(true);
    setIsStopDisable(false);
    setIsStartDisable(false);
    setOutputMessage("connect server successful");
  };

  const requestMedia = () => {
    if (stream.data.published) {
      toast.error("You can't stream with this key");
      return;
    }
    if (constraints === undefined) return;
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        window.otherMediaStream = stream;
        setUrl(streamKey);
        let supported = navigator.mediaDevices.getSupportedConstraints();
        console.log(supported);
        setRecordingCircle("red");
        console.log(streamKey);
        //start socket connection
        socket.emit("config_rtmpDestination", url);
        socket.emit("start", "start");
        setLiveState(true);
        mediaRecorder = new MediaRecorder(stream);
        mediaRecorder.start(250);
        setIsServerDisable(true);
        setIsStopDisable(false);
        setIsStartDisable(true);

        //show remote stream
        var livestream = document.getElementsByClassName("Livestream");
        console.log("adding live stream");
        livestream.innerHtml = "test";
        mediaRecorder.onstop = function (e) {
          console.log("stopped!");
          console.log(e);
          //stream.stop();
        };
        mediaRecorder.onpause = function (e) {
          console.log("media recorder paused!!");
          console.log(e);
          //stream.stop();
        };
        mediaRecorder.onerror = function (event) {
          let error = event.error;
          console.log("error", error);
        };
        //document.getElementById('button_start').disabled=false;

        mediaRecorder.ondataavailable = function (e) {
          //console.log(e.data);
          //console.log(socket)
          socket.emit("binarystream", e.data);
          state = "start";
          //chunks.push(e.data);
        };

        setMediaRecorder2(mediaRecorder);
      })
      .catch(function (err) {
        console.log("The following error occured: " + err);
        //setOutputMessage("Local video source size is not support or No camera ?" + output_video.videoWidth + "x" + output_video.videoHeight)
        state = "stop";
        setIsServerDisable(false);
        setIsStartDisable(true);
      });
  };

  const stopStream = () => {
    //navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
    //let mediaRecorder = new MediaRecorder(stream);
    setLiveState(false);
    console.log("stop pressed:");
    mediaRecorder?.stop();
    mediaRecorder2.stop();
    setRecordingCircle("gray");
    setIsServerDisable(false);
    setIsStopDisable(true);
    setIsStartDisable(true);
    socket.disconnect();
    // stopMediaStream(window.liveStreamMedia);
    // stopMediaStream(mediaStream);

    // setUrl(null);

    // stopCamera();
    // setStreamNull();
    //});
  };
  const [messages, setMessages] = useState([]);
  const [refreshChat, setRefreshChat] = useState(null);

  const socketRef = useRef();
  const {
    data: streamComments,
    loading: streamCommmentLoading,
    error: streamError,
  } = useFetch(`/streams/${streamId}/comments?limit=30&sort=createdAt`, [
    refreshChat,
  ]);

  useEffect(() => {
    if (streamComments) {
      setMessages(streamComments?.data);
    }
  }, [streamComments]);

  useEffect(() => {
    if (!stream) return;
    console.log(stream);
    socketRef.current = io.connect(socketString, socketOptions);
    socketRef.current.emit("joinRoom", stream.data.stream_key);

    socketRef.current.on("chat", (payload) => {
      console.log(payload);
      setMessages([...messages, payload.chat]);
      setRefreshChat(payload.chat._id);
    });

    return function cleanup() {
      stopCamera();
      socketRef.current.disconnect();
    };
  }, [stream]);

  const [message, setMessage] = useState("");

  const sendMessage = async (e) => {
    e.preventDefault();
    try {
      const res = await api.post(`/streams/${streamId}/comments`, {
        commentText: message,
      });
      console.log(res.data.data);
      socketRef.current.emit("chat", {
        stream_key: stream.data.stream_key,
        chat: res.data.data,
      });
      setMessages([...messages, res.data.data]);

      setMessage(""); // clear input
    } catch (e) {
      toast.error("Couldn't comment");
    }
  };

  const streamerId = stream?.data?.streamer?._id;

  useEffect(() => {
    url !== null && connect_server(url);
    if (constraints === undefined) return;
    navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
      setMediaStream(stream);
      video_show(stream);
    });
    return function cleanup() {
      stopCamera();
    };
  }, [url, constraints]);

  const stopCamera = () => {
    if (mediaStream) {
      stopMediaStream(mediaStream);
    }
    stopMediaStream(window.liveStreamMedia);
    if (window.otherMediaStream) {
      stopMediaStream(window.otherMediaStream);
    }

    console.log(mediaStream);
    console.log("windowmediastream", window.liveStreamMedia);
    console.log("other", window?.otherMediaStream);
    if (mediaStream) {
      mediaStream.getTracks().forEach((track) => track.stop());
    }
    if (window.liveStreamMedia) {
      window.liveStreamMedia.getTracks().forEach((track) => track.stop());
    }

    // console.log(mediaStream);
    var videoElement = document.getElementById("video-preview");
    if (videoElement !== null && videoElement.srcObject) {
      videoElement.srcObject.getVideoTracks().forEach((track) => {
        track.stop();
        videoElement.srcObject.current?.removeTrack(track);
      });
      videoElement.srcObject = null;
    }

    if (mediaStream && mediaStream.active === false) {
      setMediaStream(null);
    }
    // window.location.reload();
    // video_show(null);
  };

  // window.onbeforeunload = function () {
  //   return "Are you surue want to exit";
  // };

  // const { pathname } = useLocation();
  const history = useHistory();

  if (loading) {
    return <div>Loading...</div>;
  }
  if (stream?.data?.published) {
    return <Redirect to={`/live/${streamId}`} />;
  }
  return (
    <>
      <Prompt
        when={mediaStream !== null}
        message={(location, action) => {
          if (
            window.confirm(
              live_state
                ? "You are currently streamming, are you sure want to Stop Streamming ?"
                : "Are you sure you want to navigate away from this page...."
            )
          ) {
            if (live_state) {
              console.log("Stopping stream");
              stopStream();
              // window.URL.revokeObjectURL(window.URL);
              stopCamera();
            } else {
              console.log("Stopping Camera...");
              stopCamera();
              console.log("Camera Camera...");
            }
          } else {
            console.log("User tap Cancel");
            return false;
          }
        }}
      />
      <div
        style={{ paddingTop: 70, overflowX: "hidden", background: "#18181B" }}
      >
        {stream && (
          <div className="row">
            <div className="col-lg-8">
              <div
                className="mb-4 viewplayer-frame"
                style={{ background: "#666", overflowY: "scroll" }}
              >
                {/* live cam */}
                <video
                  id="video-preview"
                  style={{ width: "100%", height: "100%" }}
                  playsInline
                  autoPlay
                ></video>
              </div>

              <div className="px-5 pb-5">
                <div
                  className="clearfix position-relative"
                  style={{ paddingRight: 100 }}
                >
                  <div className="title mb-3 float-left">
                    <div className="mb-2">
                      <span className="text-muted mr-4">
                        {stream?.data?.categoryId?.name}
                      </span>
                      <span className="text-muted mr-4">
                        {stream?.data?.genreId?.name}
                      </span>
                    </div>

                    <h3>{stream?.data?.title}</h3>
                  </div>
                  <button
                    className="btn btn-primary float-right position-absolute"
                    style={{ right: 0 }}
                    onClick={() => (live_state ? stopStream() : requestMedia())}
                    disabled={
                      live_state
                        ? isStopDisable
                        : isStartDisable || isStartDelayActive
                    }
                  >
                    {live_state
                      ? "Stop Streaming"
                      : isStartDisable || isStartDelayActive
                      ? "Please wait, live not ready"
                      : "Go Live"}
                  </button>
                </div>

                {/* <button
                  className="btn btn-primary float-right position-absolute"
                  style={{ right: 0 }}
                  onClick={() => stopCamera()}
                >
                  Stop Camera
                </button> */}

                <div className="text-muted">
                  <p>{stream?.data?.description}</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="stream-chat" style={{ height: 660 }}>
                <div className="title">STREAM CHAT</div>
                {/* <Scrollbar damping={0.1}> */}
                <div style={{ height: 560, overflowY: "scroll" }}>
                  {messages &&
                    messages.map((e) => (
                      <div
                        className={`message-box ${
                          e.userId._id === auth.user.data._id
                            ? "current-user"
                            : ""
                        }`}
                      >
                        <img
                          // src={e.userId.image} // send full path
                          src={
                            e.userId.image.toLowerCase().includes("no-image") ||
                            e.userId.image.toLowerCase().includes("no-photo")
                              ? "/images/profile_placeholder.webp"
                              : `${base}/${e.userId.image}`
                          }
                          alt=""
                        />
                        <div className="message-desc">
                          <div className="message-user">
                            {e.userId.firstname} {e.userId.lastname}
                            <span className="created-at">
                              {dayjs(e.createdAt).format("ddd h:mm A")}
                            </span>
                          </div>
                          <div>{e.commentText}</div>
                        </div>
                      </div>
                    ))}
                </div>
                {/* </Scrollbar> */}

                <div className="stream-input">
                  <form
                    onSubmit={(e) => {
                      sendMessage(e);
                    }}
                  >
                    <input
                      type="text"
                      onChange={(e) => setMessage(e.target.value)}
                      value={message}
                      placeholder="Send a message"
                    />
                    {/* <button
                    className="btn btn-primary"
                    onClick={(e) => sendMessage(e)}
                  >
                    Send
                  </button> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  auth: state.auth,
});

export default connect(mapStateToProps, { commentStreaming })(LiveCame);

// export default LiveCame;
