const workouts = {
    abs: [
      {
        name: 'Crunches',
        image: '/images/workouts/abs-1.png',
        description: 'One of the most popular ab exercises out there. It is easy to do and is great if you are just starting out or have years of experience. This exercise is also great since no equipment is required, so you can do it at home or at the gym. There are also many different variations of crunches, so you are always hitting different muscles in your abdominal. Stream your ab exercises at bahstream.com'
      },
      {
        name: 'Planks',
        image: '/images/workouts/planks.png',
        description: 'Just like the crunches planks can be done at the gym or at home. There are also different variations of planks so you never get bored at the gym. Even a couple seconds a day with planks can greatly increase abdominal muscles. Show off all your plank exercises at bahstream.com'
      },
      {
        name: 'Leg Raises',
        image: '/images/workouts/leg-raises.png',
        description: 'Leg Raises can be incorporated into your ab workouts to help further increase your muscles. You can also add weight to this exercise to help increase your ab muscles.'
      },
      {
        name: 'Heel Touches',
        image: '/images/workouts/heel-touches.png',
        description: 'If done properly this exercise can really help with ab muscles. It is a simple but effective exercise. Can be done anywhere and does not take much to do. Can be done by a beginner or an expert.'
      },
      {
        name: 'Machine Crunches',
        image: '/images/workouts/machine-crunches.png',
        description: 'If you are new to the gym or do not feel comfortable doing other types of machines or equipment there are usually ab machines you can incorporate into your workout. These ab machines are typically straightforward and can be found at the gym. Stream your ab workouts at bahstream.com'
      },
      {
        name: 'Twist Crunch',
        image: '/images/workouts/twist-crunch.png',
        description: 'This is an alternative to the regular crunches but a great exercise to add to for your abs. The goal is to not move to quickly and slowly bring your elbow to touch your knee. Alternate slowly between your knee and elbow to get a great ab workout. Live stream fitness workouts at bahstream.com.'
      }
    ],
    arms: [
      {
        name: 'Barbell Curls',
        image: '/images/workouts/arms-1.png',
        description: 'Barbell curls are a great exercise for targeting the biceps. You could hold the barbell with a wide grip or with a close grip depending on how you want to target your biceps. Live stream your bicep workouts with bahstream.com'
      },
      {
        name: 'Hammer Curls',
        image: '/images/workouts/hammer-curls.png',
        description: 'Like the barbell curls or dumbbell curls any variation of curls is great for building bigger biceps. Incorporating hammer curls with any other type of curls will ensure you are hitting all the muscle groups of the biceps.'
      },
      {
        name: 'Reverse Curls',
        image: '/images/workouts/reverse-curls.png',
        description: 'Just like normal curls you want to slowly raise the dumbbells with your arms. The difference would be in the way the dumbbell is held. You will hold the dumbbells in a reverse grip and then slowly raise the dumbbells. These types of curls are great for targeting the biceps and forearms at the same time. It will be harder to lift heavy weight with reverse curls but great exercise to work the forearms while still working the biceps. Live stream fitness curls at bahsteam.com.'
      },
      {
        name: 'Preacher Curls',
        image: '/images/workouts/preacher-curls.png',
        description: 'Preacher curls can be done with a machine a barbell or a dumbbell. All you need is a mat or a bench to be able to do preacher curls. This exercise helps isolate the bicep for a bigger muscle grow. Stream your preacher curls at bahstream.com'
      },
      {
        name: 'Concentration Curls',
        image: '/images/workouts/concentration-curls.png',
        description: 'With a bench and a barbell the concentration curls help isolate the biceps. As the exercise suggests it concentrates on the muscle to build bigger arms. You can live stream all your favorite bicep curls at bahstream.com'
      },
      {
        name: 'Dumbbell Curls',
        image: '/images/workouts/dumbbell-curls.png',
        description: 'The dumbbell curls are one of the most famous bicep exercises. It is one the exercises that everyone should incorporate into their workouts no matter what level of expertise you have at the gym.'
      }
    ],
    triceps: [
      {
        name: 'Triceps Pushdowns (bar)',
        image: '/images/workouts/triceps-pushdowns-bar.png',
        description: 'Overhand grip of the bar allows for more weight to be lifted. More weight allows for bigger muscle growth. However normal grip is just as effective in targeting the triceps. Control of the weight is the goal with triceps pushdowns.'
      },
      {
        name: 'Triceps Pushdowns (rope)',
        image: '/images/workouts/triceps-pushdowns-rope.png',
        description: 'Equipment will be needed for this exercise. Slow and steady movements for effective muscle group. Just like the v-bar the rope targets the same muscles groups. The rope pushdowns will be more difficult than the bar pushdowns.'
      },
      {
        name: 'Triceps Extensions',
        image: '/images/workouts/triceps-extensions.png',
        description: 'This exercise can be done sitting or standing. It can also be done with two hands or one hand. With one hand you may need to do less weight but is equally effective. Steady repetitions will help build muscle for this exercise.'
      },
      {
        name: 'Skull Crushers',
        image: '/images/workouts/skull-crushers.png',
        description: 'This is a great workout to isolate the triceps. Light weight and correct form are key to this exercise. Typically done with a barbell. Slow and steady motion are most effective for muscle growth.'
      }
    ],
    back: [
      {
        name: 'Lat Pulldown',
        image: '/images/workouts/back-1.png',
        description: 'There are many variations to the lateral pulldowns but incorporating some sort of lateral pulldown is important to building a bigger back. The lat pulldown machine can be found in every gym and is a great exercise for the back. You can stream your fitness and stream your back workouts at bahstream.com.'
      },
      {
        name: 'Cable Rows',
        image: '/images/workouts/cable-rows.png',
        description: 'Just like the lateral pulldowns. The rows are important for building a bigger back. There are also different variations to cable rows that can be done at the gym. Make sure to live stream your cable rows with bahstream.com.'
      },
      {
        name: 'Dumbbell Rows',
        image: '/images/workouts/dumbbell-rows.png',
        description: 'Any type of rowing helps build a strong back. If your gym does not have any cables or machines for rowing you can always pick up some dumbbells or barbells and do row exercises with free weight.'
      },
      {
        name: 'Pull Ups/Assisted Pull Ups',
        image: '/images/workouts/pull-ups.png',
        description: 'Pull ups are great when you are limited on equipment and machines. Some gyms however do have some assisted pull up machines if you are just getting started and need help doing this exercise. Pullups can be done in almost any location and are great for doing at home or at the gym. Live stream your pull up exercises at bahstream.com.'
      },
      {
        name: 'Bent Over Row',
        image: '/images/workouts/bent-over-row.png',
        description: 'As mentioned before any type of rowing exercise helps build a bigger back. You could do bent over rows with dumbbells barbells or machines. Rows and pulldowns are the two most efficient and effective back exercises. Make sure to stream all your back exercises at bahstream.com.'
      },
      {
        name: 'Back Extensions',
        image: '/images/workouts/back-extensions.png',
        description: 'Back extensions help target the lower back. It’s an exercise that can be done to ensure you are targeting all the muscles on your back. It helps strengthen the lower part of your back. If no machines are available this exercise can also be done with medicine balls or with no equipment at all.'
      }
    ],
    chest: [
      {
        name: 'Barbell Bench Press',
        image: '/images/workouts/chest-1.png',
        description: 'One of the most famous exercises you will encounter at the gym. A question that is often heard throughout the fitness community is “how much do you bench”. One of the best chests exercises you can do. You can also stream your fitness content and stream your chest exercises at bahstream.com.'
      },
      {
        name: 'Dumbbell Bench Press',
        image: '/images/workouts/dumbbell-bench-press.png',
        description: 'Dumbbells are equally important as barbells. Dumbbells are harder to lift and will give that extra boost needed to build muscle. Adding dumbbell bench press to your workouts will help increase chest in no time. Dumbbells also can be found at almost any gym.'
      },
      {
        name: 'Incline Barbell Bench Press',
        image: '/images/workouts/incline-barbell-bench-press.png',
        description: 'The upper part of the chest needs to be hit to gain a bigger flat bench press and build bigger muscles in the chest overall. A lot of gyms will have both a flat barbell and an incline barbell. Stream your favorite barbell bench press with bahstream.com.'
      },
      {
        name: 'Incline Dumbbell Bench Press',
        image: '/images/workouts/incline-dumbbell-bench-press.png',
        description: 'Just like the regular flat dumbbell bench press you need an incline press with dumbbells. You want to ensure you incorporate different heights to your incline. However too high and you start to hit shoulders. Too low and you are back to flat press. Make sure to incorporate many height levels to your chest press.'
      },
      {
        name: 'Machine Chest Press',
        image: '/images/workouts/machine-chest-press.png',
        description: 'Machines are great ways if you are trying to warm up or end your workout on a lighter note. The machine chest press could also be used if want ease your way into the gym or are simply not ready for the bigger equipment like barbell or dumbbell press.'
      },
      {
        name: 'Chest Flys',
        image: '/images/workouts/chest-flys.png',
        description: 'This can be done on a machine or a bench. Chest fly machines can be found at almost any gym. However if you need to use barbells start off with low weight. Slow and steady will help your body in the long run. Stream your max chest fly’s on our website!'
      },
      {
        name: 'Pushups',
        image: '/images/workouts/pushups.png',
        description: 'Pushups is one of the greatest exercises you can do with no weights or no gym membership. Pushups targets multiple parts of the body and can be incorporated in many ways. There are many different variations of pushups allowing you to hit various parts of the body. Make sure to live stream your favorite pushup at bahstream.com.'
      },
      {
        name: 'Chest Dips',
        image: '/images/workouts/chest-dips.png',
        description: 'Chest dips can be done almost anywhere that can support your body weight. Many gyms have a machine to freely use your body or add weight to this exercise. It is an awesome exercise to do at home or at the gym.'
      }
    ],
    legs: [
      {
        name: 'Squats',
        image: '/images/workouts/legs-1.png',
        description: 'Squats should be included in everyone’s workouts. Squats are a great exercise that targets multiple part of your lower body. You could stream your max squats at bahstream.com.'
      },
      {
        name: 'Deadlifts',
        image: '/images/workouts/deadlifts.png',
        description: 'Deadlifts target various parts on the body. This exercise is known as one of the “big three” the other two being squats and bench press. Proper form is needed to ensure you are obtaining the maximum efficiency from this workout.'
      },
      {
        name: 'Lunges',
        image: '/images/workouts/lunges.png',
        description: 'Lunges are a simple exercise that can be done with or without weights. If no weights are too easy you can always increase the intensity of this exercise with some dumbbells. Lunges can also be done stepping forwards or backwards. Live stream fitness leg workouts and exercises with bahstream.com.'
      },
      {
        name: 'Leg Extensions',
        image: '/images/workouts/leg-extensions.png',
        description: 'The leg extension exercise is one used for strengthening the quadriceps. It is a machine that should be done with light weight and a lot of control. Having proper form is key to doing this exercise. Stream your leg exercises at bahstream.com.'
      },
      {
        name: 'Leg Curls',
        image: '/images/workouts/leg-curls.png',
        description: 'Leg curls are also an exercise that should be done with proper form and light weight. Leg curls target the hamstrings to help with other leg exercises. Getting a good stretch is also key to all the various leg exercises. Live stream leg curls with bahstream.com.'
      },
      {
        name: 'Calf Raises',
        image: '/images/workouts/calf-raises.png',
        description: 'Calf raises can be done standing or sitting down. It is difficult to increase muscle on your calves so it may not hurt doing calf raises multiple times a week. There are many variations of calf raises that can be done to increase muscle in the calves.'
      },
      {
        name: 'Leg Press',
        image: '/images/workouts/leg-press.png',
        description: 'Next to squats and deadlifts the leg press is the next top leg exercise. It does not require as much movement as squats or deadlifts but still covers a lot of muscles in the legs. As all other exercises proper form is needed for this exercise. Record and stream leg workouts on our website.'
      }
    ],
    shoulders: [
      {
        name: 'Side Lateral Raise',
        image: '/images/workouts/pull-1.png',
        description: 'Done with light weight it is a good exercise to target the shoulders. Typically done with dumbbells. Typically also done with slow concentrated form and high repetitions. Make live videos for your shoulder workouts at bahstream.com.'
      },
      {
        name: 'Front Raise',
        image: '/images/workouts/front-raise.png',
        description: 'Just like the side raises the front lateral raises are done with light weight and concentrated form. Good form is key to preventing injuries and building muscle. This exercise can be done with barbells or dumbbells. Both the front and side lateral raises can also be done with cable equipment but it is always best to start with light weight and then make your way up.'
      },
      {
        name: 'Dumbbell Press',
        image: '/images/workouts/dumbbell-press.png',
        description: 'This exercise can be done seated or standing. Seated will give you a more direct approach to the shoulders. Standing will target other parts of your body while seated tends to target more the shoulders. You might also find to be able to lift more weight with seated press. Stream your dumbbell shoulder press workouts at bahstream.com.'
      },
      {
        name: 'Barbell Press',
        image: '/images/workouts/barbell-press.png',
        description: 'This exercise is exactly like the dumbbell press and can also be done standing or sitting down. You can add more weight on a barbell and do not activate other muscles as much. Both dumbbell and barbell exercises should be considered when working out shoulders.'
      },
      {
        name: 'Shrugs',
        image: '/images/workouts/shrugs.png',
        description: 'This exercise is exactly what it sounds like. You can also use dumbbells or barbells to do shrugs. Using dumbbells to do shrugs will also target your forearms. Using a barbell or smith machine will take some stress out of your forearms and allow you to do more weight. Show off your shoulder shrugs at bahstream.com.'
      },
      {
        name: 'Standing Shoulder Press',
        image: '/images/workouts/standing-shoulder-press.png',
        description: 'Each gym will have some sort of equipment meant to do shoulder presses. Typically machine shoulder presses at a local gym will be sitting down and the weight can be adjusted accordingly for everyone. However if sitting is not an option the standing shoulder press can also be done. It can be done with dumbbells or barbells in almost any part of the gym.'
      }
    ]
  };
  
  export default workouts;
