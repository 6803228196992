

//  const base = "https://170.187.157.33:443"; //TODO: uncomment for deploymenet
//  exports.liveUrl = "https://170.187.157.33:443"; //TODO: uncomment for deploymenet
// const base = "https://localhost:5000"; //TODO: uncomment for deploymenet
// exports.liveUrl = "https://localhost:5000"; // this is for the local host
// const base = "https://bahstream.com"; //TODO: uncomment for deploymenet
// exports.liveUrl = "https://bahstream.com";
const base = "https://api.bahstream.com"; //TODO: uncomment for deploymenet
exports.liveUrl = "https://api.bahstream.com";
exports.base = base;
exports.baseUrl = `${base}/api`;
