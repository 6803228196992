import React from "react";
import SocialLogin from "react-social-login";

class SocialButton extends React.Component {
  render() {
    const { children, triggerLogin, ...props } = this.props;
    return (
      <b onClick={triggerLogin} {...props}>
        {children}
      </b>
    );
  }
}



export default SocialLogin(SocialButton);
