
import {
   POST_FEEDACK
  } from '../actions/types';
  
  
  
  const initialState = {
    
    Feedbacks: [],
    Feedback:null,
    loading: true,
    error: {}
  };
  
  export default function (state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
    
      case POST_FEEDACK:{
        return {
          ...state,
        
        };
      }
     
 
      default:
        return state;
    }
  }
  