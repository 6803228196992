import React, { useEffect, useState, useMemo } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  CircularProgress,
  Grid,
  Box,
} from "@mui/material";
import workout1Data from "./workout1";
import workout2Data from "./workout2";
import workout3Data from "./workouts3";
import workout4Data from "./workout4";
import workout5Data from "./workout5";
import workout6Data from "./workouts6";
// ...import other workout data modules as needed

const WorkoutDetailsPage = () => {
  const { id } = useParams();
  const [workoutData, setWorkoutData] = useState(null);
  const history = useHistory();

  const handleGoBack = () => {
    history.goBack(); // This will navigate the user back to the previous page.
  };

  const dataModules = useMemo(
    () => ({
      1: workout1Data,
      2: workout2Data,
      3: workout3Data,
      4: workout4Data,
      5: workout5Data,
      6: workout6Data,
      // ...add more mappings for other workouts
    }),
    []
  );

  useEffect(() => {
    const fetchedWorkoutData = dataModules[id];
    if (fetchedWorkoutData) {
      setWorkoutData(fetchedWorkoutData);
    } else {
      console.error(`Workout data for ID ${id} not found.`);
    }
  }, [id, dataModules]);

  if (!workoutData) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }

  // Function to render each day's workout data
  const renderWorkoutDay = (dayData) => {
    return (
      <Box style={{ marginBottom: "20px" }}>
        <Typography variant="h5" gutterBottom style={{ color: "#FFFFFF" }}>
          {dayData.title}
        </Typography>
        <TableContainer component={Paper} style={{ backgroundColor: "#333", color: "#FFFFFF" }}>
          <Box sx={{ overflowX: "auto" }}>
            <Table>
              <TableHead>
                <TableRow>
                  {dayData.data[0].map((header, index) => (
                    <TableCell key={index} style={{ color: "#FFFFFF", borderBottom: "1px solid #555" }}>
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {dayData.data.slice(1).map((row, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {row.map((cell, cellIndex) => (
                      <TableCell key={cellIndex} style={{ color: "#FFFFFF", borderBottom: "1px solid #555" }}>
                        {cell}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </TableContainer>
      </Box>
    );
  };

  // Dynamically render all available days in the workout data
  const renderedDays = [];
  for (let dayNumber = 1; dayNumber <= 6; dayNumber++) {
    const dayKey = `day${dayNumber}`;
    if (workoutData[dayKey]) {
      renderedDays.push(renderWorkoutDay(workoutData[dayKey]));
    }
  }

  return (
    <Container
      maxWidth="md"
      style={{
        backgroundColor: "#1E1E2D",
        padding: "20px",
        borderRadius: "8px",
        marginTop: "20px",
      }}
    >
      <Typography variant="h4" gutterBottom style={{ color: "#FFFFFF" }}>
        Workout Details
      </Typography>
      <Typography variant="body1" paragraph style={{ color: "#AAAAAA" }}>
        {workoutData.description}
      </Typography>
      <Grid container spacing={2}>
        {renderedDays.map((dayContent, index) => (
          <Grid item xs={12} key={index}>
            {dayContent}
          </Grid>
        ))}
      </Grid>
      <Button
        variant="contained"
        onClick={handleGoBack}
        style={{
          marginTop: "20px",
          backgroundColor: "#E94560", // Your primary button color
          color: "#FFFFFF",
          width: "100%",
        }}
      >
        Back
      </Button>
    </Container>
  );
};

export default WorkoutDetailsPage;


